import React from 'react';

function QuestionWindow({ isOpen, onClose, children }) {
    if (!isOpen) return null;

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ backgroundColor: 'white', paddingBottom: 30, paddingTop: 0, paddingLeft: 50, paddingRight: 50, width: 300 }}>
                {children}
            </div>
        </div>
    );
}

export default QuestionWindow;
