import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { Button, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
const { TextArea } = Input;

// true-false question
function ObjectReplacementDetails({ question, onDataChange, onSave }) {
    const [questionText, setQuestionText] = useState(question.text || '');
    const [isDirty, setIsDirty] = useState(false); // questiontext dirty state
    const [optionNum, setOptionNum] = useState(0); // optionnum dirty state
    const [showAddButton, setShowAddButton] = useState(false);
    const [optionNumDirty, setOptionNumDirty] = useState(false);
    const [tagMsgs, setTagMsgs] = useState([]);
    const [tagMsgsDirty, setTagMsgsDirty] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 20);
    }, [question]); 

    useEffect(() => {
        setQuestionText(question.text || '');
        setIsDirty(false);
        // fetch tagid and msg from the database
        const fetchTagMsgs = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/objectplacement/${question.id}`);
            const results = await response.json();
            // console.log(results.data);
            if (response.ok) {
                // API returns data in the form of an array of objects where each object has tag and msgs properties
                if (results.data[0]) {
                    const { TagID1, TagID2, TagID3, TagID4, TagID5, OptionNum, Msg1, Msg2, Msg3, Msg4, Msg5 } = results.data[0];
                    setOptionNum(OptionNum);
                    // console.log(OptionNum); // test use only
                    setTagMsgs([TagID1, TagID2, TagID3, TagID4, TagID5, Msg1, Msg2, Msg3, Msg4, Msg5 ]); // Store tags and msgs as an array
                } else {
                    setTagMsgs(["", "", "", "", "", "", "", "", "", ""]); // Default empty if no tags and msgs are found
                }
            } else {
                console.error('Failed to fetch object placements:', results.message);
            }
        };

        fetchTagMsgs();

        setTimeout(() => {
            setShowAddButton(true);
        }, 10);

    }, [question]);

    // Save tagid and msg
    useEffect(() => {
        const saveTagMsgs = async () => {
            // console.log(tagMsgs);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/objectplacement/update/${question.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ tagMsgs })
            });
            const data = await response.json();
            if (data.success) {
                // console.log('Tags and msgs updated successfully'); // test use only
                setTagMsgsDirty(false); // Reset dirty state after successful save
            } else {
                console.error('Failed to update tags and msgs:', data.message);
            }
        };
        
        if (tagMsgsDirty) {
            saveTagMsgs();
            setTagMsgsDirty(false);
        }
    }, [question, tagMsgs, tagMsgsDirty]);

    // update OptionNum value in the database
    useEffect(() => {
        const saveOptionNum = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/objectplacement/updatenum/${question.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ optionNum })
            });
            const data = await response.json();
            if (!data.success) {
                console.error('Failed to update num:', data.message);
            }
        };
        if (optionNumDirty) {
            saveOptionNum();
            setOptionNumDirty(false);
        }
    }, [question, optionNum, optionNumDirty]);

    const handleChange = (e) => {
        // console.log(e.target.value); // test use only
        const newText = e.target.value;
        setQuestionText(newText);
        setIsDirty(true);
        // console.log(questionText);
        onDataChange(newText); 
    };

    const handleTagMsgChange = (index, value) => {
        const updatedTagMsgs = tagMsgs.map((match, i) => {
            if (i === index) {
                return value;
            }
            return match;
        });
        setTagMsgs(updatedTagMsgs);
        setTagMsgsDirty(true);
    };


    // save question text
    const handleSave = async () => {
        if (isDirty) {
            await onSave(question.id, questionText); // Save changes
            setIsDirty(false); // Reset dirty state
        }
    };

    // add new object placement
    const addObjectPlacement = () => {
        let num = optionNum + 1;
        setOptionNum(num);
        setOptionNumDirty(true);
    }

    // delte tag msg
    const handleDeleteTagMsg = (index) => {
        if (optionNum <= 1) {
            alert("Tag ID cannot be less than 1, please add a new one before deleting");
            return;
        }
        for (let i = index; i < optionNum; i++) {
            // console.log(`option index ${i}`);
            if (i === 4) {
                tagMsgs[i] = "";
                tagMsgs[i+5] = null;
            }else {
                tagMsgs[i] = tagMsgs[i+1];
                tagMsgs[i+5] = tagMsgs[i+1+5];
            }
            // console.log(`option index ${tagMsgs[i+1]}`);
        }
        setTagMsgsDirty(true);
        let num = optionNum - 1;
        setOptionNum(num);
        setOptionNumDirty(true);
    }

    // display tagid and msgs
    const renderTagMsgs = () => {
        return ['A', 'B', 'C', 'D', 'E'].map((label, index) => {
            if (index <= optionNum - 1) {
                // console.log(index);
                return (
                    <div className='tagmsg-list' key={label}>
                        <DeleteOutlined className='delete-ranking' style={{marginRight: '15px'}}  onClick={() => handleDeleteTagMsg(index)} />
                        <div className='tagid-content'>
                            <Input
                                type="text"
                                value={tagMsgs[index] || ''}
                                onChange={(e) => handleTagMsgChange(index, e.target.value)}
                                style={{ width: '100%', marginBottom: '10px', marginRight: '10px' }}
                            />
                        </div>
  
                        <div className='msg-content'>
                            <Input 
                                type="text" 
                                value={tagMsgs[index+5] || ''}
                                onChange={(e) => handleTagMsgChange(index+5, e.target.value)}
                                // style={{ width: '10px', marginLeft: '8px' }}
                                style={{ width: '100%', marginLeft: '8px', marginRight: '12px' }}
                            />
                        </div>
                    </div>
                );
            } else {
                // Return null or an alternative component for empty or undefined choices
                return null;
            }
        });
    }

    return (
        <div>
            <TextArea
                ref={inputRef}
                type="text"
                value={questionText}
                placeholder="Question Title"
                onChange={handleChange}
                onBlur={handleSave}
                style={{ width: '100%', marginBottom: '10px', marginTop: '20px' }}
                autoSize={{ minRows: 1, maxRows: 5 }}
            />
            <div className='object_placement_layout'>
                <div className='op_tagid'>
                    <strong>Tag ID</strong>
                </div>
                <div className='op_msg'>
                    <strong>Messages</strong>
                </div>
            </div>
            {renderTagMsgs()}
            { optionNum < 5 && showAddButton && <Button type="primary" onClick={() => addObjectPlacement()} style={{marginTop:'15px'}}>Add New Tag ID</Button> }
        </div>
    );
}

export default ObjectReplacementDetails;