import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import './index.css';
import { Card, Spin, Pagination, Checkbox, Select, Button } from 'antd';

const { Option } = Select;

// const QBankMC = ({ mcCheckedState, onMcCheckedQuestion }) => {
// const QBankMCLesson = ({ mcCheckedState, setMcCheckedState }) => {
const QBankMCLesson = forwardRef((props, ref) => {
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    // const [mcCheckedState, setMcCheckedState] = useState({});
    const [questionsPerPage] = useState(3);  // Adjust number of questions per page as needed
    const [selectedQ, setSelectedQ] = useState({});

    useImperativeHandle(ref, () => ({
        getSelectedQuestionDetails,
        setSelectedTopic
    }));

    useEffect(() => {
        fetchTopics();
        fetchQuestions();
    }, [selectedTopic]);

    const fetchTopics = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/qbankmc/topics`);
        const result = await response.json();
        if (response.ok && result.topics) {
            setTopics(['All', ...result.topics]);
        } else {
            console.error('Failed to fetch topics:', result.message || 'Unknown error');
        }
    };

    const fetchQuestions = async () => {
        const url = `${process.env.REACT_APP_API_URL}/api/qbankmc${selectedTopic !== 'All' ? `?topic=${selectedTopic}` : ''}`;
        const response = await fetch(url);
        const results = await response.json();
        if (response.ok) {
            setQuestions(results.data);
            // console.log(results.data);
        } else {
            console.error('Failed to fetch questions:', results.message);
        }
        setLoading(false);
    };

    const handleTopicChange = value => {
        setSelectedTopic(value);
        setCurrentPage(1);  // Reset to first page on topic change
    };

    // mc question checked state 
    const onMcCheckedQuestion = (qid) => {
        if (props.mcCheckedState[qid]) {
            props.setMcCheckedState({});
        } else {
            props.setMcCheckedState({
                [qid]: true
            });
        }
        // console.log(mcCheckedState); // {1: true, 2: false} 
    };

    // Get current questions to display
    const indexOfLastQuestion = currentPage * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const currentQuestions = questions.slice(indexOfFirstQuestion, indexOfLastQuestion);

    // Change page
    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
    };

    const getSelectedQuestionDetails = () => {
        const selectedQuestionIds = Object.keys(props.mcCheckedState).filter(id => props.mcCheckedState[id]);
        const selectedQuestions = questions.filter(q => selectedQuestionIds.includes(String(q.QID)));

        // console.log('test', selectedQuestions);
    
        return selectedQuestions.map(q => ({
            questionTitle: q.QuestionText,
            choices: {
                A: q.A,
                B: q.B,
                C: q.C,
                D: q.D,
                E: q.E
            },
            feedbacks: {
                comA:q.ComA,
                comB:q.ComB,
                comC:q.ComC,
                comD:q.ComD,
                comE:q.ComE,
            },
            correctAnswer: q.CorrectAnswer
        }));
    };  

    if (loading) {
        return <Spin />;
    }

    const importQuestion = (q) => {
        const q_ = {
            questionTitle: q.QuestionText,
            choices: {
                A: q.A,
                B: q.B,
                C: q.C,
                D: q.D,
                E: q.E
            },
            feedbacks: {
                comA:q.ComA,
                comB:q.ComB,
                comC:q.ComC,
                comD:q.ComD,
                comE:q.ComE,
            },
            correctAnswer: q.CorrectAnswer
        };
        props.updateQ(q_);
        // console.log(q_);
    }

    return (
        <div>
            <div className='question-display-header'>
                <p style={{fontSize: '1.2rem', width: '100px'}}><strong>Topics: </strong></p>
                <Select defaultValue="All" style={{ width: '200px', marginLeft: '20px' }} onChange={handleTopicChange}>
                    {topics.map(topic => (
                        <Option key={topic} value={topic}>{topic}</Option>
                    ))}
                </Select>
                <div style={{display: 'flex', width: '800px', color: 'red', marginLeft: '80px'}}>*Please select the question you want to import.</div>
                <div className='question-pagination-lesson'>
                    <Pagination
                        current={currentPage}
                        total={questions.length}
                        pageSize={questionsPerPage}
                        onChange={paginate}
                        style={{ textAlign: 'right', margin: '15px 0' }}
                    />
                </div>
            </div>
            {currentQuestions.map((q) => (
                <div key={q.QID} className='question-box'>
                    <Checkbox 
                        checked={props.mcCheckedState[q.QID]} 
                        onChange={() => onMcCheckedQuestion(q.QID)} 
                    />
                    <Card 
                        onClick={() => onMcCheckedQuestion(q.QID)}
                        className="question-card"  // Add a class to the Card
                        style={{ marginLeft: '15px', marginBottom: '16px', width: '100%', cursor: 'pointer' }}
                    >
                        <div className='question-text'>
                            <b>Question:</b> {q.QuestionText}
                        </div>
                        <div style={{display: 'flex'}}>
                            <table className="table-option no-border">
                                <tbody>
                                    <tr>
                                        <td className={q.CorrectAnswer === 'A' ? 'correct-answer' : 'table-option-list'}>
                                            <strong>A:</strong> {q.A}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={q.CorrectAnswer === 'B' ? 'correct-answer' : 'table-option-list'}>
                                            <strong>B:</strong> {q.B}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={q.CorrectAnswer === 'C' ? 'correct-answer' : 'table-option-list'}>
                                            <strong>C:</strong> {q.C}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={q.CorrectAnswer === 'D' ? 'correct-answer' : 'table-option-list'}>
                                            <strong>D:</strong> {q.D}
                                        </td>
                                    </tr>
                                    {/* Uncomment this section if you need to include option E */}
                                    {/* <tr>
                                        <td className={q.CorrectAnswer === 'E' ? 'correct-answer' : 'table-option-list'}>
                                            <strong>E:</strong> {q.E}
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                            <div>
                            {/* backgroundColor: '#118f50', color: 'white' onClick={() => importQuestion(q)}*/}
                                {/* <Button type="primary" size="large" style={{ marginRight: '20px'}} onClick={() => importQuestion(q)} >Import Question</Button>                 */}
                            </div>
                        </div>
                    </Card>
                </div>
            ))}

             {/* <button onClick={() => console.log(getSelectedQuestionDetails())}>Get Selected Question Details</button> */}
            <div className='question-pagination-lesson'>
                <Pagination
                    current={currentPage}
                    total={questions.length}
                    pageSize={questionsPerPage}
                    onChange={paginate}
                    style={{ textAlign: 'right', margin: '15px 0' }}
                />
            </div>
        </div>
    );
});

export default QBankMCLesson;