import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { Button, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useDrag, useDrop } from 'react-dnd';

const { TextArea } = Input;

function DraggableLabel({ label, index, onDrop }) {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'label',
        item: { type: 'label', index, label },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return (
        <div ref={dragRef} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'grab', marginRight: '10px', display: 'flex', alignItems: 'center' }}>
            <div className="draggable-handle" style={{marginRight: '5px', fontSize: '20px'}}>⋮⋮</div>
            {label}
        </div>
    );
}

function DropZone({ label, onDrop, droppedItem, onClear }) {
    const [{ isOver }, dropRef] = useDrop({
        accept: 'label',
        drop: (item, monitor) => onDrop(item, label),
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const handleClear = () => {
        onClear(label);  
    };

    return (
        <div ref={dropRef} style={{ 
            width: '130px', height: '28px', padding: '3px', 
            backgroundColor: '#f0f0f0', 
            marginTop: '10px', marginBottom: '18px', borderRadius: '8px', marginLeft: '0px',
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
         }}>
            {/* {droppedItem ? `${droppedItem}` : `Drop option here`} */}
            {droppedItem ? (
                <>
                    {droppedItem}
                    <Button icon={<DeleteOutlined />} onClick={handleClear} type="default"  shape="circle" style={{marginLeft: '10px', marginBottom: '20px'}} ></Button>
                </>
            ) : `Drop option here`}

        </div>
    );
}

function RankingDetails({ question, onDataChange, onSave }) {
    const [questionText, setQuestionText] = useState(question.text || '');
    const [isDirty, setIsDirty] = useState(false); // questiontext dirty state
    const [rankingOptions, setRankingOptions] = useState([]);
    const [rankingOptionsDirty, setRankingOptionsDirty] = useState(false);
    const [optionNum, setOptionNum] = useState(0);
    const [optionNumDirty, setOptionNumDirty] = useState(false);
    const [showAddButton, setShowAddButton] = useState(false);
    const [droppedItems, setDroppedItems] = useState({});
    const [droppedItemsDirty, setDroppedItemsDirty] = useState(false);
    const [rankingMessages, setRankingMessages] = useState([]);
    const [rankingMessagesDirty, setRankingMessagesDirty] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 20);
    }, [question]);

    useEffect(() => {
        setQuestionText(question.text || '');
        setIsDirty(false);
        // fetch ranking options from the database
        const fetchRankingOptions = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/ranking/${question.id}`);
            const results = await response.json();
            // console.log(results.data);
            if (response.ok) {
                // API returns data in the form of an array of objects where each object has A, B, C, D properties
                if (results.data[0]) {
                    const { A, B, C, D, E, OptionNum, AnsA, AnsB, AnsC, AnsD, AnsE, ComA, ComB, ComC, ComD, ComE } = results.data[0];
                    setOptionNum(OptionNum);
                    // console.log(OptionNum); // test use only
                    // setRankingOptions([A, B, C, D, E, AnsA, AnsB, AnsC, AnsD, AnsE]); // Store ranking options as an array
                    setRankingOptions([A, B, C, D, E]);
                    setRankingMessages([ComA, ComB, ComC, ComD, ComE]);
                    setDroppedItems({A: AnsA, B: AnsB, C: AnsC, D: AnsD, E: AnsE});
                } else {
                    // setRankingOptions(["", "", "", "", "", "", "", "", "", ""]); // Default empty if no ranking options are found
                    setRankingOptions(["", "", "", "", ""]);
                    setRankingMessages(["", "", "", "", ""]);
                }
            } else {
                console.error('Failed to fetch ranking options:', results.message);
            }
        };

        fetchRankingOptions();

        setTimeout(() => {
            setShowAddButton(true);
        }, 10);

    }, [question]);

    useEffect(() => {
        // Save ranking options
        const saveRankingOptions = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/ranking/update/${question.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ rankingOptions })
            });
            const data = await response.json();
            if (data.success) {
                setRankingOptionsDirty(false); // Reset dirty state after successful save
            } else {
                console.error('Failed to update ranking options:', data.message);
            }
        };

        if (rankingOptionsDirty) {
            saveRankingOptions();
            setRankingOptionsDirty(false);
        }
    }, [question, rankingOptions, rankingOptionsDirty]);

    useEffect(() => {
        // Save ranking messages
        const saveRankingMessages = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/ranking/updatemsg/${question.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ rankingMessages })
            });
            const data = await response.json();
            if (data.success) {
                setRankingMessagesDirty(false); // Reset dirty state after successful save
            } else {
                console.error('Failed to update ranking options:', data.message);
            }
        };

        if (rankingMessagesDirty) {
            saveRankingMessages();
            setRankingMessagesDirty(false);
        }
    }, [question, rankingMessages, rankingMessagesDirty]);

    useEffect(() => {
        // update OptionNum value in the database
        const saveOptionNum = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/ranking/updatenum/${question.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ optionNum })
            });
            const data = await response.json();
            if (!data.success) {
                console.error('Failed to update num:', data.message);
            }
        };
        if (optionNumDirty) {
            saveOptionNum();
            setOptionNumDirty(false);
        }
    }, [question, optionNum, optionNumDirty]);

    useEffect(() => {
        // Save ranking orders
        const saveRankingOrders = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/ranking/updateorder/${question.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ droppedItems })
            });
            const data = await response.json();
            if (data.success) {
                setDroppedItemsDirty(false); // Reset dirty state after successful save
            } else {
                console.error('Failed to update ranking options:', data.message);
            }
        };

        if (droppedItemsDirty) {
            saveRankingOrders();
            setDroppedItemsDirty(false);
        }
    }, [question, droppedItems, droppedItemsDirty]);

    const handleChange = (e) => {
        const newText = e.target.value;
        setQuestionText(newText);
        setIsDirty(true);
        onDataChange(newText);
    };

    const handleRankingOptionChange = (index, value) => {
        const updatedRankingOptions = rankingOptions.map((option, i) => {
            if (i === index) {
                return value;
            }
            return option;
        });
        setRankingOptions(updatedRankingOptions);
        setRankingOptionsDirty(true);
    };

    const handleRankingMessageChange = (index, value) => {
        const updatedRankingMessages = rankingMessages.map((option, i) => {
            if (i === index) {
                return value;
            }
            return option;
        });
        setRankingMessages(updatedRankingMessages);
        setRankingMessagesDirty(true);
    };

    // add ranking option
    const addRankingOption = () => {
        let num = optionNum + 1;
        setOptionNum(num);
        setOptionNumDirty(true);
    }

    // save question text
    const handleSave = async () => {
        if (isDirty) {
            await onSave(question.id, questionText); // Save changes
            setIsDirty(false); // Reset dirty state
        }
    };

    // delete ranking option
    const handleDeleteRankingOption = (index) => {
        if (optionNum <= 2) {
            alert("Ranking options cannot be less than 2, please add a new one before deleting");
            return;
        }
        const labelOrder = ['A', 'B', 'C', 'D', 'E'];
        for (let i = index; i < optionNum; i++) {
            if (i === 4) {
                rankingOptions[i] = "";
                rankingMessages[i] = "";
                droppedItems.E = "";
                // console.log(droppedItems);
            } else {
                rankingOptions[i] = rankingOptions[i + 1];
                rankingMessages[i] = rankingMessages[i + 1];
                droppedItems[labelOrder[i]] = droppedItems[labelOrder[i + 1]];
                // rankingOptions[i + 5] = rankingOptions[i + 1 + 5];
            }
        }
        // console.log(droppedItems);
        setRankingOptionsDirty(true);
        setRankingMessagesDirty(true);
        let num = optionNum - 1;
        setOptionNum(num);
        setOptionNumDirty(true);
        for (let j = 0; j < num; j++) {
            for (let k = num; k < 5; k++){
                if (droppedItems[labelOrder[j]] === labelOrder[k]){
                    droppedItems[labelOrder[j]] = "";
                }
            }
        }
        setDroppedItemsDirty(true);
    }

    const handleDrop = (item, label) => {
        // console.log("Current state:", droppedItems);
        let newDroppedItems = { ...droppedItems };
        for (const key in newDroppedItems) {
            if (newDroppedItems[key] === item.label) {
                newDroppedItems[key] = "";  
            }
        }
        newDroppedItems[label] = item.label;
        setDroppedItems(newDroppedItems);
        setDroppedItemsDirty(true);
    };    

    const handleClear = (label) => {
        const newDroppedItems = { ...droppedItems, [label]: "" };
        setDroppedItems(newDroppedItems);
        setDroppedItemsDirty(true);
    };

    const renderDropZones = () => {
        return ['A', 'B', 'C', 'D', 'E'].slice(0, optionNum).map(label => (
            // <DropZone key={label} label={label} onDrop={handleDrop} droppedItem={droppedItems[label]} />
            <DropZone key={label} label={label} onDrop={handleDrop} droppedItem={droppedItems[label]} onClear={handleClear} />
        ));
    };

    // display ranking options
    const renderRankingOptions = () => {
        return ['A', 'B', 'C', 'D', 'E'].slice(0, optionNum).map((label, index) => (
            <div className='ranking-list' key={label}>
                <DeleteOutlined className='delete-ranking' style={{ marginRight: '15px' }} onClick={() => handleDeleteRankingOption(index)} />
                <div className='ranking-label'>
                    <DraggableLabel label={label} index={index} />
                </div>
                <div className='ranking-content'>
                    <Input
                        type="text"
                        value={rankingOptions[index] || ''}
                        onChange={(e) => handleRankingOptionChange(index, e.target.value)}
                        style={{ width: '100%', marginBottom: '10px', marginRight: '30px' }}
                    />
                </div>
            </div>
        ));
    }

    // display Messages
    const renderMessages = () => {
        return ['A', 'B', 'C', 'D', 'E'].slice(0, optionNum).map((label, index) => (
            <div className='messages-list' key={label}>
                <div className='messages-content'>
                    <Input
                        type="text"
                        value={rankingMessages[index] || ''}
                        onChange={(e) => handleRankingMessageChange(index, e.target.value)}
                        style={{ width: '85%', marginBottom: '10px', marginRight: '10px' }}
                    />
                </div>
            </div>
        ));
    }

    return (
        <div>
            <TextArea
                ref={inputRef}
                type="text"
                value={questionText}
                placeholder="Question Title"
                onChange={handleChange}
                onBlur={handleSave}
                style={{ width: '100%', marginBottom: '10px', marginTop: '20px' }}
                autoSize={{ minRows: 1, maxRows: 5 }}
            />

            <div>
                <div className='option-order-msg'>
                    <div className='option-order-msg-option'><strong>Options</strong></div>
                    <div className='option-oerder-msg-order'><strong>Orders</strong></div>   
                    <div className='option-oerder-msg-msg'><strong>Messages</strong></div>           
                </div>
                <div className='option-ranking-area'>
                    <div>
                        {renderRankingOptions()}
                    </div>
                    <div>
                        {renderDropZones()}
                    </div>
                    <div style={{marginLeft: '30px'}}>
                        {renderMessages()}
                    </div>
                </div>
                {optionNum < 5 && showAddButton && <Button type="primary" onClick={() => addRankingOption()} style={{ marginTop: '15px' }}>Add New Ranking Option</Button>}
            </div>

        </div>
    );
}

export default RankingDetails;
