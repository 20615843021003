import React, { forwardRef, useImperativeHandle, useState, useRef, useEffect } from 'react';
import './index.css';
import { Button, Input, notification, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import leesonmc_img from '../../assets/mc-top.jpg';
const { TextArea } = Input;

// multiple-choice question
// function LessonMC(onAddQuestion) {
const LessonMCEdit = forwardRef((props, ref) => {
    const { questionData = {}, handleUpdateQuestion, setIsLoading, setIsEditModalVisible } = props;
    const [questionText, setQuestionText] = useState('');
    const [questionID, setQuestionID] = useState('');
    const [choices, setChoices] = useState(Array(5).fill(""));
    const [messages, setMessages] = useState(Array(5).fill(""));
    const [optionNum, setOptionNum] = useState(5);
    const [correctAnswer, setCorrectAnswer] = useState(null);
    const [loading, setLoading] = useState(false);
    // const [outputValue, setOutputValue] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
        // console.log('test');
        // setOutputValue('');
    }, [props]); 

    useEffect(() => {
        if (questionData) {
            // setQuestionID(questionData.questionid);
            // setQuestionText(questionData.text);
            // setChoices(questionData.choices);
            // setMessages(questionData.messages);
            // setOptionNum(questionData.optionNum);
            setQuestionID(questionData.questionid || '');
            setQuestionText(questionData.text || '');
            setChoices(questionData.choices || Array(5).fill(""));
            setMessages(questionData.messages || Array(5).fill(""));
            setOptionNum(questionData.optionNum || 5);
            setCorrectAnswer(questionData.correctAnswer || null);
        }
        // console.log(questionData);
    }, [questionData]);

    useImperativeHandle(ref, () => ({
        submitQuestion,
        cancelEdit
    }));

    const handleChange = (e) => {
        const newText = e.target.value;
        setQuestionText(newText);
    };

    const handleOptionChange = (index, value) => {
        const updatedOptions = [...choices];
        updatedOptions[index] = value;
        setChoices(updatedOptions);
    };
    
    const handleMessageChange = (index, value) => {
        const updatedMessages = [...messages];
        updatedMessages[index] = value;
        setMessages(updatedMessages);
    };
    

    // add options
    const addOption = () => {
        let num = optionNum + 1;
        setOptionNum(num);
    }

    // delete option
    const handleDeleteOption = (label, index) => {
        if (optionNum <= 2) {
            alert("Options cannot be less than 2, please add a new one before deleting");
            return;
        }
        // if (label === correctAnswer) { 
        //     // setCorrectAnswer(null);
        //     // handleCorrectAnswerChange(null);
        // } else {
        //     let answers = ['A', 'B', 'C', 'D', 'E'];
        //     for (let i = index; i < optionNum; i++) {
        //         if (answers[i] === correctAnswer) {
        //             setCorrectAnswer(answers[i-1]);
        //             // handleCorrectAnswerChange(answers[i-1]);
        //             break;
        //         }
        //     }
        // }
        
        for (let i = index; i < optionNum; i++) {
            if (i === 4) {
                choices[i] = "";
                messages[i] = "";
            }else {
                choices[i] = choices[i+1];
                messages[i] = messages[i+1];
            }
        }
        let num = optionNum - 1;
        setOptionNum(num);
    };

    // correct answer for the question
    // const handleCorrectAnswerChange = (label) => {
    //     setCorrectAnswer(label);
    // };
    

    // display options
    const renderOptions = () => {
        return ['A', 'B', 'C', 'D', 'E'].map((label, index) => {
            if (index <= optionNum - 1) {
                // console.log(label);
                return (
                    <div className='options-list' key={label}>
                        <DeleteOutlined className='delete-option' style={{marginRight: '15px'}} onClick={() => handleDeleteOption(label, index)} />
                        {/* <input
                            type='radio'
                            name='correctAnswer'
                            checked={correctAnswer === label}
                            onChange={() => handleCorrectAnswerChange(label)}
                            style={{ marginRight: '5px' }}
                        /> */}
                        <div className='options-label'>
                            <label>{label}: </label>
                        </div>
                        <div className='options-content'>
                            <Input
                                type="text"
                                value={choices[index] || ''}
                                onChange={(e) => handleOptionChange(index, e.target.value)}
                                style={{ width: '95%', marginBottom: '10px' }}
                                maxLength={80} 
                            />
                        </div>
                        <div className='options-label'>
                        </div>
                        <div className='comment-content-'>
                            <Input
                                type="text"
                                value={messages[index] || ''}
                                onChange={(e) => handleMessageChange(index, e.target.value)}
                                style={{ width: '100%', marginBottom: '0px' }}
                            />
                        </div>
                        
                    </div>
                );
            } else {
                // Return null or an alternative component for empty or undefined choices
                return null;
            }
        });
    }

    const submitQuestion = async () => {
        if (!questionText) {
            alert("Question Title cannot be empty!");
            return false;
        } 
        for (let i = 0; i < optionNum; i++) {
            if (!choices[i] || !messages[i]) {
                alert("Please fill out all necessary inputs!");
                return false;
            }
        }
        setIsLoading(true);
        const questionData = {
            questionid: questionID,
            type: 'multiple_choice',
            text: questionText,
            choices: choices.slice(0, optionNum), 
            messages: messages.slice(0, optionNum),
            optionNum: optionNum,
            correctAnswer: correctAnswer
        };
    
        const apiUrl = `${process.env.REACT_APP_API_URL}/lesson/updatemcquestion`;
    
        try {
            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(questionData)
            });
    
            if (!response.ok) {
                setIsLoading(false);
                throw new Error('Failed to update the question');
            }
    
            const data = await response.json();
            notification.success({
                message: 'Success',
                description: 'Question updated successfully!',
            });
    
            const questionDetails = {
                questionid: questionID,
                type: 'multiple_choice',
                text: questionText,
                choices: choices.slice(0, optionNum), 
                messages: messages.slice(0, optionNum),
                optionNum: optionNum,
                correctAnswer: correctAnswer
            };
    
            handleUpdateQuestion(questionDetails);
            setIsLoading(false);
            setIsEditModalVisible(false);
            return true;
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error.message || 'There was an error updating the question.',
            });
            setIsLoading(false);
            return false;
        }
    };
    
    
    const cancelEdit = () => {
        setQuestionText(questionData.text);
        setChoices(questionData.choices);
        setMessages(questionData.messages);
        setOptionNum(questionData.optionNum);
        setCorrectAnswer(questionData.correctAnswer || null);
        setLoading(false);
    }

    const setNewData = (outputValue) => {
        // Extract the question
        // const question_text = outputValue.match(/^.+?(?=\nA\.)/s)[0].trim();
        let question_text = outputValue.match(/^.+?(?=\nA\.)/s)[0].trim().replace(/\n/g, ' ');
        question_text = question_text.length > 148 ? question_text.substring(0, 148) : question_text;

        // Extract options
        // const options = outputValue.match(/^[A-E]\. .+?(?=\n|$)/gm).map(option => option.replace(/^[A-E]\. /, ''));
        // Extract options and limit each to 80 characters
        const options = outputValue.match(/^[A-E]\. .+?(?=\n|$)/gm).map(option => {
            const cleanedOption = option.replace(/^[A-E]\. /, '').trim();
            return cleanedOption.length > 80 ? cleanedOption.substring(0, 80) : cleanedOption;
        });

        // Extract explanations
        // const explanations = outputValue.match(/^[a-e]\. .+?(?=\n|$)/gm).map(option => option.replace(/^[a-e]\. /, ''));
        // Extract explanations and limit each to 100 characters
        const explanations = outputValue.match(/^[a-e]\. .+?(?=\n|$)/gm).map(option => {
            const cleanedExplanation = option.replace(/^[a-e]\. /, '').trim();
            return cleanedExplanation.length > 100 ? cleanedExplanation.substring(0, 100) : cleanedExplanation;
        });

        // const OptionA = options[0];
        // const OptionB = options[1];
        // const OptionC = options[2];
        // const OptionD = options[3];
        // const OptionE = options[4];
        // const ExplanationA = explanations[0];
        // const ExplanationB = explanations[1];
        // const ExplanationC = explanations[2];
        // const ExplanationD = explanations[3];
        // const ExplanationE = explanations[4];

        const OptionA = options[0] || '';
        const OptionB = options[1] || '';
        const OptionC = options[2] || '';
        const OptionD = options[3] || '';
        const OptionE = options[4] || '';
    
        const ExplanationA = explanations[0] || '';
        const ExplanationB = explanations[1] || '';
        const ExplanationC = explanations[2] || '';
        const ExplanationD = explanations[3] || '';
        const ExplanationE = explanations[4] || '';

        setQuestionText(question_text);
        const validOptions = options.filter(option => option && option.trim() !== '');
        setOptionNum(validOptions.length);
        setChoices([OptionA, OptionB, OptionC, OptionD, OptionE]);
        setMessages([ExplanationA, ExplanationB, ExplanationC, ExplanationD, ExplanationE]);
        setLoading(false);
        // console.log(choices);
    }

    // const AIRegenerateMC = () => {
    //     setLoading(true);
    //     // console.log(questionText);
    //     let aitext = 'Genearate 1 multiple choice question (question title must be within 148 characters) very similar to the question (same topic) - "' + questionText + '."' + 'The question is computer science related, and it does not use any programming language. And it has 5 choices (each choice is within 80 characters). And only one of them is correct. Explain each choice why they are right or wrong, with max 80 characters for each explanation, only include explanations. Use A, B, C, D, E for each choice and a, b, c, d, e for each explanation.';
        
    //     fetch(`${process.env.REACT_APP_API_URL}/api/aigenerate-question`,{
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ text: aitext })
    //     })
    //     .then(response => {
    //         if (!response.ok) { // Check if response is ok (i.e., status is 200-299)
    //             throw new Error('Network response was not ok: ' + response.statusText);
                
    //         }
    //         // console.log(response);
    //         return response.json(); // Converts the response to JSON
    //     })
    //     .then(data => {
    //         // console.log('Success:', data.text);
    //         // setOutputValue(data.text);
    //         if (!data) throw new Error("Failed to load question data.");
    //         setNewData(data.text);
    //     })
    //     .catch(error => {
    //         console.error("Error fetching question data: ", error);
    //         // Handle error state here
    //     });
    // }

    const AIRegenerateMC = () => {
        setLoading(true);
    
        let retryCount = 0;
        const maxRetries = 2; // Maximum number of retries
    
        const fetchData = () => {

            const mc_option = choices.slice(0, optionNum).map((item, index) => {
                if (item !== null) {
                  return `${String.fromCharCode(65 + index)}. ${item}`; // 65 is the char code for 'A'
                }
                return item;
              });

            let mc_option_string = mc_option.filter(item => item !== null).join('; '); 
            // console.log(mc_option_string);

            let aitext = 'Genearate 1 multiple choice question (question title must be within 148 characters) which is very similar to the question (same topic) - "' + questionText +  '. ' + mc_option_string +'." ' + 'The question is computer science related, and it does not use any programming language. And it has 5 choices (each choice is within 80 characters). And only one of them is correct. Explain each choice why they are right or wrong, with max 80 characters for each explanation, only include explanations. Use A, B, C, D, E for each choice and a, b, c, d, e for each explanation.';
              
            // console.log(aitext);

            fetch(`${process.env.REACT_APP_API_URL}/api/aigenerate-question`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text: aitext })
            })
            .then(response => {
                if (!response.ok) {
                    // throw new Error('Network response was not ok: ' + response.statusText);
                    if (retryCount < maxRetries) {
                        retryCount++;
                        console.log(`Retrying... Attempt ${retryCount}`);
                        fetchData(); // Retry the fetch
                    } else {
                        setLoading(false);
                        alert('Failed to generate question, please try again later.');
                    }
                }
                return response.json();
            })
            .then(data => {
                if (!data) {
                    // throw new Error("Failed to load question data.");
                    if (retryCount < maxRetries) {
                        retryCount++;
                        console.log(`Retrying... Attempt ${retryCount}`);
                        fetchData(); // Retry the fetch
                    } else {
                        setLoading(false);
                        alert('Failed to generate question, please try again later.');
                    }
                }
                setNewData(data.text);
            })
            .catch(error => {
                console.error("Error fetching question data: ", error);
                if (retryCount < maxRetries) {
                    retryCount++;
                    console.log(`Retrying... Attempt ${retryCount}`);
                    fetchData(); // Retry the fetch
                } else {
                    setLoading(false);
                    alert('Failed to generate question, please try again later.');
                }
            });
        };
    
        fetchData(); // Initial call to the fetch function
    };
    

    // if (loading) {
    //     return <Spin size="large" />; // Show loading spinner while not ready
    // }

    return (
        <Spin spinning={loading} tip="processing..." size="large" >
        <div className='questionAndimage-lesson'>
            
            <div className='question-mc-lesson'>
                <div className='question-content-container'>
                    <div style={{marginTop: '25px', fontSize: '20px', fontWeight: 'bold'}}>
                        Question Title <span style={{ color: 'red' }}>*</span> 
                        <Button type="default" size="large" style={{marginLeft: '80px', backgroundColor: '#118f50', color: 'white', width: '12.5rem', marginTop: '8px'}} onClick={AIRegenerateMC}>AI Regenerate Question</Button>
                        <div style={{fontSize: '13px', marginTop: '-30px', marginLeft: '440px', fontWeight: 'normal'}}>
                            *Powered by <strong>Google Gemini</strong>
                        </div>
                    </div>
                    <TextArea
                        ref={inputRef}
                        type="text"
                        value={questionText}
                        placeholder="Question Title"
                        onChange={handleChange}
                        // onBlur={handleSave}
                        style={{ maxWidth: '100%', width: '100%', marginBottom: '10px', marginTop: '20px' }}
                        autoSize={{ minRows: 2, maxRows: 2 }} 
                        maxLength={148}
                    />
                </div>
                <div>
                    <div className='option-msg-title'>
                        <div className='option-msg-option-lesson'><strong>Options</strong><span style={{ color: 'red' }}>*</span></div>
                        <div className='option-msg-msg-lesson'><strong>Messages</strong> <span style={{ color: 'red' }}>*</span></div>
                    </div>
                    {renderOptions()}
                    { optionNum < 5  && <Button type="primary" onClick={() => addOption()} style={{marginTop:'15px'}}>Add New Option</Button> }
                    {/* <p>* Clcik the radio to set the correct answer</p> */}
                </div>
            </div>
            <div className='image-lesson-mc'>
                <div style={{marginTop: '0px', marginBottom: '18px', padding: '6px', hyphens: 'auto', color: 'red'}}>
                    The Multiple Choice module allows you to select by touching one out of five options. Maxeaningful message will appear on the screen. (Max: <strong> 100 characters</strong>). 
                </div>
                <img src={leesonmc_img} alt="Cannot Load Image" />
            </div>
            
        </div></Spin>
    );
});

export default LessonMCEdit;