import React, { useEffect, useState, useRef  } from 'react';
import { Input, Button, Steps, Spin } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';
// import { RightOutlined } from '@ant-design/icons';
import './index.css';

import Header from '../../components/header';
// import Footer from '../../components/footer';
import CreateNewLesson from '../../components/createNewLesson';
import PageAndContent from '../../components/pageAndContent';
import PreviewLesson from '../../components/previewLesson';

const { Step } = Steps;

const MyCreateLesson = () => {
    const [loading, setLoading] = useState(false);

    const { LessonID } = useParams();
    const { EditState } = useParams();
    const { stepID } = useParams();
    const navigate = useNavigate();
    const lessonRef = useRef(null);
    const lessoncontentRef = useRef(null);
    const previewlessonRef = useRef(null);
    const [importedLessonID, setImportedLessonID] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [editNum, setEditNum] = useState();
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    // const location = useLocation();
    const [currentStep, setCurrentStep] = useState();
    useEffect(() => {
        document.title = "Create Lesson";
        // console.log(LessonID);
        // console.log(EditState);
        if (EditState === '1') {
            setIsEdit(true);
            setEditNum(1);
        } else {
            setEditNum(0);
        }
        if (stepID === '0') {
            setCurrentStep(0);
        } else if (stepID === '1') {
            setCurrentStep(1);
        } else if (stepID === '2') {
            setCurrentStep(2);
        }else if (stepID === '3') {
            setCurrentStep(3);
        }
        
    }, []);

    const handleDataLoad = (loaded) => {
        setIsDataLoaded(loaded); 
    };

    // move step map to next
    const nextStep = async () => {
        let step = currentStep + 1;
        setCurrentStep(step);
        navigate(`/mycreatelesson/${LessonID}/${editNum}/${step}`);
        // window.location.reload();
        // if (step === 3) {
        //     // console.log('step test');
        //     try {                
        //         const generatePDFTrue = await previewlessonRef.current.fetchLessonData();
        //         if (generatePDFTrue) {
        //             console.log('3w53');
        //             // previewlessonRef.current.saveAsPdf();
        //         } else {

        //         }
        //     } catch (error) {
        //         console.error('Error saving pdf, please try again.')
        //     }
        // }
    };
    // move step map to previous
    const prevStep = () => {
        let step = currentStep - 1;
        setCurrentStep(step);
        navigate(`/mycreatelesson/${LessonID}/${editNum}/${step}`);
        // window.location.reload();
    };

    const handleBack = () => {
        if (currentStep === 0 || currentStep === 1) {
            navigate('/lessons');
            window.location.reload();
        } else if (currentStep === 3){
            // prevStep();     
            navigate(`/mycreatelesson/${LessonID}/1/1`);     
            window.location.reload();  
        }else {
            prevStep();
            window.location.reload();
        }
    }

    const handleNext = async () => {
        switch (currentStep) {
            case 0: {
                // call child component's handleCreateLesson function
                let isNext = false;
                if (lessonRef.current) {
                    isNext = lessonRef.current.handleCreateLesson();
                }
                if (isNext) {                  
                    nextStep();
                }
                break;
            }
            case 1: {
                // lessoncontentRef.current.handleAddContent()
                // .then(() => {
                //     nextStep();
                // })
                // .catch(error => {
                //     console.error('Error adding content:', error);
                //     // Handle error appropriately, maybe show a notification or alert
                // });
                // setLoading(true);
                // const isNextTrue = await lessoncontentRef.current.handleAddContent();
                // console.log('isnexttrue', isNextTrue);
                // if (isNextTrue === true) {
                //     // console.log('isnexttrue', isNextTrue);
                //     setTimeout(() => {
                //         nextStep();
                //         setLoading(false);
                //     }, 900);
                // }

                setLoading(true);
                try {
                    const isNextTrue = await lessoncontentRef.current.handleAddContent();
                    if (isNextTrue) {
                        setTimeout(() => {
                            const isDragDone = lessoncontentRef.current.getDragEnded();
                            // console.log('is drag done: ', isDragDone);
                            if (!isDragDone) {
                                setLoading(false);
                                nextStep();
                            } else {
                                setTimeout(() => {
                                    const isDragDoneAgain = lessoncontentRef.current.getDragEnded();
                                    console.log('is drag done: ', isDragDoneAgain);
                                    if (!isDragDoneAgain) {
                                        setLoading(false);
                                        nextStep();
                                    }
                                }, 500);
                            }
                            
                        }, 1000);
                        // setLoading(false);
                        // nextStep();
                    } else {
                        console.error('Error: Unable to complete the content addition');
                    }
                } catch (error) {
                    console.error('Error adding content:', error);
                    
                }
                // setLoading(false);
                break;
            }
            case 2: {
                // previewlessonRef.current.saveAsPdf();
                nextStep();
                setLoading(true);
                function handleSavePdf() {
                    setTimeout(() => {
                        // setLoading(false);
                        let rcount = previewlessonRef.current.getRenderCount();
                        // console.log(rcount);
                        if (rcount === 1) {
                            setLoading(false);
                            previewlessonRef.current.saveAsPdf();
                        } else {
                            setTimeout(handleSavePdf, 1200);
                        }
                        
                    }, 1200);
                }
                handleSavePdf();
                break;
            }
            case 3: {
                // previewlessonRef.current.saveAsPdf();
                break;
            }
        }
   
    };

    const previewButtonRef = useRef(null);

    return (
        <Spin spinning={loading} tip="processing..." size="large" >
        <div>
            <Header />
            <div className='mycreatelesson-container'>
                <div className='createlesson-steps-lesson'>
                    {/* <Steps current={currentStep}> */}
                    <Steps current={!isEdit ? currentStep : (currentStep - 1)}>
                        { !isEdit && <Step title="Lesson Title and Description" /> }
                        <Step title="Edit Lesson Content" />
                        <Step title="Preview Lesson" />
                        <Step title="Complete" />
                    </Steps>
                </div>
                <div className='createlesson-page-body'>
                    <div className='createlesson-page-title'>
                        {currentStep === 0 && isEdit && <div style={{marginBottom: '0px'}}>Edit Lesson Title</div>}
                        {currentStep === 0 && !isEdit && <div>Create New Lesson</div>}
                        {/* {currentStep === 1 && <div style={{display: 'flex'}}>
                                                    <div>Edit Lesson Content</div>
                                              </div>
                        } */}
                        {currentStep === 2 && <div style={{display: 'flex', marginBottom: '0px'}}>
                                                    <div>Preview Lesson</div>
                                                    {/* <div style={{fontSize: '16px', fontWeight: 'normal', width: '780px', marginLeft: '210px', color: 'red', marginTop: '15px'}}>
                                                        Now, you can check every page, if the lesson is okay, please click <strong>Generate Instructions</strong> Button. 
                                                    </div> */}
                                             </div>
                        }
                        {currentStep === 3 && <div style={{display: 'flex', marginBottom: '0px'}}>
                                                    <div>Complete</div>
                                                    {/* <div style={{fontSize: '16px', fontWeight: 'normal', width: '780px', marginLeft: '290px', color: 'red', marginTop: '15px'}}>
                                                        Now, please click <strong>Generate PDF</strong> Button to get the PDF. And follow the instructions to set up the <strong>Tangibook</strong>. 
                                                    </div> */}
                                            </div>
                        }
                    </div>
                    <div className='createlesson-content'>
                        {currentStep === 0 && <CreateNewLesson ref={lessonRef} isEdit={isEdit} LessonID={LessonID} importedLessonID={importedLessonID} setImportedLessonID={setImportedLessonID} nextStep={nextStep}/>}
                        {currentStep === 1 && <PageAndContent  ref={lessoncontentRef} LessonID={LessonID} importedLessonID={importedLessonID} setImportedLessonID={setImportedLessonID} previewButtonRef={previewButtonRef} style={{ zIndex: 10 }} />}
                        {currentStep === 2 && <PreviewLesson ref={previewlessonRef} LessonID={LessonID} />}
                        {currentStep === 3 && <PreviewLesson ref={previewlessonRef} LessonID={LessonID} />}
                    </div>
                    {currentStep === 0 && 
                    <div className='handleNext_'>
                        <Button type="primary" size="large" style={{width: '6.5rem'}} onClick={handleBack}> &lt; Back </Button>
                        {isEdit ? <Button type="primary" size="large" style={{width: '6.5rem'}} onClick={handleNext}> Next &gt;</Button> : <Button type="primary" size="large" style={{width: '10.5rem'}} onClick={handleNext}> Create Own Lesson &gt;</Button>}
                    </div>}

                    {currentStep === 1 && 
                    <div>
                        <div className='preview-lesson-text'>
                            When you finish, please click <strong>Preview Lesson</strong> button to see how your lesson look like.
                        </div> 
                    <div className='handleNext_preview'>
                    {/* <div className='handleNext_'> */}
                        <Button type="primary" size="large" style={{width: '6.5rem', zIndex: 1}} onClick={handleBack}> &lt; Back </Button> 
                        <Button type="primary" size="large" style={{width: '10.5rem', zIndex: 1}} ref={previewButtonRef} onClick={handleNext}> Preview Lesson &gt;</Button>
                    </div>
                    </div>
                    }
                   
                    
                    {currentStep === 2 && 
                    // <div className='handleNext_pdf'>
                    <div className='handleNext_'>
                        <Button type="primary" size="large" style={{width: '6.5rem'}} onClick={handleBack}> &lt; Back </Button>
                        {/* <Button type="primary" size="large" style={{width: '13.5rem'}} onClick={handleNext}> Generate Instructions &gt;</Button> */}
                        <div style={{color: 'red', width: '450px', marginLeft: '25%'}}>
                            Check the lessonpages, go back to modify them. Click <strong>Generate PDF</strong> button to get final PDF.
                        </div> 
                        <Button type="primary" size="large" style={{width: '13.5rem'}} onClick={handleNext}> Generate PDF &gt;</Button>
                    </div>}

                    {currentStep === 3 && 
                    // <div className='handleNext_pdf'>
                    <div className='handleNext_'>
                        <Button type="primary" size="large" style={{width: '6.5rem'}} onClick={handleBack}> &lt; Back </Button>
                        <div style={{color: 'red', width: '550px', marginLeft: '0%'}}>
                            Please print it and check instructions on the PDF.
                        </div> 
                        {/* <Button type="primary" size="large" style={{width: '11.5rem'}} onClick={handleNext}> Generate Lesson &gt;</Button> */}
                    </div>}

                </div>
            </div>
            {/* <Footer /> */}
        </div>
        </Spin>
    )
};

export default MyCreateLesson;