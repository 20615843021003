import React, { useState, useEffect } from 'react';
import './index.css';
import OptionShape from '../../components/optionShape';
import MatchShape from '../../components/matchShape';
// import { DeleteOutlined } from '@ant-design/icons';

const RenderMatchesLesson = ({ question }) => {
    const [matches, setMatches] = useState([]);
    // const [optionNum, setOptionNum] = useState(0);

    useEffect(() => {
        // Fetching matches based on questionId
        const fetchMatches = async () => {

            // console.log('matching question', question);
            if (question.matches) {
                const OptionNum = question.optionNum;
                const [ A, B, C, D, E, MatchA, MatchB, MatchC, MatchD, MatchE ] = question.matches.slice(0, 10);
                setMatches([A, B, C, D, E, MatchA, MatchB, MatchC, MatchD, MatchE]); // Store matches as an array
                setMatches([
                    { option: A, match: MatchA },
                    { option: B, match: MatchB },
                    { option: C, match: MatchC },
                    { option: D, match: MatchD },
                    { option: E, match: MatchE }
                ].slice(0, OptionNum));
            } else {
                setMatches(["", "", "", "", "", "", "", "", "", ""]); // Default empty if no matches are found
            }
        };
        fetchMatches();
        // console.log(matches);
    }, [question]);

    return (
        <div>
            {matches.map((match, index) => (
                <div key={`match-pair-${index}`} className='render-matches-left-right'>
                    <div key={`left-${index}`} className='render-mataches-list'
                        style={
                            index === 3 ? { marginTop: '-9px' } :
                            index === 4 ? { marginTop: '-18px' } :
                            {}
                        }
                    > 
                        <OptionShape content={match.option} />
                    </div> 
                    <div key={`right-${index}`} className='render-mataches-list-right'
                        style={(index === 2 || index === 3) ? { marginTop: '9px' } : {}}
                    > 
                        <MatchShape content={match.match} />
                    </div> 
                </div>
            ))}

        </div>
    );
};

export default RenderMatchesLesson;
