import React, { useState, useEffect, useRef, useCallback} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Input, Button, List, Radio, message } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import './index.css'

import Header from '../../components/header'
import QuestionWindow from '../../components/addQuestionWindow'
import QuestionDetail from '../../components/questionDetail'
// import Prereview from '../prereview';

import BreadCrumbs from '../../components/breadCrumbs';

const Questions = () => {

    const navigate = useNavigate();
    const { quizId } = useParams();
    const [questions, setQuestions] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fetchFirstQuestion, setFetchFirstQuestion] = useState(true);
    const [selectedQuiz, setSelectedQuiz] = useState(null);
    const [windowOpen, setWindowOpen] = useState(false);
    const [questionType, setQuestionType] = useState(''); 
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [edit_title, setEditTitle] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        document.title = "Question";
        fetchQuestions();
    }, [quizId]);

    const handleDataChange = (newData) => {
        setEditTitle(newData);
    };

    // fetch quizs from the database
    const fetchQuiz = async () => {
        const userid = localStorage.getItem('userid');
        fetch(`${process.env.REACT_APP_API_URL}/api/users/quizs/${userid}`)
        // fetch(`${process.env.REACT_APP_API_URL}/api/quizs`)
            .then(response => response.json())
            .then(data => {
                // console.log("Quizs fetched:", data);
                setSelectedQuiz(data.find(quiz => quiz.LID == quizId));
            })
            .catch(error => console.error('Failed to fetch questions:', error));
    };

    // fetchQuiz(quizId);

    useEffect(() => {
        fetchQuiz(quizId);
    }, [quizId]);

    useEffect(() => {
        if (isModalVisible) {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 100);
        }
    }, [isModalVisible]);    

    useEffect(() => {
        setEditTitle(''); 
        if (selectedQuestion) {
            setEditTitle(selectedQuestion.text || '');
        } 
    }, [selectedQuestion]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    // save question title
    const saveQuestion = async (id, text) => {
        // console.log('update question'); // test use only
        // console.log(text); // test use only
        if (!id) {
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/questions/update/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text })
            });
            const data = await response.json();
            if (!data.success) {
                console.error('Failed to save question:', data.message);
            }
        } catch (error) {
            console.error('Error saving changes:', error);
        }
    };

    const fetchQuestions = useCallback(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/questions/${quizId}`)
            .then(response => response.json())
            .then(data => {
                // setQuestions(Array.isArray(data) ? data : []);
                const formattedData = Array.isArray(data) ? data : [];
                setQuestions(formattedData);
                if (fetchFirstQuestion) {
                    // Automatically select the first question if there are any
                    if (formattedData.length > 0) {
                        setSelectedQuestion(formattedData[0]);
                    } else {
                        // If there are no questions, set selectedQuestion to null
                        setSelectedQuestion(null);
                    }
                    setFetchFirstQuestion(false);
                }
            })
            .catch(error => console.error('Failed to fetch questions:', error));
            // setFetchFirstQuestion(false);
    }, [quizId, fetchFirstQuestion]);

    useEffect(() => {
        fetchQuestions();
    }, [fetchQuestions]); // This useEffect triggers whenever selectedQuiz changes

    // delete question
    const handleDelete = (questionId, index) => {
        // console.log(`Deleting question ${questionId}`);
        // console.log(`Deleting question ${selectedQuestion.id}`);
        fetch(`${process.env.REACT_APP_API_URL}/api/questions/${questionId}`, {
            method: 'DELETE'
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to delete the question');
            }
            setQuestions(prev => prev.filter((_, i) => i !== index)); 
            if (selectedQuestion!==null) {
                if(questionId === selectedQuestion.id) {
                    // console.log("equal"); // test use only
                    fetchQuestions();
                    setSelectedQuestion(null);
                } else {
                    fetchQuestions();
                    setSelectedQuestion(selectedQuestion);
                }
            } else {
                fetchQuestions();
                setSelectedQuestion(null);
            }
        })
        .catch(error => console.error('Error deleting question:', error));
    };
    
    const openWindow = () => {
        if (selectedQuiz === null) {
            alert('Please select a quiz or create a new quiz before adding new question.');
            return;
        }
        setWindowOpen(true);
    };
    const closeWindow = () => setWindowOpen(false);

    const handleCancel = () => {
        setQuestionType('');
        closeWindow();
    };

    const selectQuestionType = (type) => {
        setQuestionType(type);
    };
    // add new question
    const handleAdd = () => {
        if (questionType === ''){
            alert('Please select a question type before adding.');
            return;
        }
        // console.log("Adding a new question of type: ", questionType); // test use only
        const questionData = {
            QuizsID: selectedQuiz.LID,
            type: questionType
        };

        fetch(`${process.env.REACT_APP_API_URL}/api/questions/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(questionData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Could not add new question!');
            }
            return response.json();
        })
        .then(data => {
            if (data.success) {
                // alert(data.message); // 'Question added successfully!'
                fetchQuestions(); // Refresh the list of questions to show the new one
                // console.log(data.questionId);
                setSelectedQuestion({
                    id: data.questionId,
                    text: null,
                    type: questionType
                });
                // setQuestionType('');
            } else {
                throw new Error(data.message); // If success is false, throw an error to be caught by the catch block
            }
        })
        
        .catch((error) => {
            console.error('Error:', error);
        });
        // fetchQuestions();
        setQuestionType('');
        closeWindow();
    };
    
    // display selected questions
    const handleQuestionClick = (question) => {
        fetchQuestions();
        // setSelectedQuestion(null);
        setSelectedQuestion(question);
        // fetchQuestions();
        // // setSelectedQuestion(null);
        // setSelectedQuestion(question);
    };

    // display question type
    const getQuestionType = (type) => {
        switch(type) {
            case 'multiple_choice':
                return 'Multiple Choice';
            case 'true_false':
                return 'True / False';
            case 'matching':
                return 'Matching';
            case 'ranking':
                return 'Ranking';
            case 'object_placement':
                return 'Object Placement';
            default:
                break;
        }
    };

    const handlePreReviewQuiz = () => {
        if (selectedQuiz === null) {
            alert('Please select a quiz before pre-review the quiz.');
            return;
        }
        navigate(`/prereview/${selectedQuiz.LID}`);
    };

    const handleSave = () => {
        message.success({
            content: 'Quiz is saved.',
            duration: 1,  // Duration the message is shown in seconds
            style: {
                marginTop: '10vh',  // Optionally adjust the vertical position
            }
        });
    };    

    return (
        <div>
            <Header />
            {/* <BreadCrumbs quizName={selectedQuiz ? selectedQuiz.LTitle : null} /> */}
            <div className='question-page-body'>

                {/* QUESTIONS */}
                <div className='question-manage-container'>
                    <div className='question-list'>
                        <h3>Questions</h3>
                        {questions.map((question, index) => (
                            <div 
                                // className={`question-item ${selectedQuestion && selectedQuestion.id === question.id ? 'active' : ''}`}
                                className='question-item'
                                key={question.id} 
                                onClick={() => handleQuestionClick(question)}
                            >
                                {/* <div className='question-title'> */}
                                <div className={`question-title ${selectedQuestion && selectedQuestion.id === question.id ? 'active' : ''}`} >
                                    {/* {index + 1}. {question.text} */}
                                    {index + 1}. {selectedQuestion && selectedQuestion.id === question.id ? edit_title : question.text}
                                    <div className='question-type'>
                                        {getQuestionType(question.type)}
                                        
                                    </div>
                                </div>
                                {/* <div className='delete-mark' onClick={() => handleDelete(question.id, index)} >🗑️</div> */}
                                <DeleteOutlined className='delete-mark' onClick={() => handleDelete(question.id, index)}/>
                            </div>
                        ))}
                        <Button type="primary" onClick={() => openWindow()}>Add Question</Button> 
                    </div>

                    <div className='question-edit-area'>
                        <div className='operation-area'>
                            
                            {/* <button onClick={() => handlePreReviewQuiz()}>Pre-reivew Quiz</button> */}
                            <Button type="primary" onClick={() => handlePreReviewQuiz()}>Generate Quiz</Button>
                            {/* <Button type="primary" onClick={() => Quiz()}>Create New Quiz</Button> */}
                            {/* <Button type="primary" onClick={showModal}>Add Quiz</Button> */}
                            {/* <Button type="primary" onClick={() => openWindow()}>Add Question</Button>  */}
                            <Button type="primary" onClick={() => handleSave()}>Save</Button>
                            
                        </div>
                        <div className='question-window'>
                            <QuestionWindow isOpen={windowOpen} >
                                <h2 style={{padding: '20px'}}>New Question</h2>
                                <p style={{marginBottom: '15px'}}>Type of question:</p>                         
                                <Radio.Group
                                    className="custom-radio-button"
                                    value={questionType}
                                    onChange={(e) => selectQuestionType(e.target.value)}
                                    style={{ display: 'flex', flexDirection: 'column'}}
                                >
                                    <Radio.Button value="multiple_choice" className={questionType === 'multiple_choice' ? 'active' : ''}>Multiple Choices</Radio.Button>
                                    <Radio.Button value="matching" className={questionType === 'matching' ? 'active' : ''}>Matching</Radio.Button>
                                    <Radio.Button value="true_false" className={questionType === 'true_false' ? 'active' : ''}>True / False</Radio.Button>
                                    <Radio.Button value="ranking" className={questionType === 'ranking' ? 'active' : ''}>Ranking</Radio.Button>
                                    <Radio.Button value="object_placement" className={questionType === 'object_placement' ? 'active' : ''}>Object Placement</Radio.Button>
                                </Radio.Group>

                                <div className='question-window-button'>
                                    {/* <button onClick={() => handleCancel()}>Cancel</button>
                                    <button onClick={() => handleAdd()}>Add</button> */}
                                    <Button type="primary" onClick={() => handleCancel()}>Cancel</Button>
                                    <Button type="primary" onClick={() => handleAdd()}>Add</Button>
                                </div>
                            </QuestionWindow>
                        </div>
                        <div className='question-details'>
                            {selectedQuestion !== null && selectedQuestion !== undefined ? (
                                <QuestionDetail question={selectedQuestion} onDataChange={handleDataChange} onSave={saveQuestion}>

                                </QuestionDetail>
                            ) : (
                                <p>Add New Question!</p>
                            )}
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
    };

export default Questions;