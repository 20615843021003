import React, { useState, useEffect } from 'react';
import './index.css';
import { Button, Input } from 'antd';
import WebHeader from '../../components/header';
import geminiImg from '../../assets/gemini.png';

const { TextArea } = Input;

const QuestionBank = () => {
    const [inputValue, setInputValue] = useState('');
    const [outputValue, setOutputValue] = useState('');
    const [mcNum, setMcNum] = useState('0');
    const [tfNum, setTfNum] = useState('0');
    const [matchingNum, setMatchingNum] = useState('1');
    const [rankingNum, setRankingNum] = useState('0');
    useEffect(() => {
        document.title = "AI Generate Question";
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();  // Prevent the default action to avoid a newline in textarea
            submitRequest();
        }
    };    

    const handleInputChange = (e) => {
        setInputValue(e.target.value); // Update state with input value
    };

    // Handler to update the state only if the value is between 0 and 9
    const handleNumberChange = (value, setter) => {
        if (/^[0-9]?$/.test(value)) {
            setter(value);
        }
    };

    const submitRequest = () => {
        if (inputValue.trim().length === 0) {
            return;
        }
        // if ((mcNum === '0' || mcNum === '') && (tfNum === '0' || tfNum === '') && (matchingNum === '0' || matchingNum === '') && (rankingNum === '0' || rankingNum === '')) {
        //     alert('Please input the number, at least generate one question!');
        //     return;
        // }
        let aitext = inputValue + '.';
        // console.log(inputValue); // test use only
        // if (mcNum !== '0' && mcNum !== '') {
        //     aitext = aitext + 'Genearate ' + mcNum + ' multiple choice questions (question title must be within 148 characters) that does not use any programming language, with 5 choices (each choice is within 80 characters). And only one of them is correct. Explain each choice why they are right or wrong, with max 80 characters for each explanation, only include explanations. Use A, B, C, D, E for each choice and a, b, c, d, e for each explanation.';
        // }
        // if (tfNum !== '0' && tfNum !== '') {
        //     aitext = aitext + 'Genearate ' + tfNum + ' true / false questions.';
        // }
        // if (matchingNum !== '0' && matchingNum !== '') {
            aitext = aitext + 'Generate 1 matching question related to computer science that does not involve any programming language. The question title must be within 99 characters. The question should include 5 different options (labeled A, B, C, D, E), each within 26 characters, that pair with 5 different corresponding options (labeled a, b, c, d, e), also within 26 characters. Every option must have different pairing options. The order of the options should be random. After creating the question, explain each pairing (labeled with *). Each explanation is within 100 characters. Two sets of options are in seperate area.';
        // }
        // if (rankingNum !== '0' && rankingNum !== '') {
        //     aitext = aitext + 'Genearate ' + rankingNum + ' ranking questions (5 options).';
        // }
        // aitext = aitext + 'Must have answer.';
        // console.log(aitext);
        fetch(`${process.env.REACT_APP_API_URL}/api/aigenerate-question`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text: aitext })
        })
        .then(response => {
            if (!response.ok) { // Check if response is ok (i.e., status is 200-299)
                throw new Error('Network response was not ok: ' + response.statusText);
            }
            // console.log(response);
            return response.json(); // Converts the response to JSON
        })
        .then(data => {
            // console.log('Success:', data.text);
            setOutputValue(data.text);
        })
        .catch((error) => console.error('Error:', error));

        // setInputValue('');
        // setMcNum('0');
        // setTfNum('0');
        // setMatchingNum('0');
        // setRankingNum('0');
    };

    const outputDisplay = () => {
        // // Extract the question
        // // const question_text = outputValue.match(/^.+?(?=\nA\.)/s)[0].trim();
        const question_text = outputValue.match(/^.+?(?=\nA\.)/s)[0].trim().replace(/\n/g, ' ');
        // Extract options
        const options = outputValue.match(/^[A-E]\. .+?(?=\n|$)/gm).map(option => option.replace(/^[A-E]\. /, ''));
        // Extract pairing options
        const pair_options = outputValue.match(/^[a-e]\. .+?(?=\n|$)/gm).map(option => option.replace(/^[a-e]\. /, ''));
        // const explanations = outputValue.match(/^[a-e]\. .+?(?=\n|$)/gm).map(option => option.replace(/^[a-e]\. /, ''));
        // Extract explanations and correct answers
        const regex_expla_ans = /\* (\w) - (\w):\s*(.+)/g;
        let match;
        const results = [];
        while ((match = regex_expla_ans.exec(outputValue)) !== null) {
            results.push({
                label: match[1],
                correctAnswer: match[2],
                explanation: match[3].trim()
            });
        }
        console.log(results);


        const OptionA = options[0];
        const OptionB = options[1];
        const OptionC = options[2];
        const OptionD = options[3];
        const OptionE = options[4];
        const pair_optiona =pair_options[0];
        const pair_optionb =pair_options[1];
        const pair_optionc =pair_options[2];
        const pair_optiond =pair_options[3];
        const pair_optione =pair_options[4];
        // const ExplanationA = explanations[0];
        // const ExplanationB = explanations[1];
        // const ExplanationC = explanations[2];
        // const ExplanationD = explanations[3];
        // const ExplanationE = explanations[4];

        const regex = /\s*(?:options|Options|options:|Options:)\s*$/;
        const filerted_question_text = question_text.replace(regex, '').trim();
        console.log("Question: ", filerted_question_text);
        console.log("Option A: ", OptionA);
        console.log("Option B: ", OptionB);
        console.log("Option C: ", OptionC);
        console.log("Option D: ", OptionD);
        console.log("Option E: ", OptionE);
        console.log("Option a: ", pair_optiona);
        console.log("Option b: ", pair_optionb);
        console.log("Option c: ", pair_optionc);
        console.log("Option d: ", pair_optiond);
        console.log("Option e: ", pair_optione);
        // console.log("Explanation A: ", ExplanationA);
        // console.log("Explanation B: ", ExplanationB);
        // console.log("Explanation C: ", ExplanationC);
        // console.log("Explanation D: ", ExplanationD);
        // console.log("Explanation E: ", ExplanationE);

        return outputValue;
    }

    return (
        <div className='aipart-body'>
            <WebHeader />
            <div className='aiquestion-body'>
                <div className='aiquestion-input'>
                    <div className='aiquestion-input-left'>
                        <p className='label-title'>Input the number of questions generated by AI:</p>
                        <ul className='label-list' style={{ listStyleType: 'none', padding: 0 }}>
                            {/* <li>
                                <p className='type-label'> Multiple Choice:</p> 
                                <input 
                                    type="number" 
                                    value={mcNum}
                                    onChange={(e) => handleNumberChange(e.target.value, setMcNum)}
                                    min="0"
                                    max="9"
                                />
                            </li> */}
                            {/* <li>
                                <p className='type-label'> True / False: </p>
                                <input 
                                    type="number" 
                                    value={tfNum}
                                    onChange={(e) => handleNumberChange(e.target.value, setTfNum)}
                                    min="0"
                                    max="9"
                                />
                            </li> */}
                            <li>
                                <p className='type-label'> Matching: </p>
                                <input 
                                    type="number" 
                                    value={matchingNum}
                                    onChange={(e) => handleNumberChange(e.target.value, setMatchingNum)}
                                    min="0"
                                    max="9"
                                />
                            </li>
                            {/* <li>
                                <p className='type-label'> Ranking: </p>
                                <input 
                                    type="number" 
                                    value={rankingNum}
                                    onChange={(e) => handleNumberChange(e.target.value, setRankingNum)}
                                    min="0"
                                    max="9"
                                />
                            </li> */}
                        </ul>
                    </div>
                    <div className='aiquestion-input-right'>
                        <div className='aiquestion-input-box'>
                            <TextArea 
                                placeholder="Input here! Example: Generate questions about sort algorithm"
                                autoSize={{ minRows: 4, maxRows: 4 }}
                                autoFocus 
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                style={{ width: '100%', borderRadius: '10px', fontSize: '18px' }}
                            />
                            <Button type="primary" onClick={submitRequest} style={{fontSize: '20px', marginTop: '23px', height: '40px', width: '100%'}}> Submit </Button>
                        </div>
                    </div>
                </div>
                <div className='aiquestion-output'>
                    <div className='aiquestion-output-left'>
                        <div className='geminiImg'>
                            <p> Powered by Google Gemini </p>
                            <div className='geminiImg-img'>
                                <img src={geminiImg} alt="Gemini" />
                            </div>
                        </div>
                    </div>
                    <div className='aiquestion-output-right'>
                        {/* output here*/}
                        {outputDisplay()}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default QuestionBank;