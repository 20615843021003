import React, { useState, useEffect } from 'react';
import './index.css';
// import { DeleteOutlined } from '@ant-design/icons';

const RenderOptions = ({ question }) => {
    // console.log(question);
    const questionId = question.id;
    // console.log(questionId);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        // fetching options based on questionId
        const fetchOptions = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/choices/${questionId}`);
            const results = await response.json();
            // console.log(results.data);
            if (response.ok) {
                if (results.data[0]) {
                    // const { A, B, C, D, E } = results.data[0];
                    // setOptions([A, B, C, D, E]); // Store options as an array
                    const optionsData = results.data[0];
                    const newOptions = ['A', 'B', 'C', 'D', 'E'].map(key => optionsData[key]).filter(opt => opt || opt === 0 || opt === false);
                    setOptions(newOptions);
                } else {
                    setOptions([]); // Default empty options if no options are found
                }
            } else {
                console.error('Failed to fetch options:', results.message);
            }
        };
        fetchOptions();
    }, [questionId]);

    return (
        <div>
            <div className='options-display'>
                {options.map((option, index) => (
                    <div key={index} className='render-options-list'>            
                        <svg width="20" height="16" style={{ marginRight: '3px',  marginTop: '1px'}}>
                            <circle cx="8" cy="8" r="7"  fill="white" stroke="black" strokeWidth="1"/>
                        </svg>
                        <div className='option-list-content'>
                            {option} 
                        </div>
                    </div> 
                ))}          
            </div>
            <div className='renderOptionBodyImg'>
                {question.imgpath && <img className='mc_img' src={`/images/${question.imgpath}`} alt="Cannot Load Img" />}
            </div>
        </div>
    );
};

export default RenderOptions;
