import React, { forwardRef,useImperativeHandle, useState, useEffect  } from 'react';
import { Input, Button, notification,  Radio, Space } from 'antd';
import { EditOutlined, DeleteOutlined, StopOutlined } from '@ant-design/icons';
import lensmodule_img from '../../assets/lensmodule.png';
import './index.css';

const LensModule = forwardRef((props, ref) => {
    const [lens1, setLens1] = useState('');
    const [lens2, setLens2] = useState('');
    const [lens3, setLens3] = useState('');
    const [lens4, setLens4] = useState('');
    const [tempInput, setTempInput] = useState('');
    const [lens, setLens] = useState(1);

    useEffect(() => {
        setLens1(props.lens1);
        setLens2(props.lens2);
        setLens3(props.lens3);
        setLens4(props.lens4);
        setLens(1);
        setTempInput('');
    }, [props]);  
    
    useImperativeHandle(ref, () => ({
        // setLens,
        getInputsLens,
        handleSubmit
    }));

    const getInputsLens = () => {
        return tempInput;
    }

    const handleSubmit = async () => {
        const data = { lens1, lens2, lens3, lens4 };
        const pageId = props.pageid;
        // setLens(1);
        props.setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/lesson/lensmodule/update/${pageId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
    
            const result = await response.json();
    
            if (!response.ok) {
                throw new Error("Failed to save module data");
            }
    
            // console.log('Data saved:', result);
            notification.success({
                message: 'Success',
                description: 'Module data added successfully!',
                duration: 2
            });
            props.onUpdate(pageId, { lens1, lens2, lens3, lens4 });
            props.setIsLoading(false);
            // Optionally close modal if there's one
            return true;
        } catch (error) {
            console.error('Error saving data:', error);
            notification.error({
                message: 'Error',
                description: 'Error saving module data: ' + error.message,
            });
            props.setIsLoading(false);
            return false;
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();  // Prevents the default action of the enter key
        }
    };

    const onLensChange = (e) => {
        // console.log('radio checked', e.target.value);
        setLens(e.target.value);
    };

    const handleAddLens = () => {
        switch (lens) {
            case 1:
                setLens1(tempInput);
                break;
            case 2:
                setLens2(tempInput);
                break;
            case 3:
                setLens3(tempInput);
                break;
            case 4:
                setLens4(tempInput);
                break;
            default:
                break;
        }
        setTempInput('');
    }

    const handleDeleteLens = (lensNumber) => {
        switch (lensNumber) {
            case 1:
                setLens1('');
                break;
            case 2:
                setLens2('');
                break;
            case 3:
                setLens3('');
                break;
            case 4:
                setLens4('');
                break;
            default:
                break;
        }
    }

    const handleEditLens = (lensNumber) => {
        setLens(lensNumber);
        switch (lensNumber) {
            case 1:
                setTempInput(lens1);
                setLens1('');
                break;
            case 2:
                setTempInput(lens2);
                setLens2('');
                break;
            case 3:
                setTempInput(lens3);
                setLens3('');
                break;
            case 4:
                setTempInput(lens4);
                setLens4('');
                break;
            default:
                break;
        }
    };

    const isAddLensDisabled = () => {
        switch (lens) {
            case 1:
                return lens1 !== '';
            case 2:
                return lens2 !== '';
            case 3:
                return lens3 !== '';
            case 4:
                return lens4 !== '';
            default:
                return true;
        }
    }
    

    return (
        <div className='lens-module-body'>
            <div className='lens-module-input'>
                <Radio.Group onChange={onLensChange} value={lens}>
                    <div style={{display: 'flex'}}>
                        <div className="radio-row-lens">
                            <Space direction="vertical">
                                <Radio value={1}>Top Left Page Section <img src={"path_to_image"} alt=""/></Radio>
                                <Radio value={3}>Bottom Left Page Section <img src={"path_to_image"} alt=""/></Radio>
                            </Space>
                        </div>
                        <div className="radio-row-lens">
                            <Space direction="vertical">
                                <Radio value={2}>Top Right Page Section <img src={"path_to_image"} alt=""/></Radio>
                                <Radio value={4}>Bottom Right Page Section <img src={"path_to_image"} alt=""/></Radio>
                            </Space>
                        </div>
                    </div>
                </Radio.Group>
                <div className='input-button-lens'>
                    <p style={{marginRight: '30px'}}>Message: </p> 
                    <Input.TextArea
                        className="lens-input"
                        value={tempInput}
                        onChange={e => setTempInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Input the message for lens here."
                        rows={2}
                        style={{ resize: 'none', marginRight: '20px' }}
                    />
                    {/* {isAddLensDisabled ? <Button size="large" style={{backgroundColor: '#118f50', color: 'white', marginBottom: '18px'}} onClick={handleAddLens} disabled={isAddLensDisabled()} >Add Lens</Button> :
                    <Button size="large" style={{backgroundColor: '#118f50', color: 'white', marginBottom: '18px'}} onClick={handleAddLens} disabled={isAddLensDisabled()} >Add Lens</Button>
                    } */}
                    <Button
                        size="large"
                        style={{
                            minWidth: '125px',
                            maxWidth: '125px', 
                            backgroundColor: isAddLensDisabled() ? '#a6a6a6' : '#118f50',
                            color: 'white',
                            marginBottom: '18px',
                            cursor: isAddLensDisabled() ? 'not-allowed' : 'pointer'
                        }}
                        onClick={handleAddLens}
                        disabled={isAddLensDisabled()}
                        icon={isAddLensDisabled() ? <StopOutlined style={{ color: 'red' }} /> : null}
                    >
                        {!isAddLensDisabled() && 'Add Message'}
                    </Button> 
                </div>
                <div>
                    {(lens1 || lens2 || lens3 || lens4) &&
                        <div style={{display: 'flex', marginBottom: '5px'}}>
                            <p style={{width: '180px', fontWeight: 'bold'}}>Lens Position</p>
                            <p style={{fontWeight: 'bold'}}>Message</p>
                        </div>
                    }
                    {lens1 &&  <div className='lens-msg-display'>
                                    <p style={{width: '125px'}}>Top Left:</p>
                                    <p style={{width: '300px', borderBottom: '1px solid #000000'}}>{lens1}</p>
                                    <EditOutlined style={{fontSize: '20px', marginRight: '10px', marginLeft: '10px', cursor: 'pointer'}} onClick={() => handleEditLens(1)} />
                                    <DeleteOutlined style={{fontSize: '20px', cursor: 'pointer'}} onClick={() => handleDeleteLens(1)} />
                                </div>}
                    {lens2 &&  <div className='lens-msg-display'>
                                    <p style={{width: '125px'}}>Top Right:</p>
                                    <p style={{width: '300px', borderBottom: '1px solid #000000'}}>{lens2}</p>
                                    <EditOutlined style={{fontSize: '20px', marginRight: '10px', marginLeft: '10px', cursor: 'pointer'}} onClick={() => handleEditLens(2)} />
                                    <DeleteOutlined style={{fontSize: '20px', cursor: 'pointer'}} onClick={() => handleDeleteLens(2)} />
                                </div>}
                    {lens3 &&  <div className='lens-msg-display'>
                                    <p style={{width: '125px'}}>Bottom Left:</p>
                                    <p style={{width: '300px', borderBottom: '1px solid #000000'}}>{lens3}</p>
                                    <EditOutlined style={{fontSize: '20px', marginRight: '10px', marginLeft: '10px', cursor: 'pointer'}} onClick={() => handleEditLens(3)} />
                                    <DeleteOutlined style={{fontSize: '20px', cursor: 'pointer'}} onClick={() => handleDeleteLens(3)} />
                                </div>}
                    {lens4 &&  <div className='lens-msg-display'>
                                    <p style={{width: '125px'}}>Bottom Right:</p>
                                    <p style={{width: '300px', borderBottom: '1px solid #000000'}}>{lens4}</p>
                                    <EditOutlined style={{fontSize: '20px', marginRight: '10px', marginLeft: '10px', cursor: 'pointer'}} onClick={() => handleEditLens(4)} />
                                    <DeleteOutlined style={{fontSize: '20px', cursor: 'pointer'}} onClick={() => handleDeleteLens(4)} />
                                </div>}
                </div>
            </div>
            <div className='image-lesson-lensmodule'>
                <div style={{ textAlign: 'left', marginTop: '0px', marginBottom: '18px', padding: '6px', hyphens: 'auto', color: 'red'}}>
                    This module allows you to put a len over the page. Meaningful message will appear on the screen of the len. (Max:<strong> 100 characters</strong>) . 
                </div>
                <img src={lensmodule_img} alt="Cannot Load Image" />
            </div>
        </div>
    );
});

export default LensModule;
