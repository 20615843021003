import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import './index.css'
import { FileImageOutlined } from '@ant-design/icons';
import { Modal, Button, notification } from 'antd';

const AddImage = forwardRef(({ questionId, onUpdateImageSrc  }, ref) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const fileInputRef = useRef(null);

    useImperativeHandle(ref, () => ({
        showModal
    }));

    const showModal = () => {
        // console.log(questionId);
        setIsModalVisible(true);
    };

    // add image
    const handleAddImge = async() => {
        if (fileSelected) {
            setIsModalVisible(false);
            try {
                const formData = new FormData();
                formData.append('image', fileInputRef.current.files[0]);
    
                const uploadResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/upload/${questionId}`, {
                    method: 'POST',
                    body: formData,
                });
                const data = await uploadResponse.json();
                if (!uploadResponse.ok) {
                    console.error('Error:', data);
                } else {
                    onUpdateImageSrc (data.imgPath);
                    // console.log(data);
                    // console.log(data.imgPath);
                }
                setFileSelected(false);
                resetInputFile();
            } catch (error) {
                console.error('Error deleting image:', error);
            }

        } else {
            setFileSelected(false);
            resetInputFile();
            notification.error({
                message: 'No file selected',
                description: 'Please select a file to upload.'
            });
            return;
        }
    };


    const handleCancelImg = () => {
        setIsModalVisible(false);
    };

    const resetInputFile = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
            if (validTypes.includes(file.type)) {
                // console.log('File ready for upload:', file.name);
                setFileSelected(true); 
            } else {        
                alert('Please select an image file!');
                setFileSelected(false); 
                resetInputFile();
            }
        } else {
            setFileSelected(false); 
        }
    };

    return (
        <div>
            <FileImageOutlined className='addImage' onClick={showModal} />
            <Modal 
                title="Upload Image" 
                open={isModalVisible} 
                onOk={handleAddImge} 
                onCancel={handleCancelImg}
                footer={[
                    <Button key="back" onClick={handleCancelImg}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleAddImge} disabled={!fileSelected}> 
                        Add
                    </Button>,
                ]}
            >
                <input ref={fileInputRef} type="file" onChange={handleImageUpload} accept="image/*" />
            </Modal>
        </div>
    );
});

export default AddImage;
