import React from 'react';
import { Card, Form, Input, Button, notification } from 'antd';
import { Link, useNavigate  } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import './index.css'
import logo from '../../assets/logo.png';

const Signup = () => {
    const navigate = useNavigate();
    const onSignup = (values) => {
        console.log('Received values of form: ', values);
        fetch(`${process.env.REACT_APP_API_URL}/api/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: values.username,
                password: values.password,
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                // Handle success scenario
                // alert("Registration successful!");
                // Redirect or further actions
                notification.success({
                    message: 'Register Successfully',
                    description: 'You have successfully registerred.',
                    duration: 0.8, // duration in seconds
                    placement: 'top', 
                });
                navigate('/login');
            } else {
                // Handle failure scenario
                // alert("Registration failed: " + data.message);
                notification.error({
                    message: 'Registeration failed',
                    description: 'Username already exist.',
                    duration: 0.8, // duration in seconds
                    placement: 'top', 
                });
            }
        })
        .catch(error => {
            console.error('Error during registration:', error);
        });
    };
    
    return (
        <div className="signup">
            <Card className="signup-container" title="Create Account">
                <div style={{ position: 'relative' }}>
                    <Link to="/" style={{ position: 'absolute', right: -18, top: -80, fontSize: 24 }}>
                        <CloseCircleOutlined /> {/* This is the close icon */}
                    </Link>
                </div>
                <div style={{ textAlign: 'center', padding: '1px' }}>
                    <Link to="/" style={{ display: 'inline-block', padding: '2px' }}>
                        <img className='login-logo' src={logo} alt="CANNOT LOAD LOGO" />
                    </Link>
                </div>
                <Form
                    onFinish={onSignup}
                    validateTrigger="onBlur"
                    layout="vertical"
                >
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input placeholder="Enter your username" />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password placeholder="Enter your password" />
                    </Form.Item>

                    <Form.Item
                        label="Confirm Password"
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            { required: true, message: 'Please confirm your password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="Confirm your password" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Register
                        </Button>
                    </Form.Item>
                </Form>
                <Link to="/login" className="login-form" style={{ float: 'left' }}>
                    ALERADY HAVE AN ACCOUNT? LOGIN
                </Link>
            </Card>
        </div>
    );
};

export default Signup;
