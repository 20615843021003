import React, { useState, useEffect } from 'react';
import './index.css';
import { FileOutlined, PlusOutlined } from '@ant-design/icons';
import { Layout, Menu, Input, Form, Row, Col, theme, Modal, Button, Select, Pagination, message } from 'antd';
import WebHeader from '../../components/header';
import QBankMC from '../../components/qBankMC';
import QBankMatching from '../../components/qBankMatching';
const { Header, Content, Footer, Sider } = Layout;
const nav = ['Multiple Choice', 'True / False', 'Matching', 'Ranking'];
const items = [FileOutlined, FileOutlined, FileOutlined, FileOutlined].map(
    (icon, index) => ({
        key: String(index + 1),
        icon: React.createElement(icon, { style: { color: 'rgba(0, 0, 0, 0.65)' } }),
        // label: `nav ${index + 1}`,
        label: nav[index],
    }),
);
const { Option } = Select;
const QuestionBank = () => {
    const { token: { colorBgContainer, borderRadiusLG }, } = theme.useToken();
    const [selectedItem, setSelectedItem] = useState(nav[0]);
    const [mcCheckedState, setMcCheckedState] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [quizzes, setQuizzes] = useState([]);
    const [selectedQuiz, setSelectedQuiz] = useState(null);

    const [isMCModalVisible, setIsMCModalVisible] = useState(false);
    const [questionText, setQuestionText] = useState('');
    const [options, setOptions] = useState({ A: '', B: '', C: '', D: '', E: '' });
    const [feedbacks, setFeedbacks] = useState({ Feedback_A: '', Feedback_B: '', Feedback_C: '', Feedback_D: '', Feedback_E: '' });
    const [topic, setTopic] = useState('');
    const [correctAnswer, setCorrectAnswer] = useState('');

    const [isMatchingModalVisible, setIsMatchingModalVisible] = useState(false);
    const [matchingQuestion, setMatchingQuestion] = useState({
        questionText: '',
        pairs: Array(5).fill({ option: '', match: '', answer: '' }),
        topic: '',
    });


    useEffect(() => {
        document.title = "Question Bank";
    }, []);

    useEffect(() => {
        fetchQuizzes();
        // console.log(quizzes);
    }, []);

    const fetchQuizzes = async () => {
        const userid = localStorage.getItem('userid');
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/quizs/${userid}`)
            const data = await response.json();
            // console.log(data);
            if (response.ok) {
                setQuizzes(data);
            } else {
                console.error("Failed to fetch quizzes");
                setQuizzes([]);
            }
        } catch (error) {
            console.error("Error fetching quizzes:", error);
            setQuizzes([]);
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedQuiz(null);
    };
    
    const onMenuClick = (e) => {
        const clickedItem = nav[e.key - 1];
        setSelectedItem(clickedItem);
    };

    // mc question checked state
    const onMcCheckedQuestion = (qid) => {
        setMcCheckedState(prevState => ({
            ...prevState,
            [qid]: !prevState[qid]
        }));
        // console.log(mcCheckedState); // {1: true, 2: false} 
    };

    // Function to handle adding questions to the quiz
    const addToQuiz = () => {
        if (!selectedQuiz) {
            alert('Please select a quiz!');
            return;
        }
        // console.log(selectedQuiz);
        // console.log(mcCheckedState);
        const addQuestion = Object.keys(mcCheckedState).filter(key => mcCheckedState[key]);
        // console.log(addQuestion);
        if (addQuestion.length < 1) {
            setIsModalVisible(false);
            setSelectedQuiz(null);
            setTimeout(() => {
                alert('Please select questions');
            }, 100);
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/api/qbankmc/add/${selectedQuiz}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(addQuestion)
        })
        // .then(response => response.json())
        // .then(data => console.log("Questions added to quiz: ", data))
        // .catch(error => console.error("Error adding questions to quiz: ", error));
        
        setIsModalVisible(false);
        setMcCheckedState({});
        setSelectedQuiz(null);
        setTimeout(() => {
            alert('Quesstions added sueccessfully!');
        }, 100);
        
    };

    const showMCModal = () => {
        setQuestionText('');
        setOptions({ A: '', B: '', C: '', D: '', E: '' });
        setFeedbacks({ Feedback_A: '', Feedback_B: '', Feedback_C: '', Feedback_D: '', Feedback_E: '' });
        setTopic('');
        setCorrectAnswer('');
        setIsMCModalVisible(true);
    };

    const handleMCCancel = () => {
        setIsMCModalVisible(false);
    };

    const handleQuestionChange = (e) => {
        setQuestionText(e.target.value);
    };

    const handleOptionChange = (key, event) => {
        setOptions(prevOptions => ({
            ...prevOptions,
            [key]: event.target.value 
        }));
    };    

    const handleFeedbackChange = (key, event) => {
        setFeedbacks(prevFeedbacks => ({
            ...prevFeedbacks,
            [key]: event.target.value 
        }));
    };

    const showMatchingModal = () => {
        setMatchingQuestion({
            questionText: '',
            pairs: Array(5).fill({ option: '', match: '', answer: '' }),
            topic: '',
        });
        setIsMatchingModalVisible(true);
    };
    
    const handleMatchingCancel = () => {
        setIsMatchingModalVisible(false);
    };

    const handleMatchingPairChange = (index, side, value) => {
        const updatedPairs = matchingQuestion.pairs.map((pair, idx) => {
            if (idx === index) {
                return { ...pair, [side]: value };
            }
            return pair;
        });
        setMatchingQuestion({ ...matchingQuestion, pairs: updatedPairs });
    };    

    const submitMCQuestion = async () => {
        // console.log("Submitting Question: ", questionText, options, correctAnswer, topic, feedbacks);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/qbankmc/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    questionText,
                    options,
                    feedbacks,
                    correctAnswer,
                    topic
                })
            });
            const data = await response.json();
            if (response.ok) {
                console.log("Question saved successfully:", data);
                setIsMCModalVisible(false); // Close modal after successful submission
            } else {
                throw new Error(data.message || "An error occurred while saving the question.");
            }
        } catch (error) {
            console.error("Error saving the question:", error);
            alert(error.message);
        }
    };    

    const submitMatchingQuestion = async () => {
        const { questionText, pairs, topic } = matchingQuestion;
    
        // Construct the data structure you will send to the backend
        const payload = {
            questionText,
            options: pairs.map(pair => pair.option),
            matches: pairs.map(pair => pair.match),
            answers: pairs.map(pair => pair.answer),
            topic
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/qbankmatching/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            if (response.ok) {
                console.log("Matching question saved successfully:", data);
                setIsMatchingModalVisible(false); // Close modal on success
            } else {
                throw new Error(data.message || "Failed to save the matching question.");
            }
        } catch (error) {
            console.error("Error submitting the matching question:", error);
            alert("Failed to submit matching question: " + error.message);
        }
    };
    

    return (
        <div>
            <WebHeader />

            <Modal
                title="Select a Quiz"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={addToQuiz}>
                        Add to Quiz
                    </Button>,
                ]}
            >
                <p>Select the quiz you want to add questions to:</p>
                <Select
                    value={selectedQuiz} 
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select a quiz"
                    optionFilterProp="children"
                    onChange={value => setSelectedQuiz(value)}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {quizzes && quizzes.length > 0 ? (
                        quizzes.map((quiz, index) => {
                            // console.log(quiz); 
                            return <Option key={index} value={quiz.LID}>{quiz.LTitle}</Option>;
                        })                        
                    ) : <Option disabled>No quizzes available</Option>}
                </Select>


            </Modal>
            <div>
            <Button type="primary" icon={<PlusOutlined />} style={{marginLeft: '40px'}} onClick={showMCModal}>
                Add MC Question
            </Button>

            <Button type="primary" icon={<PlusOutlined />} style={{marginLeft: '40px'}} onClick={showMatchingModal}>
                Add Matching Question
            </Button>

            </div>
            <div className='questionbank-body'>
                <Layout className='question-bank-layout'>
                    <Sider width={265} style={{ background: '#fff', position: 'fixed', height: '100vh' }} >
                        {/* <div /> */}
                        <Menu theme="light" mode="inline" className='sider-menu' style={{ fontSize: 20, marginTop: 30 }} defaultSelectedKeys={['1']} items={items} onClick={onMenuClick} />
                    </Sider>
                    <Layout style={{marginLeft: 265}}>
                        <Header style={{ padding: 0, marginBottom: 10, position: 'fixed', background: colorBgContainer, width: 1035, zIndex: 1000 }}>
                            <div className='header-add-to-quiz'>
                                {/* <span onClick={addToQuiz}>Add to Quiz</span> */}
                                <span onClick={showModal}>Add to Quiz</span>
                            </div>
                        </Header>
                        <Content style={{ margin: '10px', marginTop: '70px', padding: '8px 3px',}} >
                            <div
                                style={{
                                padding: 12,
                                minHeight: 360,
                                width: '100%',
                                fontSize: 18,
                                background: colorBgContainer,
                                borderRadius: borderRadiusLG,
                                }}
                            >
                                {
                                    (() => {
                                        switch(selectedItem) {
                                            case 'Multiple Choice':
                                                return <QBankMC mcCheckedState={mcCheckedState} onMcCheckedQuestion={onMcCheckedQuestion}/>;
                                            case 'True / False':
                                                return <div> True / False Question Bank is Coming </div>; 
                                            case 'Matching':
                                                return <QBankMatching />; 
                                            case 'Ranking':
                                                return <div>Ranking Question Bank is Coming </div>; 
                                            default:
                                                return;
                                        }
                                    })()
                                }
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center', width: 1000, paddingTop: 0, paddingBottom: 16}} >
                            Tangibook ©{new Date().getFullYear()} Created by ixlab
                        </Footer>
                    </Layout>
                </Layout>
            </div>

            <Modal
                title="Add New Multiple Choice Question"
                open={isMCModalVisible}
                onOk={submitMCQuestion}
                onCancel={handleMCCancel}
                footer={[
                    <Button key="back" onClick={handleMCCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={submitMCQuestion}>
                        Add Question
                    </Button>,
                ]}
            >
                <Form layout="vertical">
                    <Form.Item label="Question">
                        <Input value={questionText} onChange={handleQuestionChange} />
                    </Form.Item>
                    {Object.keys(options).map((key) => (
                        <Form.Item key={key} label="">
                            <Row gutter={8}>
                                <Col span={6}>
                                    <label>{`Option ${key}`}:</label>
                                </Col>
                                <Col span={18}>
                                    <Input value={options[key]} onChange={(e) => handleOptionChange(key, e)} />
                                </Col>
                            </Row>
                        </Form.Item>
                    ))}
                    {Object.keys(feedbacks).map((key) => (
                        <Form.Item key={key} label="">
                            <Row gutter={8} style={{marginTop: '0px'}}>
                                <Col span={6}>
                                    <label>{`${key}`}:</label>
                                </Col>
                                <Col span={18}>
                                    <Input value={feedbacks[key]} onChange={(e) => handleFeedbackChange(key, e)} />
                                </Col>
                            </Row>
                        </Form.Item>
                    ))}

                    <Form.Item label="Correct Answer">
                        <Select value={correctAnswer} onChange={(value) => setCorrectAnswer(value)}>
                            <Option value="A">A</Option>
                            <Option value="B">B</Option>
                            <Option value="C">C</Option>
                            <Option value="D">D</Option>
                            <Option value="E">E</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Topic">
                        <Input value={topic} onChange={(e) => setTopic(e.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
    title="Add New Matching Question"
    open={isMatchingModalVisible}
    onOk={() => submitMatchingQuestion()} // Implement this function based on your needs
    onCancel={handleMatchingCancel}
    footer={[
        <Button key="back" onClick={handleMatchingCancel}>
            Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => submitMatchingQuestion()}>
            Add Question
        </Button>,
    ]}
>
    <Form layout="vertical">
        <Form.Item label="Question">
            <Input 
                value={matchingQuestion.questionText} 
                onChange={(e) => setMatchingQuestion({ ...matchingQuestion, questionText: e.target.value })} 
            />
        </Form.Item>
        {matchingQuestion.pairs.map((pair, index) => (
            <Row key={index} gutter={16}>
                <Col span={10}>
                    <Form.Item label={`Option ${String.fromCharCode(65 + index)}`}>
                        <Input 
                            value={pair.option} 
                            onChange={(e) => handleMatchingPairChange(index, 'option', e.target.value)} 
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label={`Ans ${String.fromCharCode(65 + index)}`}>
                        <Input 
                            // placeholder="Correct match"
                            value={pair.answer} 
                            onChange={(e) => handleMatchingPairChange(index, 'answer', e.target.value)}
                        />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item label={`Match ${String.fromCharCode(97 + index)}`}>
                        <Input 
                            value={pair.match} 
                            onChange={(e) => handleMatchingPairChange(index, 'match', e.target.value)} 
                        />
                    </Form.Item>
                </Col>
            </Row>
        ))}
        <Form.Item label="Topic">
            <Input 
                value={matchingQuestion.topic} 
                onChange={(e) => setMatchingQuestion({ ...matchingQuestion, topic: e.target.value })}
            />
        </Form.Item>
    </Form>
</Modal>



        </div>
    );
};
export default QuestionBank;