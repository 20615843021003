import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef, useMemo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { Input, Button, List, Modal, Select, notification, Spin } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './index.css';
import LessonAddImage from '../lessonAddImage';
import ImageDisplay from '../imgDisplay'; 
import LessonMC from '../lessonMC';
import HandModule from '../handModule';
import LessonMCEdit from '../lessonMCEdit';
import LessonMatching from '../lessonMatching';
import LessonMatchingEdit from '../lessonMatchingEdit';
import LessonOP from '../lessonOP';
import LessonOPEdit from '../lessonOPEdit';
import QBankMCLesson from '../qBankMCLesson';
import QBankMatchingLesson from '../qBankMatchingLesson';
// import '../../../node_modules/react-quill/dist/quill.snow.css'; 
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import Joyride from 'react-joyride';
import LensModule from '../lensModule';
import AiGenerateMC from '../aiGenerateMC';
import AiGenerateMatching from '../aiGenerateMatching';
// import samplepage from '../../assets/samplepage.png';

const { Option } = Select;

const ItemType = 'page';

const DraggablePage = ({ id, index, title, currentPage, movePage, handleDeletePage, handlePageChange, setDragEnded, saveContent, saveContent2  }) => {
    const [, drag, preview] = useDrag({
        type: ItemType,
        // item: { id, index },
        item: () => {
            // console.log("Drag operation started for page index:", index);
            saveContent();
            saveContent2();
            return { id, index };
        },
        
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            setDragEnded(true);
            // if (item && dropResult) {
            //     setDragEnded(true); 
            // }
        },
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover(item, monitor) {
            if (item.index !== index) {
                movePage(item.index, index);
                item.index = index;
            }
        },
    });

    // console.log("Current page: ", currentPage, "Index: ", index, "Comparison result: ", currentPage === index);

    return (
        <div ref={drop} style={{ cursor: 'pointer' }}> 
            <List.Item
                onClick={() => handlePageChange(index)} 
                style={{ padding: '10px', backgroundColor: currentPage === index ? '#cfefff' : '#fff', border: '1px solid #ddd' }}
                actions={[<DeleteOutlined onClick={(e) => { e.stopPropagation(); handleDeletePage(index, e); }} style={{ color: 'black', fontSize: '17px', cursor: 'pointer' }} />]}
            >
                <div style={{ display: 'flex', alignItems: 'center', width: '72%' }}>
                    <div ref={drag} style={{ marginRight: '10px', cursor: 'move' }} onClick={() => {
                        // console.log(currentPage);
                        saveContent();
                        saveContent2();
                    }}>⋮⋮</div>
                    <div style={{ flexGrow: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer', userSelect: 'none'}}>
                        {title}
                    </div>
                </div>
            </List.Item>
        </div>
    );
};

// function CustomTooltip({ content }) {
//     return (
//       <div dangerouslySetInnerHTML={{ __html: content }} />
//     );
//   }

const PageAndContent = forwardRef((props, ref) => {
    const [run, setRun] = useState(false);
    const [mcCheckedState, setMcCheckedState] = useState({});
    const [pages, setPages] = useState([{ pageId: '', title: "Page 1", content: '', content2: '', imgpath:'', questions: [], 
                                        lens1: '', lens2: '', lens3: '', lens4: ''
                                       }]);
    const [currentPage, setCurrentPage] = useState(0);
    const [content, setContent] = useState('');
    const [content2, setContent2] = useState('');
    const [questionData, setQuestionData] = useState([]);
    const [isInterModalVisible, setIsInterModalVisible] = useState(false);
    const [isAIMCModalVisible, setIsAIMCModalVisible] = useState(false);
    const [isAIMatchingModalVisible, setIsAIMatchingModalVisible] = useState(false);
    const [isGenModulelVisible, setIsGenModulelVisible] = useState(false);
    const [isLensModulelVisible, setIsLensModulelVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedType, setSelectedType] = useState('none');
    const [editedType, setEditedType] = useState('none');
    const [editingQuestionIndex, setEditingQuestionIndex] = useState(null);
    const lessonMCRef = useRef(null);
    const qBankMCRef = useRef(null);
    const AIMCRef = useRef(null);
    const AIMatchingRef = useRef(null);
    const qBankMatchingRef = useRef(null);
    const lessonOPRef = useRef(null);
    const lessonMatchingRef = useRef(null);
    const lessonMCEditRef = useRef(null);
    const lessonMatchingEditRef = useRef(null);
    const lessonOPEditRef = useRef(null);
    const handModuleRef = useRef(null);
    const lensModuleRef = useRef(null);
    const { LessonID, importedLessonID, setImportedLessonID, previewButtonRef } = props;  // Receive the LessonID from parent component
    const lessonAddImageRef = useRef(null);
    const [isReady, setIsReady] = useState(false);
    const [MCAdd, setMCAdd] = useState(true);
    const [HandAdd, setHandAdd] = useState(true);
    const [LensAdd, setLensAdd] = useState(true);
    const [selectedModule, setSelectedModule] = useState('none');
    const [MatchingAdd, setMatchingAdd] = useState(true);
    const [OPAdd, setOPAdd] = useState(true);
    const [interaNum, setInteraNum] = useState(0);
    const [isContent2, setIsContent2] = useState(false);
    const [dragEnded, setDragEnded] = useState(false);
    const [isInnerModalVisible, setIsInnerModalVisible] = useState(false);
    const [matchingModalVisible, setMatchingModalVisible] = useState(false);
    const [lessonTitle, setLessonTitle] = useState('');
    const [isEditTitleVisible, setIsEditTitleVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [isTyping, setIsTyping] = useState(false);
    const [fetchingGesture, setFetchingGesture] = useState(false);

    useEffect(() => {
        if (isTyping) {
            const timer = setTimeout(() => {
                setIsTyping(false);
            }, 2500); 

            return () => clearTimeout(timer);
        }
    }, [isTyping]);

    const steps = [
        {
            target: '.invisible-target',
            content: "Welcome to the tour steps. Let's see the most important parts for editing the lesson pages.",
            placement: 'bottom',
            disableBeacon: true,
            hideBackButton: true,
        },
        {
          target: '.pages-list-container',
          content: "These are the page titles. Click on them for navigating and editing. You can also add new pages here.",
          placement: 'bottom',
          disableBeacon: true,
          hideBackButton: true,
        },
        {
            target: '.instructional-content',
            content: 'Here you can add static content for your lesson. For example, explanations or definitions. This will appear in the top left block of this page. All the chages will be saved automically.',
            placement: 'bottom',
            disableBeacon: true,
            hideBackButton: true,
        },
        {
            target: '.add-page-content-img',
            content: 'Here you can add an optional image (png or jpg), or a new block of text (not both). This will appear on the top right block of this page.',
            placement: 'bottom',
            disableBeacon: true,
            hideBackButton: true,
        },
        {
            target: '.trythis-component',
            content: "Here you can add or modify the optional questions. Questions are elements that requests students' interaction (eg. touching or placing objects). You will customize the messages for your students.",
            placement: 'bottom',
            disableBeacon: true,
            hideBackButton: true,
        },
        //   {
        //     target: previewButtonRef.current,
        //     content: 'Preview the lesson.',
        //     placement: 'bottom',
        //     disableBeacon: true,
        //     hideBackButton: true,
        // },
    ];

    useEffect(() => {
        const userid = localStorage.getItem('userid');
        const joyrideTaken = localStorage.getItem(`joyrideTaken_page${userid}`);
        // console.log(joyrideTaken);
        // If not taken, run Joyride and set the flag
        // if (!joyrideTaken) {
        if (joyrideTaken === 'false' || !joyrideTaken) {
            disableScroll();
            setRun(true);
            localStorage.setItem(`joyrideTaken_page${userid}`, 'true');
        }
        // enableScroll();

    }, []);

    const disableScroll = () => {
        document.body.style.overflow = 'hidden';
    };

    
    const enableScroll = () => {
        document.body.style.overflow = 'auto';
    };

    const handleJoyrideCallback = (data) => {
        const { status } = data;
        const finishedStatuses = ['finished', 'skipped'];

        if (finishedStatuses.includes(status)) {
            enableScroll();  
            setRun(false);
        }
    };

    useEffect(() => {
        // setIsReady(true);
        // if (importedLessonID) {
        //     setIsReady(false);
        //     fetchPagesForLesson(importedLessonID);
        //     setImportedLessonID(null);
        // } else 
        if (LessonID) {
            setIsReady(false);
            fetchPagesForLesson(LessonID);
            fetchLessonInfo(LessonID);
        }
        // setIsReady(true);
        // const registeredSize = Quill.import('attributors/style/size');
        // console.log(registeredSize); // This should output the Size class/configuration if registered successfully

    }, [LessonID]);

    const fetchPagesForLesson = (lessonId) => {
        const url = `${process.env.REACT_APP_API_URL}/lesson/getpagesmodules/${lessonId}`;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                // console.log('data', data);
                if (data && data.length > 0) {  // Check if data is not empty
                    const formattedPages = data.map(page => formatPageData(page));
                    setPages(formattedPages);
                    // console.log('formattedpages: ', formattedPages)
                    setIsReady(true);
                } else {

                    setPages([{ pageId: '', title: "Page 1", content: '', content2: '', imgpath:'', questions: [], lens1: '', lens2: '', lens3: '', lens4: '' }]);
                    setCurrentPage(0);
                    setContent('');
                    setContent2('');
                    setIsReady(true);
                }
            })
            .catch(error => {
                console.error('Failed to fetch pages:', error);
                // setPages([{ title: "Page 1", content: '',  content2: '', imgpath:'', questions: [] }]);
                setCurrentPage(0);
                setContent('');
                setContent2('');
            });
    };

    const fetchLessonInfo = (lessonId) => {
        if (lessonId) {
            fetch(`${process.env.REACT_APP_API_URL}/lessonTD/${lessonId}`)
                .then(response => response.json())
                .then(data => {
                    setLessonTitle(data.lessonTitle);
                })
                .catch(error => console.error('Failed to fetch lesson data:', error));
        }
    }

    const formatPageData = (page) => {
        return {
            pageId: page.pageId || "",
            title: page.title || "",
            content: page.content || "",
            content2: page.content2 || "",
            imgpath: page.imgPath || "",
            lens1: page.lens1 || "",
            lens2: page.lens2 || "",
            lens3: page.lens3 || "",
            lens4: page.lens4 || "",
            questions: page.questions.map(question => formatQuestionData(question))
        };
    };

    const formatQuestionData = (question) => {
        const baseQuestion = {
            questionid : question.QuestionID,
            text: question.QuestionText,
            type: question.QuestionType,
            optionNum: question.details ? question.details.OptionNum : 2,
        };
        if (question.QuestionType === 'multiple_choice') {
            return {
                ...baseQuestion,
                choices: question.details ? [
                    question.details.A || null,
                    question.details.B || null,
                    question.details.C || null,
                    question.details.D || null,
                    question.details.E || null
                ].filter(choice => choice !== null) : [],
                correctAnswer: question.details ? question.details.CorrectAnswer : null,
                messages: [
                    question.details.ComA || null,
                    question.details.ComB || null,
                    question.details.ComC || null,
                    question.details.ComD || null,
                    question.details.ComE || null
                ].filter(msg => msg !== null)
            };
        } else if (question.QuestionType === 'matching') {
            return {
                ...baseQuestion,
                matches: [
                    question.details.A || null,
                    question.details.B || null,
                    question.details.C || null,
                    question.details.D || null,
                    question.details.E || null,
                    question.details.MatchA || null,
                    question.details.MatchB || null,
                    question.details.MatchC || null,
                    question.details.MatchD || null,
                    question.details.MatchE || null,
                    question.details.ComA || null,
                    question.details.ComB || null,
                    question.details.ComC || null,
                    question.details.ComD || null,
                    question.details.ComE || null,
                    question.details.Defaultcom || null
                ],
                matchCon: [
                    question.details.AnsA || null,
                    question.details.AnsB || null,
                    question.details.AnsC || null,
                    question.details.AnsD || null,
                    question.details.AnsE || null
                ],
            };
        } else if (question.QuestionType === 'object_placement') {
            return {
                ...baseQuestion,
                tagmsgs: [
                    question.details.TagID1 || null,
                    question.details.TagID2 || null,
                    question.details.TagID3 || null,
                    question.details.TagID4 || null,
                    question.details.TagID5 || null,
                    question.details.TagID6 || null,
                    question.details.TagID7 || null,
                    question.details.TagID8 || null,
                    question.details.TagID9 || null,
                    question.details.TagID10 || null,
                    question.details.Msg1 || null,
                    question.details.Msg2 || null,
                    question.details.Msg3 || null,
                    question.details.Msg4 || null,
                    question.details.Msg5 || null,
                    question.details.Msg6 || null,
                    question.details.Msg7 || null,
                    question.details.Msg8 || null,
                    question.details.Msg9 || null,
                    question.details.Msg10 || null
                ],
            };
        } else {
            return baseQuestion;
        }
    };

    function isEffectivelyEmpty(htmlContent) {
        // Remove all HTML tags and check if the remaining content is just whitespace or empty
        return htmlContent.replace(/<[^>]*>/g, '').trim() === '';
    }

    useEffect(() => {
        if (pages[currentPage] && pages[currentPage].content) {
            setContent(pages[currentPage].content);
        }
        // console.log('content changes');
        
    }, [pages[currentPage].content]);

    useEffect(() => {
        setContent('');
        if (pages[currentPage] && pages[currentPage].content) {
            setContent(pages[currentPage].content);
        }
        setContent2('');
        setIsContent2(false);
        setSelectedType('none');
        if (pages[currentPage] && pages[currentPage].content2) {
            setContent2(pages[currentPage].content2);
            if (!isEffectivelyEmpty(pages[currentPage].content2 || !pages[currentPage].content2)){
                // console.log('content2 is :', pages[currentPage].content2);
                setIsContent2(true);
                
            }
        }
        // if (pages[currentPage] && pages[currentPage].content2) {
        //     setContent2(pages[currentPage].content2);
        //     if (!isEffectivelyEmpty(pages[currentPage].content2 || !pages[currentPage].content2)){
        //         // console.log('content2 is :', pages[currentPage].content2);
        //         setIsContent2(true);
                
        //     } else {
        //         setIsContent2(false);
        //     }
        // }
    }, [currentPage]);

    useEffect(() => {
        // setContent(pages[currentPage].content);
        // setContent2('');
        // setIsContent2(false);
        // setSelectedType('none');
        if (pages[currentPage] && pages[currentPage].content2) {
            setContent2(pages[currentPage].content2);
            if (!isEffectivelyEmpty(pages[currentPage].content2 || !pages[currentPage].content2)){
                // console.log('content2 is :', pages[currentPage].content2);
                setIsContent2(true);
                
            }
        }
    }, [ pages[currentPage].content2]);

    // useEffect(() => {
    //     setContent(pages[currentPage].content);
    // }, [currentPage]);


    // const [contOneInput, setContOneInput] = useState(true);
    // const quillRef = useRef(null);
    // const quillRef2 = useRef(null);
    // const maxEditorHeight = 240; // Max height for ReactQuill component
    // const maxEditorHeight2 = 240; 
    // useEffect(() => {
    //     if (quillRef.current) {
    //         const editor = quillRef.current.getEditor();
    //         let previousContent = editor.getContents();
    
    //         editor.on('text-change', () => {
    //             const currentContent = editor.getContents();
    //             const editorRoot = editor.root;
    //             if (editorRoot.scrollHeight > maxEditorHeight) {
    //                 // Reset to previous content if the height exceeds the maximum
    //                 editor.setContents(previousContent);
    //                 // notification.error({
    //                 //     message: 'Error',
    //                 //     description: 'Maximum content height reached. You cannot add more content.',
    //                 //     placement: 'top',
    //                 //     duration: 3,
    //                 // });
    //                 alert('Maximum content height reached. You cannot add more content.');
    //             } else {
    //                 // Update previous content to current if within limits
    //                 previousContent = currentContent;
    //             }
    //         });
    //     }

    //     if (quillRef2.current) {
    //         const editor2 = quillRef2.current.getEditor();
    //         let previousContent = editor2.getContents();
    
    //         editor2.on('text-change', () => {
    //             const currentContent = editor2.getContents();
    //             const editorRoot = editor2.root;
    //             if (editorRoot.scrollHeight > maxEditorHeight2) {
    //                 // Reset to previous content if the height exceeds the maximum
    //                 editor2.setContents(previousContent);
    //                 alert('Maximum content height reached. You cannot add more content.');
    //             } else {
    //                 // Update previous content to current if within limits
    //                 previousContent = currentContent;
    //             }
    //         });
    //     }
    // }, [currentPage]);


    useImperativeHandle(ref, () => ({
        getDragEnded,
        handleAddContent
    }));

    const updateInteractionTypesState = () => {
        const currentQuestions = pages[currentPage]?.questions || [];
        const typesCount = {
            multiple_choice: 0,
            matching: 0,
            object_placement: 0
        };
        // Count the occurrence of each question type on the current page
        currentQuestions.forEach(question => {
            if (typesCount.hasOwnProperty(question.type)) {
                typesCount[question.type] += 1;
            }
        });
        let interCount = typesCount.multiple_choice + typesCount.matching + typesCount.object_placement;
        setInteraNum(interCount);
        // Set the states based on whether the count of each type is less than the limit of 1
        setMCAdd(typesCount.multiple_choice < 1);
        setMatchingAdd(typesCount.matching < 1);
        setOPAdd(typesCount.object_placement < 1);
    };
    useEffect(() => {
        updateInteractionTypesState();
    }, [pages[currentPage]?.questions]);
    
    const movePage = (fromIndex, toIndex) => {
        const updatedPages = [...pages];
        // updatedPages = [];
        // updatedPages = [...pages];
        const [movedPage] = updatedPages.splice(fromIndex, 1);
        updatedPages.splice(toIndex, 0, movedPage);
        setPages(updatedPages);
        // console.log('updated', updatedPages);
    };

    const updatePageOrder = async () => {
        const pageIds = pages.map(page => page.pageId);
        // console.log('updated', pageIds);
        // const url = `${process.env.REACT_APP_API_URL}/lesson/updatepageorder/${LessonID}`;
        const url = `${process.env.REACT_APP_API_URL}/lesson/updatepageorder`;
        try {
            const respone = await fetch(url, {
                                    method: 'POST', // Or PUT, depending on your backend setup
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({ pageIds })
                                });
            const data = await respone.json();
            if (data.newPageIds) {
                // console.log('Updated page IDs:', data.newPageIds);
                const newPageIds = data.newPageIds;
                const updatedPages = pages.map((page, index) => ({
                    ...page,
                    pageId: newPageIds[index]
                  }));
                  setPages(updatedPages); 
            } else {
                console.error('Failed to update page order:', data.message);
            }
        } catch (error) {
            console.error('Error updating page order:', error);
            throw error;
        }
    }

    useEffect(() => {
        // console.log('dragened', dragEnded);
        const performUpdate = async () => {
            if (dragEnded) {
                await updatePageOrder();
                setDragEnded(false);
                // console.log('dragened', dragEnded);
            }
        };
        performUpdate();
    }, [dragEnded, updatePageOrder]);

    const getDragEnded = () => {
        return dragEnded;
        // return true;
    }

    const handleAddPage = async () => {
        setLoading(true);
        try {
            await saveContent();   // Assuming this function is async and handles its own errors
            await saveContent2();  // Assuming this function is async and handles its own errors
    
            const newPageNumber = pages.length + 1;
            const newPageTitle = `Page ${newPageNumber}`;
            const response = await fetch(`${process.env.REACT_APP_API_URL}/lesson/addpage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ title: newPageTitle, lessonId: LessonID })
            });
    
            if (!response.ok) throw new Error('Network response was not ok');
    
            const data = await response.json();
            setPages([...pages, { pageId: data.pageId, title: `Page ${newPageNumber}`, content: '', content2: '', imgpath: '', questions: [], 
                        lens1: '', lens2: '', lens3: '', lens4: ''}]);
            setCurrentPage(pages.length);  // Be cautious, might want to use pages.length - 1 if you need zero-index
    
        } catch (error) {
            notification.error({
                message: 'Error Adding Page',
                description: error.message || 'Failed to add the page.'
            });
        } finally {
            setLoading(false);  // This ensures loading is only set to false after all async operations are complete
        }
    };
    
    
    const handleDeletePage = (index, event) => {
        // console.log(pages.length);
        event.stopPropagation(); // Prevent event from triggering a page change
        if (pages.length === 1) {
            alert('At least one page for the lesson, please add a new page before deleting it.');
            return;
        }
        // event.stopPropagation(); // Prevent event from triggering a page change
        Modal.confirm({
            title: 'Delete This Page?',
            content: 'Deleting this page will remove it permanently.',
            okText: 'Yes, delete it',
            okType: 'danger',
            cancelText: 'No, keep it',
            onOk() {
                // console.log(pages[currentPage].pageId);
                // setLoading(true);
                setLoading(true);
                const pageid = pages[currentPage].pageId;

                // Send a delete request to the backend
                fetch(`${process.env.REACT_APP_API_URL}/lesson/deletepage`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ pageId: pageid })
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    // console.log('ddd', data);
                    notification.success({
                        message: 'Page Deleted',
                        description: 'The page has been successfully deleted.'
                    });
                    setTimeout(() => {
                        setLoading(false);
                    }, 250);
                })
                .catch(error => {
                    notification.error({
                        message: 'Error Deleting Page',
                        description: error.message || 'Failed to delete the page.'
                    });
                    alert('Failed to delete the page, please try again later.');
                    setLoading(false);
                    return;
                });
                const newPages = pages.filter((_, idx) => idx !== index);
                setPages(newPages);

                if (index === currentPage) {
                    const newCurrentPage = index > 0 ? index - 1 : 0;
                    setCurrentPage(newCurrentPage);
                } else if (index < currentPage) {
                    setCurrentPage(currentPage - 1);
                }
            },
            onCancel() {
                // console.log('Cancel delete');
            }
        });
    };
    
    const handlePageChange = (index) => {
        saveContent();
        saveContent2();
        const updatedPages = pages.map((page, idx) => 
            idx === currentPage ? { ...page, content: content, content2: content2} : page
        );
        setPages(updatedPages);
        setCurrentPage(index);
    };

    const handleTitleChange = (e) => {
        setIsTyping(true);
        const newPages = pages.map((page, idx) => 
            idx === currentPage ? { ...page, title: e.target.value } : page
        );
        setPages(newPages);
    };
    
    const handleAddContent = async () => {
        await saveContent();
        await saveContent2();
        return true;
    };


    const saveButton = () => {
        handleAddContent();
        // console.log("Saving content:", pages);
    };

    const testButton = () => {
        console.log("Saving content:", pages);
    };

    const showInterModal = () => {
        setIsInterModalVisible(true);
        setSelectedType('none');
    };

    // inner modal - qbankmc
    const showInnerModal = () => {
        setMcCheckedState({});
        setIsInnerModalVisible(true);
    };
    
    const handleInnerOk = () => {
        if (qBankMCRef.current && qBankMCRef.current.getSelectedQuestionDetails) {
            // console.log('testest');
            const q_ = qBankMCRef.current.getSelectedQuestionDetails();
            
            // setIsInnerModalVisible(false);
            // console.log(q_);
            if (q_.length === 0) {
                alert('Please select a questin before importing it.');
                return;
            }
            console.log('q_: ', q_);
            setSelectedQuestion(q_);
        }

        setIsInnerModalVisible(false);
    };

    // const updateQ = (qq) => {
    //     // console.log(qq);
    //         setSelectedQuestion(qq);
    //     console.log(selectedQuestion);
    //     handleInnerCancel();
    // }
    
    const handleInnerCancel = () => {
        setIsInnerModalVisible(false);
    };

    // innner modal - qbankmatching
    const showMatchingModal = () => {
        setMcCheckedState({});
        setMatchingModalVisible(true);
    }

    const handleMatchingOk = () => {
        if (qBankMatchingRef.current && qBankMatchingRef.current.getSelectedQuestionDetails) {
            
            const q_ = qBankMatchingRef.current.getSelectedQuestionDetails();
            if (q_.length === 0) {
                alert('Please select a questin before importing it.');
                return;
            }
            setSelectedQuestion(q_);
            // console.log(q_);
            // setIsInnerModalVisible(false);
            // console.log(q_);
        }
        setMatchingModalVisible(false);
    };
    
    const handleMatchingCancel = () => {
        setMatchingModalVisible(false);
    };


    const handleAddQuestionToPage = (questionData) => {
        const updatedPages = pages.map((page, index) => {
            if (index === currentPage) {
                return { ...page, questions: [...page.questions, questionData] };
            }
            return page;
        });
        setPages(updatedPages);
    };    

    const handleADDInterQuestion = () => {
        let addQ = false;
        if (lessonMCRef.current && lessonMCRef.current.submitQuestion) {
            addQ = lessonMCRef.current.submitQuestion();
            // setIsLoading(true);
        } else if (lessonMatchingRef.current && lessonMatchingRef.current.submitQuestion) {
            addQ = lessonMatchingRef.current.submitQuestion();
            // setIsLoading(true);
        } else if (lessonOPRef.current && lessonOPRef.current.submitQuestion) {
            addQ = lessonOPRef.current.submitQuestion();
            // setIsLoading(true);
        } else {
            alert('Please select a valid type.');
            return;
        }   
        // if (addQ) {
        //     setIsInterModalVisible(false);
        //     setIsLoading(true);
        //     // setIsLoading(false);
        // }        
    }

    const handleCancelInter = () => {
        setIsInterModalVisible(false);
    }

    const handleAddInteraction = () => {
        showInterModal();
        saveContent();
        saveContent2();
    };

    const handleEditInterQuestion = () => {
        let updateQ = false;
        if (lessonMCEditRef.current && lessonMCEditRef.current.submitQuestion) {
            // setIsLoading(true);
            updateQ = lessonMCEditRef.current.submitQuestion();
        }
        if (lessonMatchingEditRef.current && lessonMatchingEditRef.current.submitQuestion) {
            // setIsLoading(true);
            updateQ = lessonMatchingEditRef.current.submitQuestion();
        }
        if (lessonOPEditRef.current && lessonOPEditRef.current.submitQuestion) {
            // setIsLoading(true);
            updateQ = lessonMatchingEditRef.current.submitQuestion();
        }
        // if (updateQ) {
        //     setIsEditModalVisible(false);
        // }
    };

    const handleCancelEdit = () => {
        if (lessonMCEditRef.current && lessonMCEditRef.current.submitQuestion) {
            lessonMCEditRef.current.cancelEdit();
        }
        if (lessonMatchingEditRef.current && lessonMatchingEditRef.current.submitQuestion) {
            lessonMatchingEditRef.current.cancelEdit();
        }
        if (lessonOPEditRef.current && lessonOPEditRef.current.submitQuestion) {
            lessonMatchingEditRef.current.cancelEdit();
        }
        setIsEditModalVisible(false);
    };

    const handleAddGenModule = () => {
        showGenModuleModal();
    }

    const showGenModuleModal = () => {
        // setIsInterModalVisible(true);
        // setSelectedModule('none');
        setIsGenModulelVisible(true);
        setFetchingGesture(true);
    };

    const handleGenModuOk = () => {
        if (handModuleRef.current && handModuleRef.current.getInputsGesture) {
            let tempGestureInputs = handModuleRef.current.getInputsGesture();
            // console.log(tempGestureInputs);
            // setIsLoading(true);
            if (tempGestureInputs) {
                Modal.confirm({
                    title: 'The message is not added!',
                    content: 'Do you want to continue without adding the message?',
                    okText: 'Yes, Continue',
                    cancelText: 'No, Return',
                    onOk() {
                        if (handModuleRef.current && handModuleRef.current.handleSubmit) {
                            handModuleRef.current.handleSubmit();
                            // setIsLoading(true);
                        }
                        setIsGenModulelVisible(false);
                       
                    },
                    onCancel() {
                        return;
                    }
                });
            } else {
                if (handModuleRef.current && handModuleRef.current.handleSubmit) {
                    handModuleRef.current.handleSubmit();
                    // setIsLoading(true);
                }
                setIsGenModulelVisible(false);
            }
        }
        // if (handModuleRef.current && handModuleRef.current.handleSubmit) {
        //     handModuleRef.current.handleSubmit();
        //     // setIsLoading(true);
        // }
        // setIsGenModulelVisible(false);
    }

    const handleGenModuCancel = () => {
        setIsGenModulelVisible(false);
    }

    const handleAddLensModule = () => {
        setIsLensModulelVisible(true);
    }

    const handleLensModuOk = () => {
        if (lensModuleRef.current && lensModuleRef.current.getInputsLens) {
            let tempGestureLens = lensModuleRef.current.getInputsLens();
            // console.log(tempGestureInputs);
            // setIsLoading(true);
            if (tempGestureLens) {
                Modal.confirm({
                    title: 'The message is not added!',
                    content: 'Do you want to continue without adding the message?',
                    okText: 'Yes, Continue',
                    cancelText: 'No, Return',
                    onOk() {
                        if (lensModuleRef.current && lensModuleRef.current.handleSubmit) {
                            lensModuleRef.current.handleSubmit();
                            // setIsLoading(true);
                        }
                        setIsLensModulelVisible(false);
                       
                    },
                    onCancel() {
                        return;
                    }
                });
            } else {
                if (lensModuleRef.current && lensModuleRef.current.handleSubmit) {
                    lensModuleRef.current.handleSubmit();
                    // setIsLoading(true);
                }
                setIsLensModulelVisible(false);
            }
        }

        // if (lensModuleRef.current && lensModuleRef.current.handleSubmit) {
        //     lensModuleRef.current.handleSubmit();
        //     // setIsLoading(true);
        // }
        // setIsLensModulelVisible(false);
    }

    const handleLensModuCancel = () => {
        if (lensModuleRef.current && lensModuleRef.current.setLens) {
            lensModuleRef.current.setLens(1);
            // setIsLoading(true);
        }
        setIsLensModulelVisible(false);
    }

    const updateLensData = (pageId, newLensData) => {
        const updatedPages = pages.map(page => {
            if (page.pageId === pageId) {
                return { ...page, ...newLensData };
            }
            return page;
        });
        setPages(updatedPages);
    };
    
    const updateHandData = (pageId, newHandData) => {
        const updatedPages = pages.map(page => {
            if (page.pageId === pageId) {
                return { ...page, ...newHandData };
            }
            return page;
        });
        setPages(updatedPages);
    };

    const handleUpdateQuestion = (updatedData) => {
        const updatedPages = pages.map((page, idx) => {
            if (idx === currentPage) {
                const updatedQuestions = page.questions.map((question, qIndex) =>
                    qIndex === editingQuestionIndex ? updatedData : question
                );
                return { ...page, questions: updatedQuestions };
            }
            return page;
        });
        setPages(updatedPages);
        setQuestionData([]);
    }

    const handleEditQuestion = (index) => {       
        const questionType = pages[currentPage].questions[index].type;
        setEditedType(questionType);
        setEditingQuestionIndex(index);
        setIsEditModalVisible(true);
    };

    const handleDeleteQuestion = (index) => {
        Modal.confirm({
            title: 'Delete This Question?',
            content: 'Deleting this question will remove it permanently.',
            okText: 'Yes, delete it',
            okType: 'danger',
            cancelText: 'No, keep it',
            onOk() {
                // console.log(index, pages[currentPage].pageId, pages[currentPage].questions[index].type);

                const deleteUrl = `${process.env.REACT_APP_API_URL}/lesson/deletequestion`;
                fetch(deleteUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        pageId: pages[currentPage].pageId,
                        questionId: pages[currentPage].questions[index].questionid,
                        questionType: pages[currentPage].questions[index].type,
                        index: index
                    })
                })
                .then(response => {
                    if (!response.ok) throw new Error('Network response was not ok');
                    return response.json();
                })
                .then(data => {
                    notification.success({
                        message: 'Question Deleted',
                        description: 'The question has been successfully deleted.'
                    });
                    // Remove the question from the local state
                    const updatedQuestions = pages[currentPage].questions.filter((_, idx) => idx !== index);
                    const updatedPages = pages.map((page, idx) => {
                        if (idx === currentPage) {
                            return { ...page, questions: updatedQuestions };
                        }
                        return page;
                    });
                    setPages(updatedPages);
                })
                .catch(error => {
                    notification.error({
                        message: 'Error Deleting Question',
                        description: error.message || 'Failed to delete the question.'
                    });
                });



                const updatedQuestions = pages[currentPage].questions.filter((_, idx) => idx !== index);
                const updatedPages = pages.map((page, idx) => {
                    if (idx === currentPage) {
                        return { ...page, questions: updatedQuestions };
                    }
                    return page;
                });
                setPages(updatedPages);
            },
            onCancel() {
                
            }
        });
        // updateInteractionTypesState();
    };    

    const handleQuestionTypeChange = (value) => {
        setSelectedQuestion(null);
        setSelectedType(value);
    }

    const handleGenModuleChange = (value) => {
        setSelectedModule(value);
    }

    const editImg = () => {
        if (lessonAddImageRef.current) {
            // lessonAddImageRef.current.showModal();
            Modal.confirm({
                title: 'Replace the current Image?',
                content: 'Reaplce the current image.',
                okText: 'Yes, replace it',
                okType: 'danger',
                cancelText: 'No, keep it',
                onOk() {
                    lessonAddImageRef.current.showModal();
                },
                onCancel() {
                    // console.log('Cancel add');
                }
            });
        }
    };

    const deleteImg = () => {
        // setPages(prevPages => prevPages.map((page, index) =>
        //     index === currentPage ? { ...page, imgpath: '' } : page
        // ));
        const pageId = pages[currentPage].pageId;
        fetch(`${process.env.REACT_APP_API_URL}/lesson/clearImage`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ pageId })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                notification.success({
                    message: 'Image Cleared',
                    description: 'The image has been successfully cleared.'
                });
                // Update local state to reflect the change
                setPages(prevPages => prevPages.map((page, index) =>
                    index === currentPage ? { ...page, imgpath: '' } : page
                ));
            } else {
                throw new Error(data.message);
            }
        })
        .catch(error => {
            notification.error({
                message: 'Error Clearing Image',
                description: error.message || 'Failed to clear the image.'
            });
        });
    };

    const updateImageSrc = (imgPath) => {
        setPages(prevPages => prevPages.map((page, idx) => 
            idx === currentPage ? { ...page, imgpath: imgPath } : page
        ));
    };

    const addInstContent = () => {
        if (pages[currentPage]?.imgpath) {
            alert('Please delete the Image before adding new Instructional Content');
            return;
        }
        if (!OPAdd) {
            alert('Please delete the Object Placement Question before adding new Instructional Content');
            return;
        } 
        if (isContent2) {
            alert('At most Two Instructional Contents');
            return;
        }
        setIsContent2(true);
    }
    const deleteInstContent = () => {
        Modal.confirm({
            title: 'Are you sure you want to delete this content?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                
                // setContent2('');
                const newContent2 = '';
                setContent2(newContent2);
                pages[currentPage].content2 = '';
                saveContent2();
                const updatedPages = pages.map((page, idx) => 
                    idx === currentPage ? { ...page, content2: newContent2 } : page
                );
                setPages(updatedPages);
                setIsContent2(false);
            },
            onCancel() {
                // console.log('Cancel delete');
            },
        });
    }

    const addImageLesson = () => {
        if (!OPAdd) {
            alert('Please delete the Object Placement Question before adding Image');
            return;
        }
        if (isContent2) {
            alert('Please delete the second Instructional Content before adding Image');
            return;
        }
        if (pages[currentPage]?.imgpath) {
            Modal.confirm({
                title: 'Replace the current Image?',
                content: 'Add new iamge will reaplce the current image.',
                okText: 'Yes, replace it',
                okType: 'danger',
                cancelText: 'No, keep it',
                onOk() {
                    lessonAddImageRef.current.showModal();
                },
                onCancel() {
                    // console.log('Cancel add');
                }
            });
        } else {
            lessonAddImageRef.current.showModal();
        }       
    }

    if (!isReady) {
        return <Spin size="large" />; // Show loading spinner while not ready
    }

    // save page name
    const savePageName = async () => {
        // console.log(pages[currentPage]?.title); // test use only
        if (!pages[currentPage].pageId) {
            return;
        }
        try {
            const text = pages[currentPage].title;
            // console.log(text);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/lesson/updatepagetitle/${pages[currentPage].pageId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text })
            });
            const data = await response.json();
            if (!data.success) {
                console.error('Failed to save question:', data.message);
            }
        } catch (error) {
            console.error('Error saving changes:', error);
        }
    };

    const retryAsyncFunction = async (fn, maxRetries = 3) => {
       
        for (let i = 0; i < maxRetries; i++) {
            // console.log('savecontent test');
            try {
                return await fn();
            } catch (error) {
                if (i === maxRetries - 1) throw error;
            }
        }
    };

    const saveContent = async () => {
        if (!pages[currentPage].pageId) {
            return;
        }
        const text = pages[currentPage].content;
    
        const updateContent = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/lesson/updatecontent/${pages[currentPage].pageId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text })
            });
            const data = await response.json();
            if (!data.success) {
                throw new Error('Failed to save content: ' + data.message);
            }
        };
        
    
        try {
            // console.log('savecontent test');
            await retryAsyncFunction(updateContent);
        } catch (error) {
            console.error('Error saving content:', error);
            // notification.error({
            //     message: 'Error Saving Content',
            //     description: 'Please try again.'
            // });
        }
    };

    const saveContent2 = async () => {
        if (!pages[currentPage].pageId || !isContent2) {
            return;
        }
        const text = pages[currentPage].content2;
    
        const updateContent2 = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/lesson/updatecontent2/${pages[currentPage].pageId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text })
            });
            const data = await response.json();
            if (!data.success) {
                throw new Error('Failed to save content2: ' + data.message);
            }
        };
    
        try {
            await retryAsyncFunction(updateContent2);
        } catch (error) {
            console.error('Error saving content2:', error);
            // notification.error({
            //     message: 'Error Saving Additional Content',
            //     description: 'Please try again.'
            // });
        }
    };

    const SpaceForQuestion = () => {
        if (MCAdd && MatchingAdd && OPAdd && !pages[currentPage]?.imgpath && !isContent2) {
            return <p>You have three blocks left, you can use them for <strong>Multiple Choice</strong>, <strong>Matching</strong> and <strong>Object Placement</strong> Questions.</p>;
        } else if (MCAdd && MatchingAdd) {
            return <p>You have two blocks left, you can use them for <strong>Multiple Choice</strong> and <strong>Matching</strong> Questions.</p>;
        } else if (MCAdd && OPAdd && !pages[currentPage]?.imgpath && !isContent2) {
            return <p>You have two blocks left, you can use them for <strong>Multiple Choice</strong> and <strong>Object Placement</strong> Questions.</p>;
        } else if (MatchingAdd && OPAdd && !pages[currentPage]?.imgpath && !isContent2) {
            return <p>You have two blocks left, you can use them for <strong>Matching</strong> and <strong>Object Placement</strong> Questions.</p>;
        } else if (MCAdd) {
            return <p>You have one blocks left, you can use it for <strong>Multiple Choice</strong> Question.</p>;
        } else if (MatchingAdd) {
            return <p>You have one block left, you can use it for <strong>Matching</strong> Question.</p>;
        } else if (OPAdd && !pages[currentPage]?.imgpath && !isContent2) {
            return <p>You have one block left, you can use it for <strong>Object Placement</strong> Question.</p>;
        }
        else {
            return; 
        }
    };

    const handleEditTitle = () => {
        setIsEditTitleVisible(true);
    };

    const handleSaveTitle = () => {
        // save the updated title to a database
        const data = { lessonTitle };
        fetch(`${process.env.REACT_APP_API_URL}/lesson/updatetitle/${LessonID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
            // console.log('Success:', data);
            // setIsEditTitleVisible(false); 
        })
        .catch((error) => {
            console.error('Error:', error);
            setIsEditTitleVisible(false);
        });

        setIsEditTitleVisible(false);
    };

    const handleCancelEditTitle = () => {
        setIsEditTitleVisible(false);
    };

    const handleChangeTitle = (event) => {
        setLessonTitle(event.target.value);
    };

    const openAIQuestion = () => {
        // window.open('/aiquestion', '_blank');
        setIsAIMCModalVisible(true);
    }

    const openAIMatchingQuestion = () => {
        setIsAIMatchingModalVisible(true);
    }

    const handleAIMCOk = () => {
        if (AIMCRef.current && AIMCRef.current.getSelectedQuestionDetails) {
            // console.log('testest');
            const q_ = AIMCRef.current.getSelectedQuestionDetails();
            
            // setIsInnerModalVisible(false);
            // console.log(q_);
            if (!q_ || q_.length === 0) {
                alert('Fail to import the question, please try regenerate the question.');
                return;
            }
            // console.log('q_: ', q_);
            setSelectedQuestion(q_);
        }
        setIsAIMCModalVisible(false);
    }

    const handleAIMCCancel = () => {
        setIsAIMCModalVisible(false);
    }

    const handleAIMatchingOk = () => {
        if (AIMatchingRef.current && AIMatchingRef.current.getSelectedQuestionDetails) {
            // console.log('testest');
            const q_ = AIMatchingRef.current.getSelectedQuestionDetails();
            
            // setIsInnerModalVisible(false);
            // console.log(q_);
            if (!q_ || q_.length === 0) {
                alert('Fail to import the question, please try regenerate the question.');
                return;
            }
            // console.log('q_: ', q_);
            setSelectedQuestion(q_);
        }
        setIsAIMatchingModalVisible(false);
    }

    const handleAIMatchingCancel = () => {
        setIsAIMatchingModalVisible(false);
    }

    return (
        // <Spin spinning={loading} tip="processing..." size="large" >
        <div className='lesson-content-edit'>
            <div className="invisible-target" style={{height: 0, width: '100%', overflow: 'hidden'}}></div>

            <Spin spinning={loading} tip="processing..." size="large" >
            <Spin spinning={isLoading} tip="Saving Question" size="large">
             <Modal title="Edit Lesson Title" open={isEditTitleVisible} onOk={handleSaveTitle} onCancel={handleCancelEditTitle}>
                <Input value={lessonTitle} onChange={handleChangeTitle} />
            </Modal>

            <div className='page-lesson-title'>
                {lessonTitle}
                <EditOutlined style={{fontSize: '1.5rem', marginLeft: '1rem', transform: 'translateY(10%)'}} onClick={() => handleEditTitle()} />
            </div>
            <div className='add-page-content'>
                <div className='add-page-content-left'>
                    <DndProvider backend={HTML5Backend}>
                    <List
                        className="pages-list-container"
                        header={<div className='add-page-content-left-title'>Lesson Pages</div>}
                        bordered
                        dataSource={pages}
                        renderItem={(item, index) => (
                            <DraggablePage
                            key={item.title}
                            id={item.title}
                            index={index}
                            title={item.title}
                            movePage={movePage}
                            currentPage={currentPage}
                            handleDeletePage={handleDeletePage}
                            handlePageChange={handlePageChange}
                            setDragEnded={setDragEnded}
                            saveContent={saveContent}  // Passing saveContent function
                            saveContent2={saveContent2} // Passing saveContent2 function
                            />
                        )}
                        
                        footer={
                            <div>
                                <div style={{fontSize: '13px'}}><strong>This lesson has {pages.length} pages</strong></div>
                                <Button size="large" style={{backgroundColor: '#118f50', color: 'white'}} onClick={handleAddPage}>Add New Page</Button>
                            </div>
                        }
                    />
                    </DndProvider>
                </div>
                <div className='add-page-content-right'>
                    {isTyping && (<div className='content-autosave'>
                                    Contents are saved automatically.
                                </div>
                    )}
                    <div className='pagename'>
                        <div className='add-page-content-title'>Page Title:</div>
                        <Input 
                            value={pages[currentPage]?.title || ''}
                            onChange={handleTitleChange}
                            placeholder="Enter Title" 
                            style={{ marginBottom: 0 }} 
                            onBlur={savePageName}
                        />
                    </div>
                    <div className='instructional-content'>
                        <div className='add-page-content-title'>Instructional Content:</div>
                        <ReactQuill 
                            // ref={quillRef}
                            theme="snow" 
                            value={content} 
                            onChange={(newContent) => {
                                setIsTyping(true);
                                // console.log(newContent);
                                // Contents are saved automatically.
                                setContent(newContent);
                                const updatedPages = pages.map((page, idx) => 
                                    idx === currentPage ? { ...page, content: newContent } : page
                                );
                                setPages(updatedPages);
                                
                            }}
                            // onChange={handleQuillChange}
                            onBlur={saveContent}
                            modules={{
                                toolbar: [
                                [{ 'header': '1'}, { 'header': '2' }],
                                [{ 'size': ['small', false, 'large', 'huge'] }],
                                ['bold', 'italic', 'underline'],
                                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                ['clean']
                                ],
                                clipboard: {
                                    matchVisual: false
                                },
                                
                            }}
                            formats={['header', 'size', 'bold', 'italic', 'underline', 'list', 'bullet', 'indent']}       
                            style={{ 
                                // minHeight: '280px',
                                height: '280px',
                                maxHeight: '280px',
                                overflow: 'hidden',
                                // overflowY: 'hidden',
                                borderBottom: '1px solid #ccc',
                                borderBottomLeftRadius: '10px', 
                                borderBottomRightRadius: '10px', 
                            }}
                        />
                    </div>

                    <div className='add-page-content-img' style={{marginTop: '20px'}}>
                        <div className='add-page-content-img-button'>
                            <Button type="default" size="large" style={{ backgroundColor: '#118f50', color: 'white', width: '7.5rem', marginBottom: '20px', marginRight: '6px', zIndex: 1000 }} onClick={addImageLesson}> Add Image</Button>
                            <p style={{justifyContent: 'center', alignItems: 'center'}}><strong>OR</strong></p>
                            <Button type="default" size="large" style={{ backgroundColor: '#118f50', color: 'white', width: '11.5rem', marginBottom: '20px', marginLeft: '6px', zIndex: 1000  }} onClick={addInstContent}> Add Additional Text</Button>
                        </div>
                        <div className='lesson-img-display'>
                            
                            {/* <Button type="primary" size="large" onClick={saveButton} >Save Lesson</Button> */}
                            {/* <Button type="primary" size="large" onClick={testButton} >Test Buttton</Button> */}
                            <LessonAddImage ref={lessonAddImageRef} onUpdateImageSrc={updateImageSrc} pageId={pages[currentPage].pageId} />
                            {pages[currentPage]?.imgpath && <ImageDisplay key={pages[currentPage].imgpath} src={`/images/${pages[currentPage].imgpath}`} alt="Cannot Load IMG" EditImg={editImg} DeleteImg={deleteImg} />}
                            {pages[currentPage]?.imgpath && <Button type="default" size="large" style={{ backgroundColor: '#118f50', color: 'white', width: '8.5rem', marginBottom: '50px', marginLeft: '0px', zIndex: 1000  }} onClick={deleteImg}> Delete Image</Button> }
                        </div>
                        {isContent2 && 
                            <div className='additional-content'>
                                <div className='add-page-content-title'>Additional Instructional Content:</div>
                                <ReactQuill 
                                    // ref={quillRef2}
                                    theme="snow" 
                                    value={content2} 
                                    onChange={(newContent2) => {
                                        setIsTyping(true);
                                        // console.log(newContent);
                                        setContent2(newContent2);
                                        const updatedPages = pages.map((page, idx) => 
                                            idx === currentPage ? { ...page, content2: newContent2 } : page
                                        );
                                        setPages(updatedPages);
                                    }}
                                    onBlur={saveContent2}
                                    modules={{
                                        toolbar: [
                                        [{ 'header': '1'}, { 'header': '2' }],
                                        //   [{ 'header': [1, 2, 3] }],
                                        [{ 'size': ['small', false, 'large', 'huge'] }],
                                        ['bold', 'italic', 'underline'],
                                        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                        ['clean']
                                        ],
                                        clipboard: {
                                            matchVisual: false
                                        },
                                        
                                    }}
                                    formats={['header', 'size', 'bold', 'italic', 'underline', 'list', 'bullet', 'indent']}
                                    style={{ 
                                        height: '280px',
                                        maxHeight: '280px',
                                        overflow: 'hidden',
                                        borderBottom: '1px solid #ccc',
                                        borderBottomLeftRadius: '10px', 
                                        borderBottomRightRadius: '10px', 
                                    }}
                                />
                                <Button type="default" size="large" style={{ backgroundColor: '#118f50', color: 'white', width: '14.5rem', marginBottom: '50px', marginLeft: '6px', zIndex: 1000 }} onClick={deleteInstContent}> Delete Text</Button>                        </div>
                        }
                    </div>


                </div>
                <div>   
                    <div className='trythis-component'>
                        <div className='add-page-content-title'>Current Questions:</div>
                        {/* <div style={{ fontStyle: 'italic', color: 'gray', fontSize: 'small', marginBottom: '1px' }}>(Max 3)</div> */}
                        <div className='display-questions'>
                            <ul>
                                {pages[currentPage]?.questions.map((question, idx) => (
                                    <li key={idx} className="lesson-question-item">
                                        <div className='display-question-title-type'>
                                            <div className='display-questions-title'>
                                                {question.text}
                                            </div>
                                            <div className='display-questions-type'>
                                                {question.type === 'multiple_choice' && 'Multiple Choice'}
                                                {question.type === 'matching' && 'Matching'}
                                                {question.type === 'object_placement' && 'Object Placement'}
                                            </div>   
                                        </div>
                                        <div className="question-actions">
                                            <EditOutlined style={{fontSize: '20px', marginRight: '10px'}} onClick={() => handleEditQuestion(idx)} />
                                            <DeleteOutlined style={{fontSize: '20px'}} onClick={() => handleDeleteQuestion(idx)} />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <Modal 
                                title={<div style={{ fontSize: '20px', fontWeight: 'bold' }}>Edit Question</div>} 
                                open={isEditModalVisible} onOk={handleEditInterQuestion} onCancel={handleCancelEdit} okText="Update" width={1000}
                                maskClosable={false}
                            >
                                {
                                    editedType === "multiple_choice" && (
                                        <LessonMCEdit
                                            ref={lessonMCEditRef}
                                            questionData={pages[currentPage]?.questions[editingQuestionIndex]}
                                            handleUpdateQuestion={handleUpdateQuestion}
                                            setIsLoading={setIsLoading}
                                            setIsEditModalVisible={setIsEditModalVisible}
                                        />
                                    )
                                }
                                {   editedType === "matching" && (
                                        <LessonMatchingEdit
                                            ref={lessonMatchingEditRef}
                                            questionData={pages[currentPage]?.questions[editingQuestionIndex]}
                                            handleUpdateQuestion={handleUpdateQuestion}
                                            setIsLoading={setIsLoading}
                                            setIsEditModalVisible={setIsEditModalVisible}
                                        />
                                    )
                                }
                                {   editedType === "object_placement" && (
                                        <LessonOPEdit
                                            ref={lessonMatchingEditRef}
                                            questionData={pages[currentPage]?.questions[editingQuestionIndex]}
                                            handleUpdateQuestion={handleUpdateQuestion}
                                            setIsLoading={setIsLoading}
                                            setIsEditModalVisible={setIsEditModalVisible}
                                        />
                                    )
                                }
                            </Modal>
                        </div>
                        <div style={{marginTop: '20px'}}>
                            <div className='encourage-text'>           
                                {SpaceForQuestion()}
                            </div>
                            {pages[currentPage]?.questions.length < 3 && <Button type="default" size="large" style={{backgroundColor: '#118f50', color: 'white', width: '10.5rem', marginTop: '8px'}} onClick={handleAddInteraction}> Add New Question</Button>}
                        </div>
                    
                        <Modal 
                            title={<div style={{ fontSize: '20px', fontWeight: 'bold' }}>Add New Question</div>} 
                            open={isInterModalVisible} onOk={handleADDInterQuestion} onCancel={handleCancelInter} okText="OK" width={selectedType !== 'none' ? 1000 : 700} 
                            maskClosable={false}  // This should be true or just omitted as it's true by default
                            style={{ maxHeight: "85%", overflowY: "auto" }}
                        >
                            <div style={{marginTop: '10px', marginBottom: '10px', fontSize: '16px', fontWeight: 'bold'}}>Question Type</div>
                            <Select value={selectedType} style={{ width: 200 }} onChange={handleQuestionTypeChange}>
                                <Option value="none">None</Option>
                                {MCAdd && <Option value="multiple_choice">Multiple Choice Question</Option>}
                                {MatchingAdd && <Option value="matching">Matching Question</Option>}
                                {OPAdd && !pages[currentPage]?.imgpath && !isContent2 && <Option value="object_placement">Object Placement</Option>}
                            </Select>
                            {selectedType === "multiple_choice" &&
                                                                    <div>
                                                                        <Button type="primary" size='large' style={{backgroundColor: '#118f50', color: 'white'}} onClick={showInnerModal}>
                                                                            Import From Question Bank
                                                                        </Button>
                                                                        <Button type="primary" size='large' style={{backgroundColor: '#118f50', color: 'white', marginLeft: '30px'}} onClick={openAIQuestion}>
                                                                            Ask AI for Inspiration
                                                                        </Button>
                                                                    </div>
                            }
                            {selectedType === "matching" &&
                                                            <div>
                                                                <Button type="primary" size='large' style={{backgroundColor: '#118f50', color: 'white'}} onClick={showMatchingModal}>
                                                                    Import From Question Bank
                                                                </Button>
                                                                <Button type="primary" size='large' style={{backgroundColor: '#118f50', color: 'white', marginLeft: '30px'}} onClick={openAIMatchingQuestion}>
                                                                    Ask AI for Inspiration
                                                                </Button>
                                                            </div>
                            }
                            {/* qbank mc */}
                            <Modal
                                title="Question Bank"
                                open={isInnerModalVisible}
                                width={1000}
                                onOk={handleInnerOk}
                                onCancel={handleInnerCancel}
                                >
                                <QBankMCLesson ref={qBankMCRef} mcCheckedState={mcCheckedState} setMcCheckedState={setMcCheckedState} />
                                {/* <QBankMCLesson ref={qBankMCRef} mcCheckedState={mcCheckedState} setMcCheckedState={setMcCheckedState} updateQ={updateQ}/> */}
                            </Modal>
                            {/* qbank matching */}
                            <Modal
                                title="Question Bank"
                                open={matchingModalVisible}
                                width={1000}
                                onOk={handleMatchingOk}
                                onCancel={handleMatchingCancel}
                                >
                                <QBankMatchingLesson ref={qBankMatchingRef} mcCheckedState={mcCheckedState} setMcCheckedState={setMcCheckedState} />
                            </Modal>

                            <Modal
                                title="ASK AI for Inspiration"
                                open={isAIMCModalVisible}
                                width={1000}
                                onOk={handleAIMCOk}
                                onCancel={handleAIMCCancel}
                                maskClosable={false}
                                okText="Import" 
                            >
                                <AiGenerateMC ref={AIMCRef} />
                            </Modal>

                            <Modal
                                title="ASK AI for Inspiration"
                                open={isAIMatchingModalVisible}
                                width={1000}
                                onOk={handleAIMatchingOk}
                                onCancel={handleAIMatchingCancel}
                                maskClosable={false}
                                okText="Import" 
                            >
                                <AiGenerateMatching ref={AIMatchingRef} />
                            </Modal>

                            {selectedType === "multiple_choice" && <LessonMC ref={lessonMCRef} onAddQuestion={handleAddQuestionToPage} pageid={pages[currentPage].pageId} setIsLoading={setIsLoading} selectedQuestion={selectedQuestion} setIsInterModalVisible={setIsInterModalVisible} />}
                            {selectedType === "matching" && <LessonMatching ref={lessonMatchingRef} onAddQuestion={handleAddQuestionToPage} pageid={pages[currentPage].pageId} setIsLoading={setIsLoading} selectedQuestion={selectedQuestion} setIsInterModalVisible={setIsInterModalVisible} />}
                            {/* {selectedType === "object_placement" && <LessonOP ref={lessonOPRef} onAddQuestion={handleAddQuestionToPage} />} */}
                            {selectedType === "object_placement" && !isContent2 && !pages[currentPage]?.imgpath && (
                                <LessonOP ref={lessonOPRef} onAddQuestion={handleAddQuestionToPage} pageid={pages[currentPage].pageId} setIsLoading={setIsLoading} setIsInterModalVisible={setIsInterModalVisible} />
                            )}
                        
                        </Modal> 
                        <div className='general-module'>
                            <p className='general-module-title'>Interactive Modules:</p>
                            <div style={{display: 'flex', marginTop: '5px', marginBottom: '10px'}}>
                                <Button type="default" size="large" style={{backgroundColor: '#118f50', color: 'white', width: '12.5rem', marginTop: '8px'}} onClick={handleAddGenModule}> Add/Edit Gesture Module</Button>
                                <Button type="default" size="large" style={{backgroundColor: '#118f50', color: 'white', width: '12.5rem', marginTop: '8px', marginLeft: '30px'}} onClick={handleAddLensModule}> Add/Edit Lens Module</Button>
                                {/* <Button type="default" size="large" style={{backgroundColor: '#FFCC00', color: 'white', width: '12rem', marginTop: '8px',marginLeft: '22px'}}> Clear Hand Module</Button> */}
                            </div>
                            <div style={{display: 'flex'}}>
                                {/* <Button type="default" size="large" style={{backgroundColor: '#118f50', color: 'white', width: '12rem', marginTop: '8px'}} onClick={handleAddLensModule}> Add/Edit Lens Module</Button> */}
                                {/* <Button type="default" size="large" style={{backgroundColor: '#FFCC00', color: 'white', width: '12rem', marginTop: '8px',marginLeft: '22px'}}> Clear Lens Module</Button> */}
                            </div>
                            <Modal
                                title={<div style={{ fontSize: '20px', fontWeight: 'bold' }}>Gesture Module</div>} 
                                open={isGenModulelVisible}
                                // width={selectedModule !== 'none' ? 1000 : 700} 
                                width={1000}
                                onOk={handleGenModuOk}
                                onCancel={handleGenModuCancel}
                                maskClosable={false}
                                // bodyStyle={{ maxHeight: '570px', overflow: 'auto' }}
                                styles={{
                                    body: {
                                        maxHeight: '570px', 
                                        overflow: 'auto'
                                    }
                                }}
                                
                            >
                                <Spin spinning={fetchingGesture} tip="loading..." size="large" >
                                    <HandModule ref={handModuleRef} pageid={pages[currentPage].pageId} setIsLoading={setLoading} onUpdate={updateHandData} setFetchingGesture={setFetchingGesture} />
                                </Spin>
                            </Modal>

                            <Modal
                                title={<div style={{ fontSize: '20px', fontWeight: 'bold' }}>Lens Module</div>} 
                                open={isLensModulelVisible}
                                // width={selectedModule !== 'none' ? 1000 : 700} 
                                width={1000}
                                onOk={handleLensModuOk}
                                onCancel={handleLensModuCancel}
                                maskClosable={false}
                            >
                                <LensModule ref={lensModuleRef} pageid={pages[currentPage].pageId} setIsLoading={setIsLoading} onUpdate={updateLensData}
                                            lens1={pages[currentPage].lens1} lens2={pages[currentPage].lens2} lens3={pages[currentPage].lens3} lens4={pages[currentPage].lens4} />
                            </Modal>
                        </div>
                    </div>
                    
                </div>
                <Joyride
                    hideCloseButton={true}
                    continuous={true}
                    run={run}
                    steps={steps}
                    // scrollToFirstStep={true}
                    scrollToSteps={true}
                    scrollOffset={1000}
                    // disableScrolling={true}
                    showProgress={true}
                    showSkipButton={true}
                    styles={{
                        options: {
                            zIndex: 10000,
                            // overlayColor: 'transparent', // Makes the overlay invisible
                            // overlayColor: 'rgba(55, 55, 55, 1)', // Light semi-transparent overlay
                            spotlightPadding: 0,
                            spotlightBackground: 'transparent', // Removes the spotlight effect
                        },
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            width: '100vw',
                            height: '100vh',
                        },
                        tooltip: {
                            // maxWidth: 'none', // Ensure the tooltip can expand to necessary width
                            // width: 'auto', // Auto-adjust width
                            // minWidth: '350px' // Set a minimum width for the tooltip
                            width: '450px',
                        },
                        buttonSkip: {
                            border: '1px solid #000', // Adds a black border to the skip button
                            padding: '8px 10px', // Increases padding for better appearance
                            backgroundColor: '#FF0000', // Changes the background color to red
                            borderRadius: '5px',
                            color: 'white' ,
                            fontSize: '14px'
                        },
                        buttonNext: {
                            backgroundColor: 'green', // Set the Next button to green
                            color: 'white' // Optional: set text color to white for better visibility
                        },
                    }}
                    spotlightPadding={0}
                    disableOverlayClose={true}
                    locale={{ last: 'Finish', skip: 'Exit Tour' }}
                    callback={handleJoyrideCallback}
                />
            </div>
            </Spin>
            </Spin>
        </div>
        // </Spin>
    )
});

export default PageAndContent;