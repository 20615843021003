import React, { useState, useEffect, useRef, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Input, Button, List, Spin } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import './index.css'

import Header from '../../components/header'

import BreadCrumbs from '../../components/breadCrumbs';

const Lessons = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [lessons, setLessons] = useState([]);
    useEffect(() => {
        document.title = "Lesson Bank";
        fetchLessons();
    }, []);

    // fetch lessons from the database
    const fetchLessons = () => {
        setIsLoading(true);
        // const userid = localStorage.getItem('userid');
        const userid = 4;
        // console.log(userid);
        fetch(`${process.env.REACT_APP_API_URL}/users/lessons/${userid}`)
            .then(response => response.json())
            .then(data => {
                // console.log("lessons fetched:", data);
                setLessons(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Failed to fetch Lessons:', error);
                setIsLoading(false); 
            });
    };

    // display selected lesson
    const handleLessonClick = (lesson) => {
        // console.log("Lesson clicked:", lesson); // Debug log
        setSelectedLesson(lesson);
        // console.log("Selected lesson state updated:", lesson); // Debug log
        navigate(`/lessonbankpreview/${lesson.LessonID}`);
    };

    // // delete lesson
    // const handleDeleteClick = (e, quizID, index) => {
    //     e.stopPropagation();
    //     // call api to delte lesson
    // }

    // // create new lesson
    // const createNewLesson = () => {
    //     navigate('/createlesson');
    // }

    return (
        <div>
            <Header />
            {/* <BreadCrumbs class="breadcrumbs" quizName={selectedQuiz ? selectedQuiz.LTitle : null} /> */}
            <div className='lesson-page-body'>
                <div className='quiz-list'>
                    <h3 style={{fontSize: '25px'}}>Lesson Bank</h3>
                    <Spin spinning={isLoading}> 
                        <List
                            itemLayout="horizontal"
                            dataSource={lessons}
                            renderItem={(lesson, index) => (
                                <List.Item
                                    className={`quiz-item quiz-title ${selectedLesson && selectedLesson.LessonID === lesson.LessonID ? 'active' : ''}`}
                                    onClick={() => handleLessonClick(lesson)}
                                    actions={[
                                        // <EditOutlined className="edit-quiz-title" style={{fontSize: '20px'}} onClick={(e) => {
                                        //     handleEditClick(e, lesson)
                                        // }} />,
                                        // <DeleteOutlined key="delete" style={{fontSize: '20px'}} onClick={(e) => handleDeleteClick(e, lesson.LessonID, index)} />,
                                    ]}
                                >
                                    <List.Item.Meta
                                        // title={`${quiz.LTitle}`}
                                        title={<span style={{ fontSize: '20px', fontWeight: 'normal' }}>{lesson.LessonTitle}</span>}
                                    />
                                </List.Item>
                            )}
                        />
                    </Spin>
                    
                    {/* <Button type="primary" onClick={createNewLesson}>Create Lesson</Button> */}
                </div>
            </div>
        </div>
    )
    };

export default Lessons;