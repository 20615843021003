import React, { useState, useEffect } from 'react';
import jsonData from '../../data_lesson.json';  

const LessonList = () => {
  const [lessonItems, setLessonItems] = useState([]);

  useEffect(() => {
    setLessonItems(jsonData);  
    console.log(jsonData);
  }, []);  

  return (
    <div>

    </div>
  );
};

export default LessonList;
