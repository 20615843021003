import React from 'react';
import './index.css';


const RenderOptions = ({ question }) => {
    // console.log(question);
    // const questionId = question.id;

    return (
        <div>
            <div className='truefalse-display'>
                <li> True </li>
                <li> False </li>
            </div>
        </div>
    );
};

export default RenderOptions;
