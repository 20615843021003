import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { List, notification, Pagination, Spin } from 'antd';
// import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import RenderOptionsLesson from '../../components/renderOptionsLesson';
import RenderMatchesLesson from '../../components/renderMatchesLesson';
import { useParams, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './index.css';
// import layout_img from '../../assets/layout.png';
// import { icons } from 'antd/es/image/PreviewGroup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import trythis from '../../assets/trythis.png';
import instructionpng from '../../assets/instruction.png';
import rfidpng from '../../assets/rfid.png';
// import instructionsvg from '../../assets/instruction.svg';
// import '../../../node_modules/react-quill/dist/quill.snow.css'; 


// const { Option } = Select;

const PreviewLesson = forwardRef((props, ref) => {
    const { stepID } = useParams();
    const [pages, setPages] = useState([{ pageId: '', title: "Instruction Page", content: '', content2: '', imgpath: '', questions: [] }]);
    const [currentPage, setCurrentPage] = useState(0);
    // const [content, setContent] = useState('');
    // const [content2, setContent2] = useState('');
    const [loading, setLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [iterCount, setIterCount] = useState(0);
    // const [lessonInfoReady, setLessonInfoReady] = useState(false);
    const [lessonInfo, setLessonInfo] = useState({ title: '', description: '' });
    const { LessonID } = props;  // Receive the LessonID from parent component
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

    // const [dataLoaded, setDataLoaded] = useState(false);

    const totalPages = pages.length;
    const pageSize = 1;
    let iCount = 0;

    // let renderCount = 0;
    const [renderCount, setRenderCount] = useState(0);



    useEffect(() => {
        if (stepID === '3') {
            setIsComplete(true);
        } else {
            setIsComplete(false);
        }
    }, [stepID]);

    useEffect(() => {
        async function fetchData() {
            if (LessonID) {
                await fetchLessonInfo(LessonID);          
                await fetchPagesForLesson(LessonID);
                const rcount = renderCount + 1;
                // console.log(rcount);
                setRenderCount(rcount);
            }
        }
        // if (stepID !== '3') {
            fetchData();
            // console.log('32423423');
        // }
        // generateCSV2();
    }, [LessonID]);
 
    useImperativeHandle(ref, () => ({
        getRenderCount,
        saveAsPdf
    }));

    const getRenderCount = () => {
        // console.log(renderCount);
        return renderCount;
    }

    const itemRender = (current, type, originalElement) => {
        if (type === 'page') {
            // if (!isComplete && current === 0) {
            //     return null; // Do not render the 0th page if isComplete is false
            // }
            return <a>{isComplete ? current - 1 : current}</a>; // Adjust the displayed number
        }
        return originalElement; // For 'prev' and 'next' buttons, use default rendering
    };    

    // const fetchLessonInfo = async (lessonId) => {
    //     const url = `${process.env.REACT_APP_API_URL}/lessonTD/${lessonId}`;
    //     fetch(url)
    //         .then(response => response.json())
    //         .then(data => {
    //             setLessonInfo({
    //                 title: data.lessonTitle,
    //                 description: data.lessonDescrp
    //             });
    //         })
    //         .catch(error => {
    //             console.error('Failed to fetch lesson info:', error);
    //         });
    // };

    const fetchLessonInfo = async (lessonId) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/lessonTD/${lessonId}`);
          const data = await response.json();
          setLessonInfo({
            title: data.lessonTitle,
            description: data.lessonDescrp
          });
        } catch (error) {
          console.error('Failed to fetch lesson info:', error);
        }
    };

    // const fetchPagesForLesson = async (lessonId) => {
    //     setLoading(true);
    //     // const url = `${process.env.REACT_APP_API_URL}/lesson/getpages/${lessonId}`;
    //     // fetch(url)
    //     const url = `${process.env.REACT_APP_API_URL}/lesson/getpages/${lessonId}?timestamp=${new Date().getTime()}`;
    //     fetch(url, {
    //         method: 'GET',
    //         headers: {
    //             'Cache-Control': 'no-cache',
    //             'Pragma': 'no-cache',
    //             'Expires': '0'
    //         }
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             // console.log(data);
    //             iCount = 0;
    //             const formattedPages = data.map(page => ({
    //                 title: page.title || "", 
    //                 content: page.content || "",   
    //                 content2: page.content2 || "",    
    //                 imgpath: page.imgPath || "",        
    //                 questions: page.questions.map(question => {
    //                     // Base question structure
    //                     const baseQuestion = {
    //                         id: question.QuestionID,
    //                         text: question.QuestionText,
    //                         type: question.QuestionType,
    //                         optionNum: question.details ? question.details.OptionNum : 0,
    //                     };
    //                     // Extend base structure based on type
    //                     if (question.QuestionType === 'multiple_choice') {
    //                         // let count = iterCount + 1;
    //                         // console.log('mc count:', count, iterCount);
    //                         // setIterCount(count);
    //                         iCount++;
    //                         // console.log(iCount);
    //                         return {
    //                             ...baseQuestion,
    //                             choices: question.details ? [
    //                                 question.details.A || null,
    //                                 question.details.B || null,
    //                                 question.details.C || null,
    //                                 question.details.D || null,
    //                                 question.details.E || null
    //                             ].filter(choice => choice !== null) : [],  // Filter out null values
    //                             correctAnswer: question.details ? question.details.CorrectAnswer : null,
    //                             messages: [
    //                                 question.details.ComA || null,
    //                                 question.details.ComB || null,
    //                                 question.details.ComC || null,
    //                                 question.details.ComD || null,
    //                                 question.details.ComE || null
    //                             ].filter(msg => msg !== null)  // Filter out null values
    //                         };
    //                     } else if (question.QuestionType === 'matching') {
    //                         // let count = iterCount + 1;
    //                         // console.log('matching count:', count, iterCount);
    //                         // setIterCount(count);
    //                         iCount++;
    //                         // console.log(iCount);
    //                         return {
    //                             ...baseQuestion,
    //                             matches: [
    //                                 question.details.A || null,
    //                                 question.details.B || null,
    //                                 question.details.C || null,
    //                                 question.details.D || null,
    //                                 question.details.E || null,
    //                                 question.details.MatchA || null,
    //                                 question.details.MatchB || null,
    //                                 question.details.MatchC || null,
    //                                 question.details.MatchD || null,
    //                                 question.details.MatchE || null,
    //                                 question.details.ComA || null,
    //                                 question.details.ComB || null,
    //                                 question.details.ComC || null,
    //                                 question.details.ComD || null,
    //                                 question.details.ComE || null,
    //                                 question.details.Defaultcom || null
    //                             ],
    //                             matchCon: [
    //                                 question.details.AnsA || null,
    //                                 question.details.AnsB || null,
    //                                 question.details.AnsC || null,
    //                                 question.details.AnsD || null,
    //                                 question.details.AnsE || null
    //                             ],
    //                         };
    //                     } else if (question.QuestionType === 'object_placement') {
    //                         // let count = iterCount + 1;
    //                         // console.log('op count:', count, iterCount);
    //                         // setIterCount(count);
    //                         iCount++;
    //                         // console.log(iCount);
    //                         return {
    //                             ...baseQuestion,
    //                         };
    //                     } else {
    //                         return baseQuestion;
    //                     }
    //                 })    
    //             }));
    //             // console.log('formattedpages:', formattedPages);
    //             // setPages(formattedPages);
    //             if (stepID === '3') {
    //                 setPages([pages[0], ...formattedPages]);
    //             } else {
    //                 setPages(formattedPages);
    //             }
    //             setIterCount(iCount);
    //             setLoading(false);
    //             setIsReady(true);
    //         })
    //         .catch(error => {
    //             console.error('Failed to fetch pages:', error);
    //             setLoading(false);  
    //         });
    // };

    const fetchPagesForLesson = async (lessonId) => {
        setLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/lesson/getpages/${lessonId}?timestamp=${new Date().getTime()}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                }
            });
            const data = await response.json();
            let iCount = 0;
    
            const formattedPages = data.map(page => {
                return {
                    // pageId: page.pageId || "",
                    title: page.title || "",
                    content: page.content || "",
                    content2: page.content2 || "",
                    imgpath: page.imgPath || "",
                    questions: page.questions.map(question => {
                        const baseQuestion = {
                            id: question.QuestionID,
                            text: question.QuestionText,
                            type: question.QuestionType,
                            optionNum: question.details ? question.details.OptionNum : 0,
                        };
    
                        if (question.QuestionType === 'multiple_choice') {
                            iCount++;
                            return {
                                ...baseQuestion,
                                choices: question.details ? [
                                    question.details.A || null,
                                    question.details.B || null,
                                    question.details.C || null,
                                    question.details.D || null,
                                    question.details.E || null
                                ].filter(choice => choice !== null) : [],
                                correctAnswer: question.details ? question.details.CorrectAnswer : null,
                                messages: [
                                    question.details.ComA || null,
                                    question.details.ComB || null,
                                    question.details.ComC || null,
                                    question.details.ComD || null,
                                    question.details.ComE || null
                                ].filter(msg => msg !== null)
                            };
                        } else if (question.QuestionType === 'matching') {
                            iCount++;
                            return {
                                ...baseQuestion,
                                matches: [
                                    question.details.A || null,
                                    question.details.B || null,
                                    question.details.C || null,
                                    question.details.D || null,
                                    question.details.E || null,
                                    question.details.MatchA || null,
                                    question.details.MatchB || null,
                                    question.details.MatchC || null,
                                    question.details.MatchD || null,
                                    question.details.MatchE || null,
                                    question.details.ComA || null,
                                    question.details.ComB || null,
                                    question.details.ComC || null,
                                    question.details.ComD || null,
                                    question.details.ComE || null,
                                    question.details.Defaultcom || null
                                ],
                                matchCon: [
                                    question.details.AnsA || null,
                                    question.details.AnsB || null,
                                    question.details.AnsC || null,
                                    question.details.AnsD || null,
                                    question.details.AnsE || null
                                ],
                            };
                        } else if (question.QuestionType === 'object_placement') {
                            iCount++;
                            return {
                                ...baseQuestion
                            };
                        } else {
                            return baseQuestion;
                        }
                    })
                };
            });
    
            if (stepID === '3') {
                setPages([pages[0], ...formattedPages]);
            } else {
                setPages(formattedPages);
            }
            setIterCount(iCount);
            setIsReady(true);
        } catch (error) {
            console.error('Failed to fetch pages:', error);
        } finally {
            setLoading(false);
        }
    };
    

    // useEffect(() => {
    //     // Only set content if it exists and prevent setting undefined or null
    //     if (pages[currentPage] && pages[currentPage].content) {
    //         setContent(pages[currentPage].content);
    //     }
    //     if (pages[currentPage] && pages[currentPage].content2) {
    //         setContent2(pages[currentPage].content2);
    //     }
    // }, [currentPage, pages]);

    const handlePageChange = (page) => {
        setCurrentPage(page - 1);
    };

    // const handlePageChange = (page) => {
    //     if (!isComplete && page === 0) {
    //         setCurrentPage(1); // Skip the 0th page if isComplete is false
    //     } else {
    //         setCurrentPage(page - 1);
    //     }
    // };
    

    // generate csv
    const generateCSV = async () => {
        // console.log("generate csv");
        for (let pageIndex = 1; pageIndex < pages.length; pageIndex++) {
            const csv_name = 'page' + (pageIndex);
            var list_data = [];
            for (let questionIndex = 0; questionIndex < pages[pageIndex].questions.length; questionIndex++) {
                var question_data = [];
                question_data.push(pages[pageIndex].questions[questionIndex].type);
                question_data.push(pages[pageIndex].questions[questionIndex].id);
                question_data.push(questionIndex+1); // position of question on the page
                list_data.push(question_data);
            }
            const csv_data = {
                name: csv_name,
                data: list_data,
            };
            fetch(`${process.env.REACT_APP_API_URL}/api/generate-csv`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(csv_data)
            })
            .then(response => response.json())
            // .then(data => console.log('Success:', data))
            .catch((error) => console.error('Error:', error));
            await new Promise(resolve => setTimeout(resolve, 1500));
        }   
    };

    // generate csv2
    const generateCSV2 = async () => {
        // console.log("generate csv");
        fetch(`${process.env.REACT_APP_API_URL}/api/generate-csv2/${LessonID}`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify(csv_data)
        })
        
        // for (let pageIndex = 1; pageIndex < pages.length; pageIndex++) {
        //     const csv_name = 'Bpage' + (pageIndex);
        //     var list_data = [];
        //     // for (let questionIndex = 0; questionIndex < pages[pageIndex].questions.length; questionIndex++) {
        //     //     var question_data = [];
        //     //     question_data.push(pages[pageIndex].questions[questionIndex].type);
        //     //     question_data.push(pages[pageIndex].questions[questionIndex].id);
        //     //     question_data.push(questionIndex+1);
        //     //     list_data.push(question_data);
        //     // }
        //     list_data.push(pages[pageIndex].pageId);
        //     console.log(list_data);
        //     const csv_data = {
        //         name: csv_name,
        //         data: list_data,
        //     };
        //     fetch(`${process.env.REACT_APP_API_URL}/api/generate-csv2`,{
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify(csv_data)
        //     })
        //     .then(response => response.json())
        //     // .then(data => console.log('Success:', data))
        //     .catch((error) => console.error('Error:', error));
        //     await new Promise(resolve => setTimeout(resolve, 1500));
        // }   
    };

    // save as pdf
    const saveAsPdf = async () => {
        setLoading(true);
        setIsGeneratingPDF(true);  
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            // format: 'a4'
            format: 'letter'
            // format: [297, 210]
        });

        for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
            if (pageIndex > 0) {
                pdf.addPage();
            }
            // console.log(pages[pageIndex]);
            
            setCurrentPage(pageIndex);  // Update the current page to render the next set of questions
            await new Promise(resolve => setTimeout(resolve, 700)); // Wait for the state to update and render
    
            const input = document.getElementById('divToPDF');
            const canvas = await html2canvas(input, {
                scale: 5,
                useCORS: true
            });
    
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgHeight = canvas.height * pdfWidth / canvas.width; 
            if (imgHeight > pdfHeight) {
                pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
            } else {
                pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, imgHeight);
            }
        }

        var date = new Date();
        let fileName = ( date.getFullYear() + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2) + ("0" + date.getHours() ).slice(-2) + ("0" + date.getMinutes()).slice(-2) + ("0" + date.getSeconds()).slice(-2) );
        
        pdf.save(`${fileName}.pdf`);
        setLoading(false);
        generateCSV();
        generateCSV2();
        notification.success({
            message: 'Complete',
            description: 'Lesson Finalization Complete',
            duration: 1, // Duration in seconds,
            placement: 'top'
        });
        setIsGeneratingPDF(false);
    };

    if (!isReady) { 
        return <Spin spinning={true} size="large" />;
    }


    return (
        <div className='lesson-preview-content'>
            <div className='lesson-preview-content-left'>
                <List
                    className="lesson-preview-pages-list-container"
                    header={<div className='lesson-preview-content-left-title'>Pages</div>}
                    bordered
                    dataSource={pages.slice()}
                    // dataSource={!isComplete ? pages.slice(1) : pages} 
                    renderItem={(item, index) => (
                        <List.Item 
                            onClick={() => handlePageChange(index + 1)} 
                            style={{ cursor: 'pointer', backgroundColor: currentPage === index ? '#cfefff' : '#fff' }}
                        >
                            {item.title}
                        </List.Item>
                    )}
                    footer={
                        // <div>
                        //     <div style={{fontSize: '13px'}}><strong>This lesson has {pages.length} pages</strong></div>
                        // </div>
                        <div>
                            <div style={{ fontSize: '13px' }}>
                                <strong>
                                    This lesson has {isComplete ? pages.length - 1 : pages.length} pages
                                </strong>
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='lesson-preview-content-right'>
                <div className='prereview-body'>    
                    <Pagination
                        current={currentPage + 1}
                        onChange={handlePageChange}
                        total={totalPages * pageSize}
                        pageSize={pageSize}
                        showSizeChanger={false}
                        itemRender={itemRender}
                    />     
                    <Spin spinning={loading}>  
                        {/* <div className='prereview-layout-lesson'> */}
                        <div className={`prereview-layout-lesson ${( currentPage > 0 || stepID !== '3' )? 'dashed-lines-horizontal dashed-lines-vertical' : ''}`}>
                            {/* test */}
                            <div id="divToPDF" className='prereview-pdf-lesson'>
                                {stepID !== '3' ? (            
                                    <div>
                                        <div className='page_identifier'>
                                            <img className='page_identifier_img' src={`/pageidentifier/page${ currentPage+1 }.svg`} alt="Cannot Load Img" />
                                        </div>
                                        <div className="prereview-body-container">
                                            <div className='section-content'>
                                                <h2 style={{paddingLeft: '30px', margin: '0px', paddingBottom: '0px', paddingTop: '0px'}}>{pages[currentPage].title}</h2>
                                                {/* <div dangerouslySetInnerHTML={{ __html: pages[currentPage]?.content }} /> */}
                                                <ReactQuill
                                                    value={pages[currentPage]?.content}
                                                    readOnly={true}
                                                    theme="bubble" // Bubble theme for a clean read-only view
                                                    modules={{
                                                        toolbar: false // Disable toolbar
                                                    }}
                                                />
                                            </div>
       
                                            <div className='section-2-preview'>             
                                                {pages[currentPage]?.imgpath ? (
                                                    <div className='section-image'>
                                                        <img className='lesson-preview-img' src={`/images/${pages[currentPage].imgpath}`} alt="Cannot Load Img" />
                                                    </div>
                                                ) : pages[currentPage]?.questions.some(question => question.type === 'object_placement') ? (
                                                    pages[currentPage]?.questions.map((question, index) => (
                                                        question.type === 'object_placement' && (
                                                            <div className='question-box-lesson-op' key={index}>
                                                                <div className={`ls-question-slot-title ${question.type === 'matching' ? 'matching-style-lesson' : ''}`}>
                                                                    <p>{question.text}</p>
                                                                </div>
                                                                <div className='ls-question-slot-options-op'>       
                                                                    {/* <div className="object-placement-circle">
                                                                        Place Object Here
                                                                    </div> */}
                                                                    <img src={rfidpng} alt="Cannot Load Image" className="rfid-image"/>
                                                                </div>
                                                        
                                                                <img src={trythis} alt="Cannot Load Image" className="top-layer-image-3"/>
                                                            </div>
                                                        )
                                                    ))
                                                ) : pages[currentPage]?.content2 ? (
                                                    <ReactQuill
                                                        value={pages[currentPage]?.content2}
                                                        readOnly={true}
                                                        theme="bubble" // Bubble theme for a clean read-only view
                                                        modules={{
                                                            toolbar: false // Disable toolbar
                                                        }}
                                                    />
                                                ) : (
                                                    <div className={`question-box-empty-op ${isGeneratingPDF ? 'no-print' : ''}`}>
                                                        <p>[A object placement question can be put here.]</p>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='section-question-1'>
                                                {pages[currentPage]?.questions.filter(q => q.type === 'multiple_choice').length > 0 ? (
                                                    pages[currentPage]?.questions.map((question, index) => (
                                                        question.type === 'multiple_choice' && (
                                                            <div className='question-box-lesson' key={index}>
                                                                <div className={`ls-question-slot-title ${question.type === 'matching' ? 'matching-style-lesson' : ''}`}>
                                                                    <p>{question.text}</p>
                                                                </div>
                                                                <div className='ls-question-slot-options'>
                                                                    {<RenderOptionsLesson question={question} />}
                                                                </div>
                                                                <img src={trythis} alt="Cannot Load Image" className="top-layer-image"/>
                                                            </div>
                                                        )
                                                    ))
                                                ) : (
                                                    // Display an empty block or a placeholder if no multiple choice questions exist
                                                    <div className={`question-box-empty ${isGeneratingPDF ? 'no-print' : ''}`}>
                                                        <p>[A choice question can be put here.]</p>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='section-question-2'>
                                                {pages[currentPage]?.questions.filter(q => q.type === 'matching').length > 0 ? (
                                                    pages[currentPage]?.questions.map((question, index) => (
                                                        question.type === 'matching' && (
                                                            <div className='question-box-lesson' key={index}>
                                                                <div className={`ls-question-slot-title ${question.type === 'matching' ? 'matching-style-lesson' : ''}`}>
                                                                    <p>{question.text}</p>
                                                                </div>
                                                                <div className='ls-question-slot-options'>
                                                                    {<RenderMatchesLesson question={question} />}
                                                                </div>
                                                                <div style={{position: 'absolute', fontSize: '13px', top: '510px', marginLeft: '10px'}}>
                                                                    *Touch left and right option at the same time.
                                                                </div>
                                                                <img src={trythis} alt="Cannot Load Image" className="top-layer-image-2"/>
                                                            </div>
                                                        )
                                                    ))
                                                ) : (
                                                    <div className={`question-box-empty ${isGeneratingPDF ? 'no-print' : ''}`}>
                                                        <p>[A matching question can be put here.]</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='page-number-lesson'>
                                            Page: {currentPage+1}
                                        </div>
                                    </div>) : (
                                         currentPage === 0 ? (
                                            <div>
                                                <div className="blank-page-content">
                                                    <div className='lesson-title-pages'>
                                                        <div className='lesson-title-'>
                                                            <h2>Board Setup Instructions</h2>
                                                            <p style={{fontSize: '20px', fontWeight: 'bold'}}>Lesson Title: {lessonInfo.title}</p>
                                                            <p>Lesson Description: {lessonInfo.description}</p>
                                                        </div>
                                                        <div className='lesson-pages-components'>
                                                            <div> Pages: {totalPages - 1} </div>
                                                            <div> Interactive Components: {iterCount} </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <img src={instructionpng} alt="Cannot Load Image" className="instruction-png"/>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (         
                                            <div>
                                                <div className='page_identifier'>
                                                    <img className='page_identifier_img' src={`/pageidentifier/page${ currentPage }.svg`} alt="Cannot Load Img" />
                                                </div>
                                                <div className="prereview-body-container">
                                                    <div className='section-content'>
                                                        {/* <h2>Content</h2> */}
                                                        <h2 style={{paddingLeft: '30px', margin: '0px', paddingBottom: '0px', paddingTop: '0px'}}>{pages[currentPage].title}</h2>
                                                        {/* <div dangerouslySetInnerHTML={{ __html: pages[currentPage]?.content }} /> */}
                                                        <ReactQuill
                                                            value={pages[currentPage]?.content}
                                                            readOnly={true}
                                                            theme="bubble" // Bubble theme for a clean read-only view
                                                            modules={{
                                                                toolbar: false // Disable toolbar
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='section-2-preview'>
                                                        {pages[currentPage]?.imgpath ? (
                                                            <div className='section-image'>
                                                                <img className='lesson-preview-img' src={`/images/${pages[currentPage].imgpath}`} alt="Cannot Load Img" />
                                                            </div>
                                                        ) : pages[currentPage]?.questions.some(question => question.type === 'object_placement') ? (
                                                            pages[currentPage]?.questions.map((question, index) => (
                                                                question.type === 'object_placement' && (
                                                                    <div className='question-box-lesson-op' key={index}>
                                                                        <div className={`ls-question-slot-title ${question.type === 'matching' ? 'matching-style-lesson' : ''}`}>
                                                                            <p>{question.text}</p>
                                                                        </div>
                                                                        <div className='ls-question-slot-options-op'>       
                                                                            {/* <div className="object-placement-circle">
                                                                                Place Object Here
                                                                            </div> */}
                                                                            <img src={rfidpng} alt="Cannot Load Image" className="rfid-image"/>
                                                                        </div>
                                                                        <img src={trythis} alt="Cannot Load Image" className="top-layer-image-3"/>
                                                                    </div>
                                                                )
                                                            ))
                                                        ) : pages[currentPage]?.content2 ? (
                                                            <ReactQuill
                                                                value={pages[currentPage]?.content2}
                                                                readOnly={true}
                                                                theme="bubble" // Bubble theme for a clean read-only view
                                                                modules={{
                                                                    toolbar: false // Disable toolbar
                                                                }}
                                                            />
                                                        ) : (
                                                            <div className={`question-box-empty-op ${isGeneratingPDF ? 'no-print' : ''}`}>
                                                                <p>[A object placement question can be put here.]</p>
                                                            </div>
                                                        )}
                                   
                                                    </div>
                                                    <div className='section-question-1'>
                                                        {/* <h2>Question 1</h2> */}
                                                        {pages[currentPage]?.questions.filter(q => q.type === 'multiple_choice').length > 0 ? (
                                                            pages[currentPage]?.questions.map((question, index) => (
                                                                question.type === 'multiple_choice' && (
                                                                    <div className='question-box-lesson' key={index}>
                                                                        <div className={`ls-question-slot-title ${question.type === 'matching' ? 'matching-style-lesson' : ''}`}>
                                                                            <p>{question.text}</p>
                                                                        </div>
                                                                        <div className='ls-question-slot-options'>
                                                                            {<RenderOptionsLesson question={question} />}
                                                                        </div>
                                                                        <img src={trythis} alt="Cannot Load Image" className="top-layer-image"/>
                                                                    </div>
                                                                )
                                                            ))
                                                        ) : (
                                                            // Display an empty block or a placeholder if no multiple choice questions exist
                                                            <div className={`question-box-empty ${isGeneratingPDF ? 'no-print' : ''}`}>
                                                                <p>[A multiple choice question can be put here.]</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='section-question-2'>
                                                        {/* <h2>Question 2</h2> */}
                                                        {pages[currentPage]?.questions.filter(q => q.type === 'matching').length > 0 ? (
                                                            pages[currentPage]?.questions.map((question, index) => (
                                                                question.type === 'matching' && (
                                                                    <div className='question-box-lesson' key={index}>
                                                                        <div className={`ls-question-slot-title ${question.type === 'matching' ? 'matching-style-lesson' : ''}`}>
                                                                            <p>{question.text}</p>
                                                                        </div>
                                                                        <div className='ls-question-slot-options'>
                                                                            {<RenderMatchesLesson question={question} />}
                                                                        </div>
                                                                        <div style={{position: 'absolute', fontSize: '13px', top: '510px', marginLeft: '10px'}}>
                                                                            *Touch left and right option at the same time.
                                                                        </div>
                                                                        <img src={trythis} alt="Cannot Load Image" className="top-layer-image-2"/>
                                                                    </div>
                                                                )
                                                            ))
                                                        ) : (
                                                            <div className={`question-box-empty ${isGeneratingPDF ? 'no-print' : ''}`}>
                                                                <p>[A matching question can be put here.]</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='page-number-lesson'>
                                                    Page: {currentPage}
                                                </div>
                                            </div>
                                        )
                                )}
                               
                            </div>
                        </div>  
                    </Spin>          
                </div>
            </div>
        </div>
    );
});

export default PreviewLesson;