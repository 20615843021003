import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Modal, Button, notification } from 'antd';

const LessonAddImage = forwardRef((props, ref) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const fileInputRef = useRef(null);

    useImperativeHandle(ref, () => ({
        showModal
    }));

    const showModal = () => {
        setIsModalVisible(true);
        // console.log(props.pageId);
    };

    const handleLessonAddImage = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        event.stopPropagation(); // Stop event propagation
        if (fileSelected) {
            setIsModalVisible(false);
            try {
                const formData = new FormData();
                formData.append('image', fileInputRef.current.files[0]);
                const pageId = props.pageId;
                const uploadResponse = await fetch(`${process.env.REACT_APP_API_URL}/img/upload/${pageId}`, {
                    method: 'POST',
                    body: formData,
                });
                const data = await uploadResponse.json();
                if (!uploadResponse.ok) {
                    console.error('Error:', data);
                    notification.error({
                        message: 'Upload Failed',
                        description: 'Failed to upload image. Please try again.',
                    });
                } else {
                    props.onUpdateImageSrc(data.imgPath);
                    notification.success({
                        message: 'Image Uploaded',
                        description: 'Your image has been uploaded successfully.',
                    });
                }
                setFileSelected(false);
                resetInputFile();
            } catch (error) {
                console.error('Error adding image:', error);
                notification.error({
                    message: 'Upload Error',
                    description: 'An error occurred while uploading the image.',
                });
            }
        } else {
            setFileSelected(false);
            resetInputFile();
            notification.error({
                message: 'No File Selected',
                description: 'Please select a file to upload.',
            });
        }
    };

    const handleCancelImg = () => {
        setIsModalVisible(false);
    };

    const resetInputFile = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
            if (validTypes.includes(file.type)) {
                setFileSelected(true);
            } else {
                alert('Please select a valid image file!');
                setFileSelected(false);
                resetInputFile();
            }
        } else {
            setFileSelected(false);
        }
    };

    return (
        <div>
            <Modal
                title="Upload Image"
                open={isModalVisible}
                onCancel={handleCancelImg}
                footer={[
                    <Button key="back" onClick={handleCancelImg}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleLessonAddImage} disabled={!fileSelected}>
                        Add
                    </Button>,
                ]}
            >
                <input ref={fileInputRef} type="file" onChange={handleImageUpload} accept="image/*" />
            </Modal>
        </div>
    );
});

export default LessonAddImage;
