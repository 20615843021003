import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import './index.css';
import { Button, Input, Spin } from 'antd';
// import WebHeader from '../../components/header';
// import geminiImg from '../../assets/gemini.png';

const { TextArea } = Input;

const AIGenerateMC = forwardRef((props, ref)  => {
    const [inputValue, setInputValue] = useState('');
    const [outputValue, setOutputValue] = useState('');
    const [loading, setLoading] = useState(false);
    // const [mcNum, setMcNum] = useState('1');
    // const [tfNum, setTfNum] = useState('0');
    // const [matchingNum, setMatchingNum] = useState('0');
    // const [rankingNum, setRankingNum] = useState('0');
    useEffect(() => {
        // console.log(props.isAIMCModalVisible);
        setInputValue('');
        setOutputValue('');
        // document.title = "AI Generate Question";
    }, [props]);

    useImperativeHandle(ref, () => ({
        getSelectedQuestionDetails
    }));

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();  // Prevent the default action to avoid a newline in textarea
            submitRequest();
        }
    };    

    const handleInputChange = (e) => {
        setInputValue(e.target.value); // Update state with input value
    };

    const submitRequest = () => {
        if (inputValue.trim().length === 0) {
            alert('Plase input your request before generating question.');
            return;
        }
        setLoading(true);
        let aitext = inputValue + '.';
        aitext = aitext + 'Genearate 1 multiple choice question (question title must be within 148 characters) that does not use any programming language, with 4 choices (each choice is within 80 characters). The question is computer science related. And only one of them is correct. Explain each choice why they are right or wrong, with max 80 characters for each explanation, only include explanations. Use A, B, C, D for each choice and a, b, c, d for each explanation.';
        aitext = aitext + 'Must have answer.';
        // console.log(aitext);
        fetch(`${process.env.REACT_APP_API_URL}/api/aigenerate-question`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text: aitext })
        })
        .then(response => {
            if (!response.ok) { // Check if response is ok (i.e., status is 200-299)
                throw new Error('Network response was not ok: ' + response.statusText);
            }
            // console.log(response);
            return response.json(); // Converts the response to JSON
        })
        .then(data => {
            // console.log('Success:', data.text);
            setOutputValue(data.text);
            setLoading(false);
        })
        .catch((error) => console.error('Error:', error));

    };
    
    const getSelectedQuestionDetails = () => {
        if (!outputValue) {
            // alert('Please regenerate the question.');
            return;
        }
        // console.log("Output Value: ", outputValue);

        // Extract the question
        // const question_text = outputValue.match(/^.+?(?=\nA\.)/s)[0].trim();
        const question_text = outputValue.match(/^.+?(?=\nA\.)/s)[0].trim().replace(/\n/g, ' ') || "";
        // Extract options
        const options = outputValue.match(/^[A-E]\. .+?(?=\n|$)/gm).map(option => option.replace(/^[A-D]\. /, '')) || [];
        // Extract explanations
        const explanations = outputValue.match(/^[a-e]\. .+?(?=\n|$)/gm).map(option => option.replace(/^[a-d]\. /, '')) || [];

        const OptionA = options[0];
        const OptionB = options[1];
        const OptionC = options[2];
        const OptionD = options[3];
        // const OptionE = options[4];
        const ExplanationA = explanations[0];
        const ExplanationB = explanations[1];
        const ExplanationC = explanations[2];
        const ExplanationD = explanations[3];
        // const ExplanationE = explanations[4];
        return ([{
            questionTitle: question_text.length > 148 ? question_text.substring(0, 148) : question_text,
            choices: {
                A: OptionA.length > 80 ? OptionA.substring(0, 80) : OptionA,
                B: OptionB.length > 80 ? OptionB.substring(0, 80) : OptionB,
                C: OptionC.length > 80 ? OptionC.substring(0, 80) : OptionC,
                D: OptionD.length > 80 ? OptionD.substring(0, 80) : OptionD,
                // E: OptionE.length > 80 ? OptionE.substring(0, 80) : OptionE,
            },
            feedbacks: {
                comA: ExplanationA.length > 100 ? ExplanationA.substring(0, 100) : ExplanationA,
                comB: ExplanationB.length > 100 ? ExplanationB.substring(0, 100) : ExplanationB,
                comC: ExplanationC.length > 100 ? ExplanationC.substring(0, 100) : ExplanationC,
                comD: ExplanationD.length > 100 ? ExplanationD.substring(0, 100) : ExplanationD,
                // comE: ExplanationE.length > 100 ? ExplanationE.substring(0, 100) : ExplanationE,
            },
            // correctAnswer: q.CorrectAnswer
        }]);
    }

    const outputDisplay = () => {

        return outputValue;
    }


    return (
        <Spin spinning={loading} tip="processing..." size="large" >
        <div className='aipart-body-lesson'>
            <div style={{marginTop: '-30px'}}>
                *Powered by <strong>Google Gemini</strong>
            </div>
            <div className='aiquestion-body-lesson'>            
                <div className='aiquestion-input-lesson'>
                    <div className='aiquestion-input-right-lesson'>
                        <div className='aiquestion-input-box'>
                            <TextArea 
                                placeholder="Input here! Example: Generate question about loop"
                                autoSize={{ minRows: 4, maxRows: 4 }}
                                autoFocus 
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                style={{ width: '100%', borderRadius: '10px', fontSize: '18px' }}
                            />
                            <Button type="primary" onClick={submitRequest} style={{fontSize: '20px', marginTop: '23px', height: '40px', width: '100%'}}> Generate / Regenerate </Button>
                        </div>
                    </div>
                </div>
                <div className='aiquestion-output-bottom'>
                    
                    <div className='aiquestion-output-lesson'>
                        {/* <Spin spinning={loading} tip="processing..." size="large" > */}
                            {/* output here*/}
                            {outputDisplay()}
                        {/* </Spin> */}
                    </div>
                    
                </div>           
            </div>
        </div>
        </Spin>
    );
});

export default AIGenerateMC;