import React, { useState, useEffect, useRef, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Input, Button, List, Spin } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import './index.css'

import Header from '../../components/header'

import BreadCrumbs from '../../components/breadCrumbs';

const Quizzes = () => {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "My Quizzes";
        fetchQuizs();
    }, []);

    const [quizs, setQuizs] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newQuizTitle, setNewQuizTitle] = useState('');
    const [selectedQuiz, setSelectedQuiz] = useState(null);
    const [edit_title, setEditTitle] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef(null);

    const handleDataChange = (newData) => {
        setEditTitle(newData);
    };

    useEffect(() => {
        if (isModalVisible) {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 100);
        }
    }, [isModalVisible]);    

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancelQuiz = () => {
        setIsModalVisible(false);
        setNewQuizTitle('');
    };

    const handleEditClick = (e, quiz) => {
        e.stopPropagation();
        setEditingQuiz(quiz);
        setIsEditModalVisible(true);
        editQuizTitle(quiz);
    };

    // fetch quizs from the database
    const fetchQuizs = () => {
        setIsLoading(true);
        const userid = localStorage.getItem('userid');
        // console.log(userid);
        fetch(`${process.env.REACT_APP_API_URL}/api/users/quizs/${userid}`)
            .then(response => response.json())
            .then(data => {
                // console.log("Quizs fetched:", data);
                setQuizs(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Failed to fetch quizs:', error);
                setIsLoading(false); 
            });
    };

    // create new quiz
    const handleNewQuiz = () => {
        const userid = localStorage.getItem('userid');
        // const userid = localStorage.getItem('userid') || 'null';
        fetch(`${process.env.REACT_APP_API_URL}/api/quizs/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ quizTitle: newQuizTitle, userid: userid })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Could not add new quiz!');
            }
            return response.json();
        })
        .then(data => {
            if (data.success) {
                // alert(data.message); // 'Question added successfully!'
                setIsModalVisible(false);
                fetchQuizs(); // Refresh the list of quizs to show the new one
                // console.log(newQuizTitle);
                const newQuiz = {
                    id: data.QuizsID,
                    title: newQuizTitle,
                };
                setSelectedQuiz(newQuiz);
                setNewQuizTitle('');
                const userid = localStorage.getItem('userid');
                fetch(`${process.env.REACT_APP_API_URL}/api/users/quizs/${userid}`)
                    .then(response => response.json())
                    .then(data => {
                        setQuizs(data);
                        if (data.length > 0) {
                            // setSelectedQuiz(data[data.length - 1]);  // Select the last quiz
                        }
                    })
                    .catch(error => {
                        console.error('Failed to fetch quizs:', error);
                    });
            } else {
                throw new Error(data.message); // If success is false, throw an error to be caught by the catch block
            }
        })
        
        .catch((error) => {
            console.error('Error:', error);
        });
    };
    
    // delete a quiz

    const handleDeleteClick = (e, quizID, index) => {
        e.stopPropagation();
        handleDeleteQuiz(quizID, index);
    }

    const handleDeleteQuiz = async (quizID, index) => {
        Modal.confirm({
            title: 'Delte this quiz?',
            content: 'Delete this quiz will also delete all associated questions!',
            okText: 'Yes, delete it',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                // Fetch all questions for the quiz
                const deletedQuestions = await fetch(`${process.env.REACT_APP_API_URL}/api/questions/${quizID}`);
                if (!deletedQuestions.ok) throw new Error('Failed to fetch questions for deletion');

                const qs = await deletedQuestions.json();
                
                // Attempt to delete all questions
                for (const q of qs) {
                    const deleteQuestionResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/questions/${q.id}`, {
                        method: 'DELETE'
                    });
                    if (!deleteQuestionResponse.ok) throw new Error(`Failed to delete question ID ${q.id}`);
                }

                fetch(`${process.env.REACT_APP_API_URL}/api/quizs/${quizID}`, {
                    method: 'DELETE'
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete the quiz');
                    }
                    // Upon successful deletion, update the quizs array to remove the deleted quiz
                    setQuizs(prev => prev.filter((_, i) => i !== index));
                    // Reset selected quiz if it was the one deleted
                    if (selectedQuiz && quizID === selectedQuiz.LID) {
                        // console.log("equal");
                        setSelectedQuiz(null);
                    } else {
                        setSelectedQuiz(selectedQuiz);
                    }
                })
                .catch(error => console.error('Error deleting quiz:', error));
            },
            onCancel() {
                setSelectedQuiz(selectedQuiz);
            },
        });

    };

    // edit quiz title
    const [editingQuiz, setEditingQuiz] = useState(null); // To store the quiz currently being edited
    const [isEditModalVisible, setIsEditModalVisible] = useState(false); // To control the visibility of the edit modal
    const editQuizTitle = (quiz) => {
        setEditingQuiz({ id: quiz.LID, title: quiz.LTitle });
        setIsEditModalVisible(true);
    };
    const saveQuizTitle = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/quizs/update/${editingQuiz.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ quizTitle: editingQuiz.title })
        });
        if (response.ok) {
            setQuizs(quizs.map(quiz => quiz.LID === editingQuiz.id ? {...quiz, LTitle: editingQuiz.title} : quiz));
            setIsEditModalVisible(false);
            setEditingQuiz(null);
        } else {
            alert("Failed to update quiz title");
        }
        // console.log(selectedQuiz);
        setSelectedQuiz(selectedQuiz);
    };
    

    // display selected quizs
    const handleQuizClick = (quiz) => {
        // console.log("Quiz clicked:", quiz); // Debug log
        setSelectedQuiz(quiz);
        // console.log("Selected quiz state updated:", quiz); // Debug log
        navigate(`/quizzes/${quiz.LID}`);
    };

    const handleSave = () => {

    };

    return (
        <div>
            <Header />
            {/* <BreadCrumbs class="breadcrumbs" quizName={selectedQuiz ? selectedQuiz.LTitle : null} /> */}
            <div className='question-page-body'>
                <Modal
                    title="Create New Quiz"
                    open={isModalVisible}
                    onOk={handleNewQuiz}
                    onCancel={handleCancelQuiz}
                    footer={[
                        <Button key="back" onClick={handleCancelQuiz}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" onClick={handleNewQuiz} disabled={!newQuizTitle.trim()}>
                            Add
                        </Button>,
                    ]}
                >
                    <Input
                        ref={inputRef}
                        value={newQuizTitle}
                        onChange={e => setNewQuizTitle(e.target.value)}
                        placeholder="Enter quiz title"
                        onKeyDown={e => {
                            if (e.key === 'Enter' && newQuizTitle.trim()) {
                                handleNewQuiz();
                            }
                        }}
                    />
                </Modal>

                {/* QUIZZES */}
                <div className='quiz-list'>
                    <h3 style={{fontSize: '25px', marginBottom: '20px'}}>My Quizzes</h3>
                    <Spin spinning={isLoading}> 
                        <List
                            itemLayout="horizontal"
                            dataSource={quizs}
                            renderItem={(quiz, index) => (
                                <List.Item
                                    className={`quiz-item quiz-title ${selectedQuiz && selectedQuiz.LID === quiz.LID ? 'active' : ''}`}
                                    onClick={() => handleQuizClick(quiz)}
                                    actions={[
                                        <EditOutlined className="edit-quiz-title" style={{fontSize: '20px'}} onClick={(e) => {
                                            handleEditClick(e, quiz)
                                        }} />,
                                        <DeleteOutlined key="delete" style={{fontSize: '20px'}} onClick={(e) => handleDeleteClick(e, quiz.LID, index)} />,
                                    ]}
                                >
                                    <List.Item.Meta
                                        // title={`${quiz.LTitle}`}
                                        title={<span style={{ fontSize: '20px', fontWeight: 'normal' }}>{quiz.LTitle}</span>}
                                    />
                                </List.Item>
                            )}
                        />
                    </Spin>
                    
                    <Button type="primary" size="large" onClick={showModal}>Create New Quiz</Button>
                </div>

                <Modal
                    title="Edit Quiz Title"
                    open={isEditModalVisible}
                    onOk={saveQuizTitle}
                    onCancel={(e) => {
                        e.stopPropagation();
                        setIsEditModalVisible(false);
                    }}
                    footer={[
                        <Button key="back" onClick={(e) => {
                            e.stopPropagation(); // This stops the event from bubbling up to parent elements
                            setIsEditModalVisible(false);
                        }}>
                            Cancel
                        </Button>,
                        <Button key="save" type="primary" onClick={saveQuizTitle}>
                            Save
                        </Button>
                    ]}
                >
                    <Input
                        value={editingQuiz?.title}
                        onChange={(e) => setEditingQuiz({ ...editingQuiz, title: e.target.value })}
                        onClick={(e) => e.stopPropagation()}
                        onPressEnter={saveQuizTitle}
                    />
                </Modal>
            </div>
        </div>
    )
    };

export default Quizzes;