import React, { useState, useEffect } from 'react';
import './index.css';
import OptionShape from '../../components/optionShape';
import MatchShape from '../../components/matchShape';
// import { DeleteOutlined } from '@ant-design/icons';

const RenderMatches = ({ questionId }) => {
    const [matches, setMatches] = useState([]);
    // const [optionNum, setOptionNum] = useState(0);

    useEffect(() => {
        // Fetching matches based on questionId
        const fetchMatches = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/matches/${questionId}`);
            const results = await response.json();
            // console.log(results.data);
            if (response.ok) {
                if (results.data[0]) {
                    const { A, B, C, D, E, MatchA, MatchB, MatchC, MatchD, MatchE, OptionNum } = results.data[0];
                    // setOptionNum(OptionNum);
                    // console.log(OptionNum); // test use only
                    setMatches([A, B, C, D, E, MatchA, MatchB, MatchC, MatchD, MatchE]); // Store matches as an array
                    setMatches([
                        { option: A, match: MatchA },
                        { option: B, match: MatchB },
                        { option: C, match: MatchC },
                        { option: D, match: MatchD },
                        { option: E, match: MatchE }
                    ].slice(0, OptionNum));
                } else {
                    setMatches(["", "", "", "", "", "", "", "", "", ""]); // Default empty if no matches are found
                }
            } else {
                console.error('Failed to fetch matches:', results.message);
            }
        };
        fetchMatches();
    }, [questionId]);

    return (
        <div>
            {matches.map((match, index) => (
                <div key={index} className='render-mataches-list'> 
                    {/* <li className='match-opiton'> {<OptionShape content={match.option} />} </li> */}
                    <OptionShape content={match.option} />
                    {/* <li className='match-match'> {match.match} </li> */}
                    <MatchShape content={match.match} />
                </div> 
            ))}

        </div>
    );
};

export default RenderMatches;
