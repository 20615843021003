import React from 'react';
import Quizzes from '../pages/quizzes';
import Lessons from '../pages/lessons';
// import CreateLesson from '../pages/createLesson';
import MyCreateLesson from '../pages/mycreateLesson';
import Prereview from '../pages/prereview';
import Questionbank from '../pages/questionBank';
import Lessonbank from '../pages/lessonBank';
import AIQuestion from '../pages/aiQuestion';
import Home from '../pages/home';
import Login from '../pages/login';
import Signup from '../pages/signup';
import Questions from '../pages/questions';
import LessonBankPreview from '../pages/lessonBankPreview';
import Test from '../pages/test';
import Cookies from '../pages/cookies';

import { createBrowserRouter } from 'react-router-dom';


const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/lessons",
        element: <Lessons />,
    },
    {
        path: "/test",
        element: <Test />,
    },
    {
        path: "/cookies",
        element: <Cookies />,
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/signup",
        element: <Signup />,
    },
    {
        path: "/quizzes/:quizId",
        element: <Questions />,
    },
    {
        path: "/quizzes",
        element: <Quizzes />,
    },
    // {
    //     path: "/lessons",
    //     element: <Lessons />,
    // },
    {
        path: "/mycreatelesson/:LessonID/:EditState/:stepID",
        element: <MyCreateLesson />,
    },
    // {
    //     path: "/createlesson",
    //     element: <CreateLesson />,
    // },
    {
        path: "/prereview/:quizsid",
        element: <Prereview />,
    },
    {
        path: "/lessonbankpreview/:LessonID",
        element: <LessonBankPreview />,
    },
    {
        path: "/questionbank",
        element: <Questionbank />,
    },
    {
        path: "/questionbank",
        element: <Questionbank />,
    },
    {
        path: "/lessonbank",
        element: <Lessonbank />,
    },
    {
        path: "/aiquestion",
        element: <AIQuestion />,
    },
])

export default router