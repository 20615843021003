import './index.css'
import Header from "../../components/header";
import React, { useEffect } from 'react';
import tangibookImg from '../../assets/tangibook.png';
// import proceduresImg from '../../assets/procedures.png';
import proceduresImg from '../../assets/pro.svg';
import { Button } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Tangibook";
    }, []);

    const start = () => {
        navigate('/lessons');
    }


    return (
        <div className='body'>
            <div className='home-container'>
                <Header />
                {/* <h1 style={{paddingLeft: '30px'}}>Welcome to Tangibook</h1> */}
                <div className='tangibook-homepage'>     
                    <div className='tangibook-procedure-img'>
                        <img src={tangibookImg} alt="Cannot Load Image" className="tangibook-img"/>
                        <img src={proceduresImg} alt="Cannot Load Image" className="procedures-img"/>
                    </div>
                    <div className='tangibook-description'>
                        <div className='description'>
                            TEACH is a platform that uses paper-based tangibles with embedded electronics 
                            and augments paper with sensory interactions!
                        </div>
                        <div className='button-start'>
                            <Button type="primary" size="large" style={{fontSize: '30px', width: '280px', height: '70px'}} onClick={start} >Start From Here</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
