import React, { forwardRef, useImperativeHandle, useState, useRef, useEffect } from 'react';
import './index.css';
import { Button, Input, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
// import { OmitProps } from 'antd/es/transfer/ListBody';
import leesonmc_img from '../../assets/mc-top.jpg';
const { TextArea } = Input;


// multiple-choice question
// function LessonMC(onAddQuestion) {
const LessonMC = forwardRef((props, ref) => {
    const [questionText, setQuestionText] = useState('');
    const [choices, setChoices] = useState(Array(5).fill(""));
    const [messages, setMessages] = useState(Array(5).fill(""));
    const [optionNum, setOptionNum] = useState(2);
    const [correctAnswer, setCorrectAnswer] = useState(null);
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []); 

    useEffect(() => {
        if (!props.selectedQuestion || !props.selectedQuestion[0]) {
            return;
        }
        
        if (props.selectedQuestion) {
            // console.log("New question received:", props.selectedQuestion[0].choices);
            setQuestionText(props.selectedQuestion[0].questionTitle);
            // setOptionNum(4);
            const question = props.selectedQuestion[0];
            // console.log(question);
            const choicesArray = [
                question.choices.A,
                question.choices.B,
                question.choices.C,
                question.choices.D,
                question.choices.E
            ];
            setChoices(choicesArray);
            // Filter out empty or undefined choices and count the remaining valid choices
            const validChoicesCount = choicesArray.filter(choice => choice !== undefined && choice !== "").length;

            // Set the number of valid choices using the setOptionNum function
            setOptionNum(validChoicesCount);
            const msgArray = [
                question.feedbacks.comA,
                question.feedbacks.comB,
                question.feedbacks.comC,
                question.feedbacks.comD,
                question.feedbacks.comE
            ];
            setMessages(msgArray);
            
            // const updatedMessages = Array(5).fill("Wrong");
            // const correctIndex = question.correctAnswer.charCodeAt(0) - 'A'.charCodeAt(0); // Convert 'A', 'B', etc. to array index 0, 1, etc.
            // updatedMessages[correctIndex] = "Correct";
            // setMessages(updatedMessages);
        }
    }, [props.selectedQuestion]);
    

    useImperativeHandle(ref, () => ({
        submitQuestion
    }));

    const handleChange = (e) => {
        const newText = e.target.value;
        setQuestionText(newText);
    };

    const handleOptionChange = (index, value) => {
        const updatedOptions = [...choices];
        updatedOptions[index] = value;
        setChoices(updatedOptions);
    };
    
    const handleMessageChange = (index, value) => {
        const updatedMessages = [...messages];
        updatedMessages[index] = value;
        setMessages(updatedMessages);
    };
    

    // add options
    const addOption = () => {
        let num = optionNum + 1;
        setOptionNum(num);
    }

    // delete option
    const handleDeleteOption = (label, index) => {
        if (optionNum <= 2) {
            alert("Options cannot be less than 2, please add a new one before deleting");
            return;
        }
        if (label === correctAnswer) { 
            setCorrectAnswer(null);
            handleCorrectAnswerChange(null);
        } else {
            let answers = ['A', 'B', 'C', 'D', 'E'];
            for (let i = index; i < optionNum; i++) {
                if (answers[i] === correctAnswer) {
                    setCorrectAnswer(answers[i-1]);
                    handleCorrectAnswerChange(answers[i-1]);
                    break;
                }
            }
        }
        
        for (let i = index; i < optionNum; i++) {
            if (i === 4) {
                choices[i] = "";
                messages[i] = "";
            }else {
                choices[i] = choices[i+1];
                messages[i] = messages[i+1];
            }
        }
        let num = optionNum - 1;
        setOptionNum(num);
    };

    // correct answer for the question
    const handleCorrectAnswerChange = (label) => {
        setCorrectAnswer(label);
    };
    

    // display options
    const renderOptions = () => {
        return ['A', 'B', 'C', 'D', 'E'].map((label, index) => {
            if (index <= optionNum - 1) {
                // console.log(label);
                return (
                    <div className='options-list' key={label}>
                        <DeleteOutlined className='delete-option' style={{marginRight: '15px'}} onClick={() => handleDeleteOption(label, index)} />
                        {/* <input
                            type='radio'
                            name='correctAnswer'
                            checked={correctAnswer === label}
                            onChange={() => handleCorrectAnswerChange(label)}
                            style={{ marginRight: '5px' }}
                        /> */}
                        <div className='options-label'>
                            <label>{label}: </label>
                        </div>
                        <div className='options-content'>
                            <Input
                                type="text"
                                value={choices[index] || ''}
                                onChange={(e) => handleOptionChange(index, e.target.value)}
                                style={{ width: '95%', marginBottom: '10px' }}
                            />
                        </div>
                        {/* <div className='options-label'>
                        </div> */}
                        <div className='comment-content-'>
                            <Input
                                type="text"
                                value={messages[index] || ''}
                                onChange={(e) => handleMessageChange(index, e.target.value)}
                                style={{ width: '100%', marginBottom: '0px' }}
                            />
                        </div>
                        
                    </div>
                );
            } else {
                // Return null or an alternative component for empty or undefined choices
                return null;
            }
        });
    }

    const submitQuestion = async () => {
        if (!questionText) {
            alert("Question Title cannot be empty!");
            return false;
        } 
        for (let i = 0; i < optionNum; i++) {
            if (!choices[i] || !messages[i]) {
                alert("Please fill out all necessary inputs!");
                return false;
            }
        }
        props.setIsLoading(true);
        // console.log('page id: ', props.pageid);
        const questionData = {
            pageId: props.pageid,
            type: 'multiple_choice',
            text: questionText,
            choices: choices, 
            messages: messages,
            optionNum: optionNum,
            correctAnswer: correctAnswer
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/lesson/mcquestion/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(questionData)
            });
    
            if (!response.ok) {
                props.setIsLoading(false);
                throw new Error("Network response was not ok");
            }
    
            const data = await response.json();
            if (data.questionID) {
                const questionDetails = {
                    questionid: data.questionID,
                    type: 'multiple_choice',
                    text: questionText,
                    choices: choices.slice(0, optionNum),
                    messages: messages.slice(0, optionNum),
                    optionNum: optionNum,
                    correctAnswer: correctAnswer
                };
                props.onAddQuestion(questionDetails);
                notification.success({
                    message: 'Success',
                    description: 'Question added successfully!',
                    duration: 1.5
                });
                props.setIsLoading(false);
                props.setIsInterModalVisible(false);
                return true;
            } else {
                props.setIsLoading(false);
                throw new Error("Failed to add question");
            }
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            notification.error({
                message: 'Error',
                description: 'Error adding question: ' + error.message,
            });
            props.setIsLoading(false);
            return false;
        }
    };
    
    
    return (
        <div className='questionAndimage-lesson'>
            <div className='question-mc-lesson'>
                <div className='question-content-container'>
                    <div style={{marginTop: '25px', fontSize: '20px', fontWeight: 'bold'}}>
                        Question Title <span style={{ color: 'red' }}>*</span>
                    </div>
                    <TextArea
                        ref={inputRef}
                        type="text"
                        value={questionText}
                        placeholder="Question Title"
                        onChange={handleChange}
                        // onBlur={handleSave}
                        style={{ maxWidth: '100%', width: '100%', marginBottom: '10px', marginTop: '20px' }}
                        autoSize={{ minRows: 2, maxRows: 2 }} 
                        maxLength={148}
                    />
                </div>
                <div>
                    <div className='option-msg-title'>
                        <div className='option-msg-option-lesson'><strong>Options</strong><span style={{ color: 'red' }}>*</span></div>
                        <div className='option-msg-msg-lesson'><strong>Messages</strong><span style={{ color: 'red' }}>*</span></div>
                    </div>
                    {renderOptions()}
                    { optionNum < 5  && <Button type="primary" onClick={() => addOption()} style={{marginTop:'15px'}}>Add New Option</Button> }
                    {/* <p>* Clcik the radio to set the correct answer</p> */}
                </div>
            </div>
            <div className='image-lesson-mc'>
                <div style={{marginTop: '0px', marginBottom: '18px', padding: '6px', hyphens: 'auto', color: 'red'}}>
                    The Multiple Choice module allows you to select by touching one out of five options. Maxeaningful message will appear on the screen. (Max: <strong> 100 characters</strong>). 
                </div>
                <img src={leesonmc_img} alt="Cannot Load Image" />
            </div>
        </div>
    );
});

export default LessonMC;