import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { Button, Input} from 'antd';
// import { Button, Input, Menu, Dropdown} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { jsPlumb } from "jsplumb";
const { TextArea } = Input;
// true-false question
function MatchingDetails({ question, onDataChange, onSave }) {
    const [questionText, setQuestionText] = useState(question.text || '');
    const [isDirty, setIsDirty] = useState(false); // questiontext dirty state
    const [matches, setMatches] = useState([]);
    const [matchCon, setMatchCon] = useState([]);
    const [matchesDirty, setMatchesDirty] = useState(false);
    const [connDirty, setConnDirty] = useState(false);
    const [updateConn, setUpdateConn] = useState(false);
    const [optionNum, setOptionNum] = useState(0);
    const [optionNumDirty, setOptionNumDirty] = useState(false);
    const [showAddButton, setShowAddButton] = useState(false);
    const inputRef = useRef(null);


    useEffect(() => {
        inputRef.current?.focus();
    }, [question]); 

    useEffect(() => {
        // Save matches
        const saveMatches = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/matches/update/${question.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ matches })
            });
            const data = await response.json();
            if (data.success) {
                // console.log('Matches updated successfully'); // test use only
                setMatchesDirty(false); // Reset dirty state after successful save
            } else {
                console.error('Failed to update matches:', data.message);
            }
        };
        
        if (matchesDirty) {
            saveMatches();
            setMatchesDirty(false);
        }
    }, [question, matches, matchesDirty]);

    useEffect(() => {
        if (jsPlumbInstance.current) {
            jsPlumbInstance.current.repaintEverything();
        }
    }, [matches, matchCon, optionNum]);

    useEffect(() => {
        // update OptionNum value in the database
        const saveOptionNum = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/matches/updatenum/${question.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ optionNum })
            });
            const data = await response.json();
            if (!data.success) {
                console.error('Failed to update num:', data.message);
            }
        };
        if (optionNumDirty) {
            saveOptionNum();
            setOptionNumDirty(false);
        }
    }, [question, optionNum, optionNumDirty]);

    const handleChange = (e) => {
        // console.log(e.target.value); // test use only
        const newText = e.target.value;
        setQuestionText(newText);
        setIsDirty(true);
        // console.log(questionText);
        onDataChange(newText); 
    };

    // save question text
    const handleSave = async () => {
        if (isDirty) {
            await onSave(question.id, questionText); // Save changes
            setIsDirty(false); // Reset dirty state
        }
    };

    const handleMatchChange = (index, value) => {
        const updatedMatches = matches.map((match, i) => {
            if (i === index) {
                return value;
            }
            return match;
        });
        setMatches(updatedMatches);
        setMatchesDirty(true);
    };

    // delete match
    const handleDeleteMatch = (index) => {  
        if (optionNum <= 2) {
            alert("Options cannot be less than 2, please add a new one before deleting");
            return;
        }
        deleteConn(index);
        setUpdateConn(true);
        for (let j = 0; j <= optionNum; j++) {
            // console.log(j, matchCon[j]);
            if (matchCon[j] && (matchCon[j]) === String(index)) {
                // console.log(index, j, Number(matchCon[j]));
                deleteConn(j);
                // handleConnChange(j, null);
                setUpdateConn(true);
                break;
            }
        }      
        
        for (let i = index; i < optionNum; i++) {
            // console.log(`option index ${i}`);
            if (i === 4) {
                matches[i] = "";
                matches[i+5] = "";
            }else {
                matches[i] = matches[i+1];
                matches[i+5] = matches[i+1+5];
            }
            matches[i+10] = matches[i+1+10];
            // console.log(`option index ${matches[i+1]}`);
            if (i === optionNum - 1) {
                modifyConn(i, '9');  // 9 means NULL when backend processes it
                // setConnDirty(true);
            }
        }
        for (let j = 0; j < optionNum; j++) {
            // console.log(matchCon[j]);
            if (matchCon[j] && j < index && matchCon[j] > String(index)) {
                // console.log(matchCon[j]);
                modifyConn(j, String(matchCon[j]-1));
                setUpdateConn(true);
                // setConnDirty(true);
            } else if (matchCon[j] && j > index && matchCon[j] > String(index)) {
                modifyConn(j-1, String(matchCon[j]-1));
                setUpdateConn(true);
                modifyConn(j, '9');
            } else if (matchCon[j] && j > index && matchCon[j] < String(index)) {
                modifyConn(j-1, String(matchCon[j]));
                setUpdateConn(true);
                modifyConn(j, '9');
            }
        }
       
        setMatchesDirty(true);
        let num = optionNum - 1;
        setOptionNum(num);
        setOptionNumDirty(true);
        setUpdateConn(true);
        drawConnections();
    };

    const modifyConn = async (index, targetid) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/matches/modifyconn/${question.id}/${index}/${targetid}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify({ matchCon })
        });
        const data = await response.json();
        if (data.success) {
            // console.log('Matches updated successfully'); // test use only
            setConnDirty(false); // Reset dirty state after successful save
        } else {
            console.error('Failed to delete connection:', data.message);
        }
        // setConnDirty(true);
        setUpdateConn(true);
    }

    // add matches
    const addMatch = () => {
        let num = optionNum + 1;
        setOptionNum(num);
        setOptionNumDirty(true);
        setUpdateConn(true);
        // drawConnections();
    }

    const deleteConn = async (index) => {
        // console.log(index);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/matches/deleteconn/${question.id}/${index}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify({ matchCon })
        });
        const data = await response.json();
        if (data.success) {
            // console.log('Matches updated successfully'); // test use only
            setConnDirty(false); // Reset dirty state after successful save
        } else {
            console.error('Failed to delete connection:', data.message);
        }
        // setConnDirty(true);
        setUpdateConn(true);
    };

    // const menuRef = useRef(null);
    const jsPlumbInstance = useRef(null);
    // const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, connection: null }); // Initialized with default values    useEffect(() => {
    useEffect(() => {
        // jsPlumbInstance.current = jsPlumb.getInstance();
        jsPlumbInstance.current = jsPlumb.getInstance({
            DragOptions: { containment: 'parent' }
        });
        jsPlumbInstance.current.setContainer(document.getElementById('jsplumb-container'));

        jsPlumbInstance.current.bind("connection", function (info) {
            // console.log("Connected: " + info.sourceId + " to " + info.targetId);
        });

        // jsPlumbInstance.current.bind('contextmenu', function(connection, originalEvent) { //right click
        jsPlumbInstance.current.bind('click', function(connection, originalEvent) { // left click
            originalEvent.preventDefault();
            if (window.confirm('Delete connection?')) {
                let source_str = connection.sourceId.split("-");
                let num = source_str[0];
                deleteConn(Number(num));
                jsPlumbInstance.current.deleteConnection(connection); 
                
            }
        });

        return () => {
            jsPlumbInstance.current.deleteEveryConnection();
            jsPlumbInstance.current.reset();
            jsPlumbInstance.current.repaintEverything();
        };
    }, [question]);

    useEffect(() => {
        setQuestionText(question.text || '');
        setIsDirty(false);
        // setMatchCon(true);
        // setConnDirty(true);
    }, [question]);


    // fetch matches from the database
    useEffect(() => {
        const fetchMatches = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/matches/${question.id}`);
            const results = await response.json();
            // console.log(results.data);
            if (response.ok) {
                if (results.data[0]) {
                    const { A, B, C, D, E, MatchA, MatchB, MatchC, MatchD, MatchE, OptionNum, ComA, ComB, ComC, ComD, ComE, Defaultcom } = results.data[0];
                    setOptionNum(OptionNum);
                    // console.log(OptionNum); // test use only
                    setMatches([A, B, C, D, E, MatchA, MatchB, MatchC, MatchD, MatchE, ComA, ComB, ComC, ComD, ComE, Defaultcom]); // Store matches as an array
                } else {
                    setMatches(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]); // Default empty if no matches are found
                }
            } else {
                console.error('Failed to fetch matches:', results.message);
            }
        };

        fetchMatches();
        setTimeout(() => {
            setShowAddButton(true);
        }, 10);

    }, [question]); 

    // fetch match connections from the database
    useEffect(() => {
        const fetchMatchConnection = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/matches/connection/${question.id}`);
            const results = await response.json();
            // console.log(results.data);
            if (response.ok) {
                if (results.data[0]) {
                    const { AnsA, AnsB, AnsC, AnsD, AnsE } = results.data[0];
                    setMatchCon([AnsA, AnsB, AnsC, AnsD, AnsE ]);
                } else {
                    setMatchCon(["", "", "", "", ""]); // Default empty if no matches are found
                }
            } else {
                console.error('Failed to fetch match connection:', results.message);
            }
        };
        fetchMatchConnection();
        setUpdateConn(false);
    }, [question, updateConn, matches, connDirty]); 


    const drawConnections = () => {
        // setMatchCon(matchCon);
        if (jsPlumbInstance.current) {
            jsPlumbInstance.current.deleteEveryConnection();
            // jsPlumbInstance.current.repaintEverything();
            for (let ii = 0; ii < optionNum; ii ++) {
                if (matchCon[ii]) {
                    connectElements(String(ii), matchCon[ii]);
                    jsPlumbInstance.current.repaintEverything();
                }
            }
            jsPlumbInstance.current.repaintEverything(); // Ensure everything is correctly drawn
        }
        
    };

    useEffect(() => {
        drawConnections();
    }, [matchCon, matches, connDirty]); // Redraw whenever connections or matches change
    

    const handleDragStart = (e, id) => {
        e.dataTransfer.setData("draggedId", id);
    };

    const handleDrop = (e, targetId) => {
        e.preventDefault();
        const sourceId = e.dataTransfer.getData("draggedId");
        // console.log(sourceId, targetId);
        connectElements(String(sourceId), String(targetId));
        handleConnChange(Number(sourceId), Number(targetId));
    };



    const handleConnChange = (index, value) => {
        // console.log(matchCon);
        const updatedConn = matchCon.map((conn, i) => {
            if (i === index) {
                return value;
            }
            return conn;
        });
        // console.log(updatedConn);
        setMatchCon(updatedConn);
        setConnDirty(true);
        setUpdateConn(true);
    };

    // Save match connections
    useEffect(() => {
        const saveConn = async () => {
            // console.log(matchCon);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/matches/updateconn/${question.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ matchCon })
            });
            const data = await response.json();
            if (data.success) {
                // console.log('Matches updated successfully'); // test use only
                setConnDirty(false); // Reset dirty state after successful save
            } else {
                console.error('Failed to update connections:', data.message);
            }
        };
        
        if (connDirty) {
            saveConn();
            setConnDirty(false);
        }
    }, [ connDirty]);

    function debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
    
    var handleResize = debounce(function() {
        if (jsPlumbInstance.current) {
            // jsPlumbInstance.current.reset();
            jsPlumbInstance.current.repaintEverything();
            // jsPlumbInstance.current.reset();
        }
    }); 
    
    window.addEventListener('resize', handleResize);
    
    
    const connectElements = (sourceId, targetId) => {
        if (sourceId && targetId) {
            sourceId = `${sourceId}-left`;
            targetId = `${targetId}-right`;
            // Check if the connection already exists
            // console.log(targetId);
            const existingConnections = jsPlumbInstance.current.getConnections({
                source: sourceId,
                target: targetId
            });

            if (existingConnections.length === 0) {
                // Connect if no existing connection is found
                jsPlumbInstance.current.connect({
                    source: sourceId,
                    target: targetId,
                    anchors: ["Center", "Center"],
                    connector: ["Flowchart", { stub: [40, 60], gap: 10, cornerRadius: 5, alwaysRespectStubs: true }],
                    // endPoint: ['Dot'],
                    detachable: false,
                    connector: ["Straight", { curviness: 50 }],
                    paintStyle: { stroke: "black", strokeWidth: 6 }
                });
            }
        }
    };

    // display matches
    const renderMatches = () => {
        return ['A', 'B', 'C', 'D', 'E'].map((label, index) => {
            if (index <= optionNum - 1) {
                // console.log(index);
                return (
                    <div className='matches-list' key={label}>
                        <DeleteOutlined className='delete-match' style={{marginRight: '15px'}} onClick={() => handleDeleteMatch(index)}/>
                        <div className='matches-label'>
                            <label>{label}: </label>
                        </div>
                        <div className='matches-content'>
                            <Input
                                type="text"
                                value={matches[index] || ''}
                                onChange={(e) => handleMatchChange(index, e.target.value)}
                                style={{ width: '100%', marginBottom: '10px', marginRight: '10px' }}
                            />
                        </div>
                        <div 
                            id={`${index}-left`} 
                            className='draggable-circle'
                            draggable 
                            onDragStart={(e) => handleDragStart(e, index)}
                        />
                        <div 
                            id={`${index}-right`} 
                            className='droppable-circle'
                            onDrop={(e) => handleDrop(e, index)} 
                            onDragOver={(e) => e.preventDefault()}
                        />

                        <div className='matches-label'>
                            {/* <DroppableCircle id={`drop-${index}`} /> */}
                            <label>{label.toLowerCase()}: </label>
                        </div>
                        <div className='matches-content'>
                            <Input
                                type="text"
                                value={matches[index+5] || ''}
                                onChange={(e) => handleMatchChange(index+5, e.target.value)}
                                style={{ width: '100%', marginBottom: '10px' }}
                            />
                        </div>
                        <div className='match-msg-label'>
                            {/* MSG: */}
                        </div>
                        <div className='match-msg-content'>                 
                            <Input 
                                type="text" 
                                value={matches[index+10] || ''}
                                onChange={(e) => handleMatchChange(index+10, e.target.value)}
                                style={{ width: '100%', marginBottom: '0px' }}
                            />
                        </div>
                    </div>
                );
            } else {
                // Return null or an alternative component for empty or undefined choices
                return null;
            }
        });
    }

    return (
        <div>
            <TextArea
                ref={inputRef}
                type="text"
                value={questionText}
                placeholder="Question Title"
                onChange={handleChange}
                onBlur={handleSave}
                style={{ width: '100%', marginBottom: '10px', marginTop: '20px' }}
                autoSize={{ minRows: 1, maxRows: 5 }}
            />


            <div className='jsplumb-container' id='jsplumb-container'>
                <div className='option1-option2-msg-title'>
                    <div className='option1-option2-msg-option1'><strong>Options - Left</strong></div>
                    <div className='option1-option2-msg-option2'><strong>Options - Right</strong></div>
                    <div className='option1-option2-msg-msg'><strong>Messages</strong></div>
                </div>
                {renderMatches()}
                <div className='default-msg'>
                    <div className='default-msg-title'><strong>Default Message:</strong></div>
                    <div className='default-msg-content'>                 
                        <Input 
                            type="text" 
                            value={matches[15] || ''}
                            onChange={(e) => handleMatchChange(15, e.target.value)}
                            style={{ width: '100%', marginBottom: '0px' }}
                        />
                    </div>
                </div>
                {/* { optionNum < 5 && <button onClick={() => addMatch()}>Add New Matches</button> }                 */}
                { optionNum < 5 && showAddButton && <Button type="primary" onClick={() => addMatch()} style={{marginTop:'15px'}} >Add New Matches</Button> }    
                {/* <div style={{marginTop:'15px'}}>
                    Comments:

                </div> */}
                <div className='matching-question-comment'>
                    {/* <p>* Left click the connection to edit the comment</p> */}
                    <p>* Click and hold the left circle and drop it on the right circle to connect two options</p> 
                    <p>* Left click the line to delete it</p>   
                </div>        
            </div>

        </div>
    );
}

export default MatchingDetails;