import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Card, Spin, Pagination, Checkbox, Select, Input, Typography  } from 'antd';
const { Text } = Typography;

const { Option } = Select;

const QBankMatchingLesson = forwardRef((props, ref) => {
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const [questionsPerPage] = useState(2);

    useImperativeHandle(ref, () => ({
        getSelectedQuestionDetails
    }));

    useEffect(() => {
        fetchTopics();
        fetchQuestions();
    }, [selectedTopic]);

    const fetchTopics = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/qbankmatching/topics`);
        const result = await response.json();
        if (response.ok && result.topics) {
            setTopics(['All', ...result.topics]);
        } else {
            console.error('Failed to fetch topics:', result.message || 'Unknown error');
        }
    };

    const fetchQuestions = async () => {
        const url = `${process.env.REACT_APP_API_URL}/api/qbankmatching${selectedTopic !== 'All' ? `?topic=${selectedTopic}` : ''}`;
        const response = await fetch(url);
        const results = await response.json();
        if (response.ok) {
            setQuestions(results.data);
            // console.log(results.data);
        } else {
            console.error('Failed to fetch questions:', results.message);
        }
        setLoading(false);
    };

    const onMcCheckedQuestion = (qid) => {
        if (props.mcCheckedState[qid]) {
            props.setMcCheckedState({});
        } else {
            props.setMcCheckedState({
                [qid]: true
            });
        }
        // console.log(mcCheckedState); // {1: true, 2: false} 
    };

    const handleTopicChange = value => {
        setSelectedTopic(value);
        setCurrentPage(1);
    };

    // Get current questions to display
    const indexOfLastQuestion = currentPage * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const currentQuestions = questions.slice(indexOfFirstQuestion, indexOfLastQuestion);

    // Change page
    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
    };

    const getSelectedQuestionDetails = () => {
        const selectedQuestionIds = Object.keys(props.mcCheckedState).filter(id => props.mcCheckedState[id]);
        const selectedQuestions = questions.filter(q => selectedQuestionIds.includes(String(q.QID)));
    
        return selectedQuestions.map(q => ({
            questionTitle: q.QuestionText,
            options: {
                A: q.A,
                B: q.B,
                C: q.C,
                D: q.D,
                E: q.E
            },
            matches: {
                a: q.MatchA,
                b: q.MatchB,
                c: q.MatchC,
                d: q.MatchD,
                e: q.MatchE,
            },
            answers: {
                AnsA: q.AnsA,
                AnsB: q.AnsB,
                AnsC: q.AnsC,
                AnsD: q.AnsD,
                AnsE: q.AnsE,
            }
        }));
    };

    if (loading) {
        return <Spin />;
    }

    return (
        <div>
            <div className='question-display-header'>
                <p style={{fontSize: '1.2rem', width: '180px'}}><strong>Select Topic</strong></p>
                <Select defaultValue="All" style={{ width: 200 }} onChange={handleTopicChange}>
                    {topics.map(topic => (
                        <Option key={topic} value={topic}>{topic}</Option>
                    ))}
                </Select>
                <div className='question-pagination-lesson'>
                    <Pagination
                        current={currentPage}
                        total={questions.length}
                        pageSize={questionsPerPage}
                        onChange={paginate}
                        style={{ textAlign: 'right', margin: '15px 0' }}
                    />
                </div>
            </div>
            
            {currentQuestions.map((q) => (
                <div key={q.QID} className='question-box-matching-'>
                    <Checkbox checked={props.mcCheckedState[q.QID]} onChange={() => onMcCheckedQuestion(q.QID)} />
                    <Card 
                        onClick={() => onMcCheckedQuestion(q.QID)}
                        style={{ marginLeft: '25px', marginBottom: '16px', width: '95%', cursor: 'pointer' }}
                    >
                        <div className='question-text'>
                            <b>Question:</b> {q.QuestionText}
                        </div>
                        {['A', 'B', 'C', 'D', 'E'].map((letter) => (
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }} key={letter}>
                                <div style={{ marginRight: '10px', userSelect: 'none' }}>{`${letter}:`}</div>
                                <div style={{ flex: 1, border: '1px solid #d9d9d9', padding: '4px 11px', borderRadius: '2px', userSelect: 'none' }}>
                                    <Text>{q[letter]}</Text>
                                </div>
                                <div style={{ marginLeft: '50px', marginRight: '50px', userSelect: 'none' }}>{`${letter} - ${q[`Ans${letter}`]}`}</div>
                                <div style={{ marginRight: '10px', userSelect: 'none' }}>{`match ${letter.toLowerCase()}:`}</div>
                                <div style={{ flex: 1, border: '1px solid #d9d9d9', padding: '4px 11px', borderRadius: '2px', userSelect: 'none' }}>
                                    <Text>{q[`Match${letter}`]}</Text>
                                </div>
                            </div>
                        ))}
                    </Card>
                </div>
            ))}

            
            <div className='question-pagination-lesson'>
                <Pagination
                    current={currentPage}
                    total={questions.length}
                    pageSize={questionsPerPage}
                    onChange={paginate}
                    style={{ textAlign: 'right', margin: '15px 0' }}
                />
            </div>
        </div>
    );
});

export default QBankMatchingLesson;
