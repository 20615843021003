import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './index.css';

import MultipleChoiceDetails from '../multipleChoices';
import TrueFalseDetails from '../trueFalse';
import MatchingDetails from '../matching';
import RankingDetails from '../ranking';
import ObjectReplacementDetails from '../objectPlacement';

const QuestionDetail = ({ question, onDataChange, onSave}) => { 
    // console.log("Component rendered with question:", question);  // test use only
    return (
        <div>
            {(() => {
                switch (question.type) {
                    case 'multiple_choice':
                        return <MultipleChoiceDetails question={question} onDataChange={onDataChange} onSave={onSave}/>;
                    case 'true_false':
                        return (
                            <DndProvider backend={HTML5Backend}>
                                <TrueFalseDetails question={question} onDataChange={onDataChange} onSave={onSave}/>
                            </DndProvider>
                        );
                    case 'matching':
                        return <MatchingDetails question={question} onDataChange={onDataChange} onSave={onSave}/>;
                    case 'ranking':
                        return (
                            <DndProvider backend={HTML5Backend}>
                                <RankingDetails question={question} onDataChange={onDataChange} onSave={onSave}/>
                            </DndProvider>
                        );
                    case 'object_placement':
                        return <ObjectReplacementDetails question={question} onDataChange={onDataChange} onSave={onSave}/>;
                    default:
                        return <p>Add Questions</p>;
                }
            })()}
        
        </div>
    );
}

export default QuestionDetail;

