import React, { forwardRef, useImperativeHandle, useState, useRef, useEffect } from 'react';import './index.css';
import { Button, Input, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import leesonop_img from '../../assets/op-top.jpg';
const { TextArea } = Input;

// true-false question
const LessonOP = forwardRef((props, ref) => {
    const [questionText, setQuestionText] = useState('');
    const [optionNum, setOptionNum] = useState(2); 
    // const [showAddButton, setShowAddButton] = useState(false);
    const [tagMsgs, setTagMsgs] = useState(Array(20).fill(''));
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []); 


    const handleChange = (e) => {
        const newText = e.target.value;
        setQuestionText(newText);
    };

    useImperativeHandle(ref, () => ({
        submitQuestion
    }));

    const handleTagMsgChange = (index, value) => {
        const updatedTagMsgs = tagMsgs.map((match, i) => {
            if (i === index) {
                return value;
            }
            return match;
        });
        setTagMsgs(updatedTagMsgs);
    };


    // add new object placement
    const addObjectPlacement = () => {
        let num = optionNum + 1;
        setOptionNum(num);
    }

    // delte tag msg
    const handleDeleteTagMsg = (index) => {
        if (optionNum <= 1) {
            alert("Tag ID cannot be less than 1, please add a new one before deleting");
            return;
        }
        for (let i = index; i < optionNum; i++) {
            // console.log(`option index ${i}`);
            if (i === 9) {
                tagMsgs[i] = "";
                tagMsgs[i+10] = null;
            }else {
                tagMsgs[i] = tagMsgs[i+1];
                tagMsgs[i+10] = tagMsgs[i+1+10];
            }
            // console.log(`option index ${tagMsgs[i+1]}`);
        }
        let num = optionNum - 1;
        setOptionNum(num);
    }


    const submitQuestion = async () => {
        if (!questionText) {
            alert("Question Title cannot be empty!");
            return false;
        } 
        for (let i = 0; i < optionNum; i++) {
            if (!tagMsgs[i] || !tagMsgs[i+10]) {
                alert("Please fill out all necessary inputs!");
                return false;
            }
        }
        props.setIsLoading(true);
        const questionData = {
            pageId: props.pageid,
            type: 'object_placement',
            text: questionText,
            tagmsgs: tagMsgs,
            optionNum: optionNum
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/lesson/opquestion/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(questionData)
            });
    
            if (!response.ok) {
                props.setIsLoading(false);
                throw new Error('Network response was not ok');
            }  
            const data = await response.json();           
            if (data.questionID) {
                // Question added successfully
                const questionDetails = {
                    questionid: data.questionID,
                    type: 'object_placement',
                    text: questionText,
                    tagmsgs: tagMsgs,
                    optionNum: optionNum
                };
                props.onAddQuestion(questionDetails);
                // alert("Question added successfully.");
                notification.success({
                    message: 'Success',
                    description: 'Question added successfully!',
                    duration: 1.5, // Optional, in seconds
                });
                props.setIsLoading(false);
                props.setIsInterModalVisible(false);
                return true;
            } else {
                props.setIsLoading(false);
                throw new Error('Failed to add question!');
            }
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            notification.error({
                message: 'Error',
                description: 'Error adding question: ' + error.message,
            });
            props.setIsLoading(false);
            return false;
        }
    };
   
    const renderTagMsgs = () => {
        return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'].map((label, index) => {
            if (index <= optionNum - 1) {
                // console.log(index);
                return (
                    <div className='tagmsg-list' key={label}>
                        <DeleteOutlined className='delete-ranking' style={{marginRight: '15px'}}  onClick={() => handleDeleteTagMsg(index)} />
                        <div className='tagid-content-lesson'>
                            <Input
                                type="text"
                                value={tagMsgs[index] || ''}
                                onChange={(e) => handleTagMsgChange(index, e.target.value)}
                                style={{ width: '100%', marginBottom: '10px', marginRight: '10px' }}
                            />
                        </div>
  
                        <div className='msg-content-lesson'>
                            <Input 
                                type="text" 
                                value={tagMsgs[index+10] || ''}
                                onChange={(e) => handleTagMsgChange(index+10, e.target.value)}
                                // style={{ width: '10px', marginLeft: '8px' }}
                                style={{ width: '100%', marginLeft: '8px', marginRight: '12px' }}
                            />
                        </div>
                    </div>
                );
            } else {
                // Return null or an alternative component for empty or undefined choices
                return null;
            }
        });
    }

    return (
        <div className='questionAndimage-lesson'>
            <div className='question-op-lesson'>
                <div style={{marginTop: '25px', fontSize: '20px', fontWeight: 'bold'}}>
                    Question Title<span style={{ color: 'red' }}>*</span>
                </div>
                <TextArea
                    ref={inputRef}
                    type="text"
                    value={questionText}
                    placeholder="Question Title"
                    onChange={handleChange}
                    // onBlur={handleSave}
                    style={{ width: '100%', marginBottom: '10px', marginTop: '20px' }}
                    autoSize={{ minRows: 2, maxRows: 2 }}
                />
                <div className='object_placement_layout'>
                    <div className='op_tagid-lesson'>
                        <strong>Tag ID</strong><span style={{ color: 'red' }}>*</span>
                    </div>
                    <div className='op_msg-lesson'>
                        <strong>Messages</strong><span style={{ color: 'red' }}>*</span>
                    </div>
                </div>
                {renderTagMsgs()}
                { optionNum < 10 && <Button type="primary" onClick={() => addObjectPlacement()} style={{marginTop:'15px'}}>Add New Tag ID</Button> }
            </div>
            <div className='image-lesson-op'>
                <div style={{marginTop: '18px', marginBottom: '0px', width: '300px', color: 'red', padding: '6px', hyphens: 'auto'}}>
                    The Object Placment module allows you to put object on the designated area. Meaningful messages will appear on the screen. (Max: <strong> 100 characters</strong>).
                </div>
                <img src={leesonop_img} alt="Cannot Load Image" />
            </div>
        </div>
    );
});

export default LessonOP;