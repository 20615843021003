import React, { useState, useEffect, useRef } from 'react';
import './index.css';

const optionShape = ({ content }) => {

  return (
    // <div style={{ position: 'relative', height: '23px' }}>
    //     <svg width="120" height="40" viewBox="10 0 120 50">
    //         <circle cx="10" cy="25" r="10" fill="none" stroke="black" strokeWidth="1" />
    //         <line x1="20" y1="10" x2="140" y2="10" stroke="black" strokeWidth="1" /> {/* Top line */}
    //         <line x1="20" y1="40" x2="140" y2="40" stroke="black" strokeWidth="1" /> {/* Bottom line */}
    //         <line x1="140" y1="10" x2="140" y2="40" stroke="black" strokeWidth="1" /> {/* Right side */}
    //         <line x1="20" y1="10" x2="10" y2="15" stroke="black" strokeWidth="1" /> {/* Upper connection */}
    //         <line x1="20" y1="40" x2="10" y2="35" stroke="black" strokeWidth="1" /> {/* Lower connection */}
    //     </svg>
    //     <div style={{ position: 'absolute', fontSize: '13px', top: '10px', left: '23px', width: '80px', textAlign: 'left' }}>
    //         {content}
    //     </div>
    // </div>
    <div>
      <div className='matching-option-box'>
        <svg width="20" height="16" style={{ marginRight: '0px',  marginTop: '0px'}}>
            <circle cx="8" cy="8" r="7"  fill="white" stroke="black" strokeWidth="1"/>
        </svg>
        <div className='matching-option-content-'>
          {content}
        </div>
      </div>
    </div>
  );
};

export default optionShape;
