import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import './index.css';
import { Button, Input, notification, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import leesonmatching_img from '../../assets/matching-top.jpg';

const { TextArea } = Input;

const LessonMatching = forwardRef((props, ref) => {
    const { questionData = {}, handleUpdateQuestion, setIsLoading, setIsEditModalVisible } = props;
    const [questionText, setQuestionText] = useState('');
    const [questionID, setQuestionID] = useState('');
    const [matches, setMatches] = useState(Array(16).fill(""));
    const [matchCon, setMatchCon] = useState(Array(5).fill(""));
    const [optionNum, setOptionNum] = useState(2);
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const svgRef = useRef(null);
    // const connections = useRef([]);

    let matching_option_string;
    let matching_match_string;

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    useEffect(() => {
        if (questionData) {
            setQuestionID(questionData.questionid);
            setQuestionText(questionData.text);
            setMatches(questionData.matches);
            setMatchCon(questionData.matchCon);
            setOptionNum(questionData.optionNum); 
        }
    }, [questionData]);

    useImperativeHandle(ref, () => ({
        submitQuestion,
        cancelEdit
    }));

    const handleChange = (e) => {
        setQuestionText(e.target.value);
    };

    const handleMatchChange = (index, value) => {
        const updatedMatches = matches.map((match, i) => (i === index ? value : match));
        setMatches(updatedMatches);
    };

    const handleMatchConChange = (index, value) => {
        const allowedChars = 'abcde'.slice(0, optionNum);
        if (value.length <= 1 && allowedChars.includes(value.toLowerCase())) {
            const updatedMatchCon = matchCon.map((matchcon, i) => (i === index ? value : matchcon));
    
            // Check if the value already exists in matchCon
            if (matchCon.includes(value)) {
                const oldIndex = matchCon.indexOf(value);
                updatedMatchCon[oldIndex] = '';
            }
    
            setMatchCon(updatedMatchCon);
        }
    };

    const handleDeleteMatch = (index) => {
        if (optionNum <= 2) {
            alert("Options cannot be less than 2, please add a new one before deleting");
            return;
        }
        let updatedMatchCon = [...matchCon];
        updatedMatchCon[index] = '';
        for (let i = index; i < optionNum; i++) {
            if (i === 4) {
                matches[i] = "";
                matches[i + 5] = "";
                matches[i + 10] = "";
                updatedMatchCon[i] = "";
            } else {
                matches[i] = matches[i + 1];
                matches[i + 5] = matches[i + 1 + 5];
                matches[i + 10] = matches[i + 1 + 10];
                updatedMatchCon[i] = updatedMatchCon[i+1];
            }
            
        }
        const allowedChars = 'abcde'.slice(0, optionNum-1);
        setOptionNum(optionNum - 1);
        // console.log(allowedChars);
        const newMatchCon = updatedMatchCon.map((value) => (allowedChars.includes(value) ? value : ''));
        // console.log(newMatchCon);
        setMatchCon(newMatchCon);
    };

    const addMatch = () => {
        setOptionNum(optionNum + 1);
    };

    const renderMatches = () => {
        return ['A', 'B', 'C', 'D', 'E'].map((label, index) => {
            if (index < optionNum) {
                return (
                    <div className='lesson-matches-list' key={label}>
                        <DeleteOutlined className='delete-match' style={{ marginRight: '15px' }} onClick={() => handleDeleteMatch(index)} />
                        <div className='lesson-matches-label'><label>{label}: </label></div>
                        <div className='lesson-matches-content'>
                            <Input type="text" value={matches[index] || ''} onChange={(e) => handleMatchChange(index, e.target.value)} 
                                style={{ width: '100%', marginBottom: '10px', marginRight: '10px' }} maxLength={29}  // Limiting characters 
                            /> 
                        </div>
                        <div className='lesson-match'>
                            {/* {label} -  */}
                            <Input type="text" value={matchCon[index] || ''} onChange={(e) => handleMatchConChange(index, e.target.value)} 
                                style={{ width: '45px', marginLeft: '-10px'}} maxLength={29}
                            />
                        </div>
                        <div className='lesson-matches-label'><label>{label.toLowerCase()}: </label></div>
                        <div className='lesson-matches-content'>
                            <Input type="text" value={matches[index + 5] || ''} onChange={(e) => handleMatchChange(index + 5, e.target.value)} 
                                style={{ width: '100%', marginBottom: '10px' }} maxLength={29}  // Limiting characters 
                            />
                        </div>
                        <div className='lesson-match-msg-content'>
                            <Input type="text" value={matches[index + 10] || ''} onChange={(e) => handleMatchChange(index + 10, e.target.value)} style={{ width: '100%', marginBottom: '0px', marginLeft: '10px' }} />
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    const submitQuestion = async () => {
        if (!questionText) {
            alert("Question Title cannot be empty!");
            return false;
        }
        for (let i = 0; i < optionNum; i++) {
            if (!matches[i] || !matches[i+5] || !matches[i+10] || !matchCon[i]) {
                alert("Please fill out all necessary inputs!");
                return false;
            }
        }
        if (!matches[15]) {
            alert("Please fill out all necessary inputs!");
            return false;
        }
        setIsLoading(true);
        const questionData = {
            questionid: questionID,
            type: 'matching',
            text: questionText,
            matches: matches,
            matchCon: matchCon,
            optionNum: optionNum
        };
    
        const apiUrl = `${process.env.REACT_APP_API_URL}/lesson/updatematchingquestion`;
    
        try {
            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(questionData)
            });
    
            if (!response.ok) {
                setIsLoading(false);
                throw new Error('Failed to update the question');
            }
    
            const data = await response.json();
            notification.success({
                message: 'Success',
                description: 'Question updated successfully!',
            });
    
            const questionDetails = {
                questionid: questionID,
                type: 'matching',
                text: questionText,
                matches: matches,
                matchCon: matchCon,
                optionNum: optionNum
            };
    
            handleUpdateQuestion(questionDetails);
            setIsLoading(false);
            setIsEditModalVisible(false);
            return true;
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error.message || 'There was an error updating the question.',
            });
            setIsLoading(false);
            return false;
        }
    };
    

    const cancelEdit = () => {
        setQuestionText(questionData.text);
        setMatches(questionData.matches);
        setMatchCon(questionData.matchCon);
        setOptionNum(questionData.optionNum); 
    }

    const setNewData = (outputValue) => {
        // Extract the question
        const question_text = outputValue.match(/^.+?(?=\nA\.)/s)[0].trim().replace(/\n/g, ' ');
        const regex = /\s*(?:options|Options|options:|Options:)\s*$/;
        let filerted_question_text = question_text.replace(regex, '').trim();

        // Extract options
        const options = outputValue.match(/^[A-E]\. .+?(?=\n|$)/gm).map(option => option.replace(/^[A-E]\. /, ''));
        // Extract pairing options
        const pair_options = outputValue.match(/^[a-e]\. .+?(?=\n|$)/gm).map(option => option.replace(/^[a-e]\. /, ''));
        // const explanations = outputValue.match(/^[a-e]\. .+?(?=\n|$)/gm).map(option => option.replace(/^[a-e]\. /, ''));
        // Extract explanations and correct answers
        const regex_expla_ans = /\* (\w) - (\w):\s*(.+)/g;
        let match;
        const results = [];
        while ((match = regex_expla_ans.exec(outputValue)) !== null) {
            results.push({
                label: match[1],
                correctAnswer: match[2],
                explanation: match[3].trim()
            });
        }
        // console.log(results);

        const OptionA = options[0] || '';
        const OptionB = options[1] || '';
        const OptionC = options[2] || '';
        const OptionD = options[3] || '';
        const OptionE = options[4] || '';

        const pair_optiona =pair_options[0] || '';
        const pair_optionb =pair_options[1] || '';
        const pair_optionc =pair_options[2] || '';
        const pair_optiond =pair_options[3] || '';
        const pair_optione =pair_options[4] || '';

        let questionTitle = filerted_question_text.length > 99 ? filerted_question_text.substring(0, 99) : filerted_question_text

        setQuestionText(questionTitle);
        const validOptions = options.filter(option => option && option.trim() !== '');
        setOptionNum(validOptions.length);
      

        const options_ = {
            A: OptionA.length > 29 ? OptionA.substring(0, 29) : OptionA,
            B: OptionB.length > 29 ? OptionB.substring(0, 29) : OptionB,
            C: OptionC.length > 29 ? OptionC.substring(0, 29) : OptionC,
            D: OptionD.length > 29 ? OptionD.substring(0, 29) : OptionD,
            E: OptionE.length > 29 ? OptionE.substring(0, 29) : OptionE,
        };

        const matches_ = {
            a: pair_optiona.length > 29 ? pair_optiona.substring(0, 29) : pair_optiona,
            b: pair_optionb.length > 29 ? pair_optionb.substring(0, 29) : pair_optionb,
            c: pair_optionc.length > 29 ? pair_optionc.substring(0, 29) : pair_optionc,
            d: pair_optiond.length > 29 ? pair_optiond.substring(0, 29) : pair_optiond,
            e: pair_optione.length > 29 ? pair_optione.substring(0, 29) : pair_optione,
        };

        const answers_ = {
            AnsA: results[0].correctAnswer.length > 1? results[0].correctAnswer.substring(0, 1) : results[0].correctAnswer,
            AnsB: results[1].correctAnswer.length > 1? results[1].correctAnswer.substring(0, 1) : results[1].correctAnswer,
            AnsC: results[2].correctAnswer.length > 1? results[2].correctAnswer.substring(0, 1) : results[2].correctAnswer,
            AnsD: results[3].correctAnswer.length > 1? results[3].correctAnswer.substring(0, 1) : results[3].correctAnswer,
            AnsE: results[4].correctAnswer.length > 1? results[4].correctAnswer.substring(0, 1) : results[4].correctAnswer,
        };

        const explanations_ ={
            explA: results[0].explanation.length > 100? results[0].explanation.substring(0, 100) : results[0].explanation,
            explB: results[1].explanation.length > 100? results[1].explanation.substring(0, 100) : results[1].explanation,
            explC: results[2].explanation.length > 100? results[2].explanation.substring(0, 100) : results[2].explanation,
            explD: results[3].explanation.length > 100? results[3].explanation.substring(0, 100) : results[3].explanation,
            explE: results[4].explanation.length > 100? results[4].explanation.substring(0, 100) : results[4].explanation,
        };

        const updatedMatches = [
            options_.A, options_.B, options_.C,
            options_.D, options_.E,  
            matches_.a, matches_.b, matches_.c,
            matches_.d, matches_.e, 
            explanations_.explA, explanations_.explB, explanations_.explC,
            explanations_.explD, explanations_.explE, 'Wrong Match'   
        ];

        const updatedMatchCon = [
            answers_.AnsA, answers_.AnsB, answers_.AnsC,
            answers_.AnsD, answers_.AnsE
        ];
        setMatches(updatedMatches);
        setMatchCon(updatedMatchCon);

        setLoading(false);
        // console.log(choices);
    }

    const AIRegenerateMatching = () => {
        setLoading(true);
    
        let retryCount = 0;
        const maxRetries = 2; // Maximum number of retries
    
        const fetchData = () => {
            const matching_option = matches.slice(0, optionNum).map((item, index) => {
                if (item !== null) {
                  return `${String.fromCharCode(65 + index)}. ${item}`; // 65 is the char code for 'A'
                }
                return item;
              });

            matching_option_string = matching_option.filter(item => item !== null).join('; '); 

            // console.log(matching_option_string);

            const matching_match = matches.slice(5, 5+optionNum).map((item, index) => {
                if (item !== null) {
                  return `${String.fromCharCode(97 + index)}. ${item}`; // 97 is the char code for 'a'
                }
                return item;
              });

            matching_match_string = matching_match.filter(item => item !== null).join('; '); 

            let aitext = 'Genearate 1 matching question (question title must be within 95 characters) which is very similar to the question (same topic) - "' + questionText + '. ' + matching_option_string + '. ' + matching_match_string + '." ' + 'The question is computer science related, and it does not use any programming language. The question should include 5 different options (labeled A, B, C, D, E), each within 26 characters, that pair with 5 different corresponding options (labeled a, b, c, d, e), also within 26 characters. Every option must have different pairing options. The order of the options should be random. After creating the question, explain each pairing (labeled with *). Each explanation is within 100 characters. Two sets of options are in seperate area.';
            // console.log(aitext);
            fetch(`${process.env.REACT_APP_API_URL}/api/aigenerate-question`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text: aitext })
            })
            .then(response => {
                if (!response.ok) {
                    // throw new Error('Network response was not ok: ' + response.statusText);
                    if (retryCount < maxRetries) {
                        retryCount++;
                        console.log(`Retrying... Attempt ${retryCount}`);
                        fetchData(); // Retry the fetch
                    } else {
                        setLoading(false);
                        alert('Failed to generate question, please try again later.');
                    }
                }
                return response.json();
            })
            .then(data => {
                if (!data) {
                    // throw new Error("Failed to load question data.");
                    if (retryCount < maxRetries) {
                        retryCount++;
                        console.log(`Retrying... Attempt ${retryCount}`);
                        fetchData(); // Retry the fetch
                    } else {
                        setLoading(false);
                        alert('Failed to generate question, please try again later.');
                    }
                }
                setNewData(data.text);
            })
            .catch(error => {
                console.error("Error fetching question data: ", error);
                if (retryCount < maxRetries) {
                    retryCount++;
                    console.log(`Retrying... Attempt ${retryCount}`);
                    fetchData(); // Retry the fetch
                } else {
                    setLoading(false);
                    alert('Failed to generate question, please try again later.');
                }
            });
        };
    
        fetchData(); // Initial call to the fetch function
    };

    return (
        <Spin spinning={loading} tip="processing..." size="large" >
        <div className='questionAndimage-lesson'>
            <div className='question-matching-lesson'>
                <div style={{marginTop: '25px', fontSize: '20px', fontWeight: 'bold'}}>
                    Question Title<span style={{ color: 'red' }}>*</span>
                    <Button type="default" size="large" style={{marginLeft: '80px', backgroundColor: '#118f50', color: 'white', width: '12.5rem', marginTop: '8px'}} onClick={AIRegenerateMatching}>AI Regenerate Question</Button>
                    <div style={{fontSize: '13px', marginTop: '-30px', marginLeft: '440px', fontWeight: 'normal'}}>
                        *Powered by <strong>Google Gemini</strong>
                    </div>
                </div>
                <TextArea ref={inputRef} type="text" value={questionText} placeholder="Question Title" onChange={handleChange} style={{ width: '100%', marginBottom: '10px', marginTop: '20px' }}
                    autoSize={{ minRows: 2, maxRows: 2 }} maxLength={99}
                />
                <div className='lesson-jsplumb-container'>
                    <div className='lesson-option1-option2-msg-title'>
                        <div className='lesson-option1-option2-msg-option1'><strong>Options - Left</strong><span style={{ color: 'red' }}>*</span></div>
                        <div className='lesson-option1-option2-msg-match'><strong>Match</strong><span style={{ color: 'red' }}>*</span></div>
                        <div className='lesson-option1-option2-msg-option2'><strong>Options - Right</strong><span style={{ color: 'red' }}>*</span></div>
                        <div className='lesson-option1-option2-msg-msg'><strong>Messages</strong><span style={{ color: 'red' }}>*</span></div>
                    </div>
                    {renderMatches()}
                    <div className='default-msg'>
                        <div className='default-msg-title-'><strong>Default Message<span style={{ color: 'red' }}>*</span>:</strong></div>
                        <div className='lesson-default-msg-content'>
                            <Input type="text" value={matches[15] || ''} onChange={(e) => handleMatchChange(15, e.target.value)} style={{ width: '100%', marginBottom: '0px' }} />
                        </div>
                    </div>
                    {optionNum < 5 && <Button type="primary" onClick={addMatch} style={{ marginTop: '15px' }}>Add New Matches</Button>}
                    {/* <div className='matching-question-comment'>
                        <p>* Click and hold the left circle and drop it on the right circle to connect two options</p>
                        <p>* Left click the line to delete it</p>
                    </div> */}
                </div>
            </div>
            <div className='image-lesson-matching'>
                <div style={{marginTop: '18px', marginBottom: '0px', width: '300px', padding: '6px', hyphens: 'auto', color: 'red'}}>
                    The Matching module allows you to match options by touching two options (one each side). Meaningful message will appear on the screen. (Max: <strong> 100 characters</strong>).
                </div>
                <img src={leesonmatching_img} alt="Cannot Load Image" />
            </div>
        </div>
        </Spin>
    );
});

export default LessonMatching;