import React, { useState, useEffect } from 'react';
import './index.css';

const QBankMatching = () => {

    return (
        <div>
            coming
        </div>
    );
};

export default QBankMatching;