import React, { useState, useEffect, useRef } from 'react';
import { List, notification, Pagination, Spin, Button } from 'antd';
// import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import RenderOptionsLesson from '../../components/renderOptionsLesson';
import RenderMatchesLesson from '../../components/renderMatchesLesson';
import './index.css';
// import layout_img from '../../assets/layout.png';
// import { icons } from 'antd/es/image/PreviewGroup';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import trythis from '../../assets/trythis.png';
// import samplepage from '../../assets/samplepage.png';
import samplepage from '../../assets/samplepage.svg';
import Joyride from 'react-joyride';
import rfidpng from '../../assets/rfid.png';
// import Footer from '../../components/footer';

// const { Option } = Select;

function CustomTooltip({ content }) {
    return (
      <div dangerouslySetInnerHTML={{ __html: content }} />
    );
  }

const PreviewLessonBank = () => {
    const [run, setRun] = useState(true);
    const [pages, setPages] = useState([{ title: "Instruction Page", content: '', imgpath: '', questions: [], 
                                        lens1: '', lens2: '', lens3: '', lens4: '', gestid: '',
                                        }]);
    const [currentPage, setCurrentPage] = useState(0);
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [iterCount, setIterCount] = useState(0);
    // const [lessonInfoReady, setLessonInfoReady] = useState(false);
    const [lessonInfo, setLessonInfo] = useState({ title: '', description: '' });
    // const { LessonID } = props;  // Receive the LessonID from parent component
    const { LessonID } = useParams();
    const navigate = useNavigate();

    const totalPages = pages.length;
    const pageSize = 1;
    let iCount = 0;

    useEffect(() => {
        async function fetchData() {
            if (LessonID) {
                await fetchLessonInfo(LessonID);          
                fetchPagesForLesson(LessonID);
            }
        }
        fetchData();
        
    }, [LessonID]);

    const steps = [
        {
            target: 'body', 
            content: (
              <CustomTooltip content={`<img src="${samplepage}" alt="Example" style="width: 900px; height: auto;"/>`} />
            ),
            placement: 'center',
            disableBeacon: true,
            hideBackButton: true,
        },
    ];

    const itemRender = (current, type, originalElement) => {
        // if (type === 'page') {
        //   return <a>{current - 1}</a>; // Adjust the displayed number
        // }
        return originalElement; // For 'prev' and 'next' buttons, use default rendering
    };

    const fetchLessonInfo = async (lessonId) => {
        const url = `${process.env.REACT_APP_API_URL}/lessonTD/${lessonId}`;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setLessonInfo({
                    title: data.lessonTitle,
                    description: data.lessonDescrp
                });
            })
            .catch(error => {
                console.error('Failed to fetch lesson info:', error);
            });
    };

    const fetchPagesForLesson = (lessonId) => {
        setLoading(true);
        // const url = `${process.env.REACT_APP_API_URL}/lesson/getpages/${lessonId}`;
        // fetch(url)
        const url = `${process.env.REACT_APP_API_URL}/lesson/getpagesmodules/${lessonId}?timestamp=${new Date().getTime()}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        })
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                iCount = 0;
                const formattedPages = data.map(page => ({
                    title: page.title || "", 
                    content: page.content || "", 
                    content2: page.content2 || "",      
                    imgpath: page.imgPath || "",   
                    lens1: page.lens1 || "",
                    lens2: page.lens2 || "",
                    lens3: page.lens3 || "",
                    lens4: page.lens4 || "",   
                    gestid: page.gestid,
                    questions: page.questions.map(question => {
                        // Base question structure
                        const baseQuestion = {
                            id: question.QuestionID,
                            text: question.QuestionText,
                            type: question.QuestionType,
                            optionNum: question.details ? question.details.OptionNum : 0,
                        };
                        // Extend base structure based on type
                        if (question.QuestionType === 'multiple_choice') {
                            // let count = iterCount + 1;
                            // console.log('mc count:', count, iterCount);
                            // setIterCount(count);
                            iCount++;
                            // console.log(iCount);
                            return {
                                ...baseQuestion,
                                choices: question.details ? [
                                    question.details.A || null,
                                    question.details.B || null,
                                    question.details.C || null,
                                    question.details.D || null,
                                    question.details.E || null
                                ].filter(choice => choice !== null) : [],  // Filter out null values
                                correctAnswer: question.details ? question.details.CorrectAnswer : null,
                                messages: [
                                    question.details.ComA || null,
                                    question.details.ComB || null,
                                    question.details.ComC || null,
                                    question.details.ComD || null,
                                    question.details.ComE || null
                                ].filter(msg => msg !== null)  // Filter out null values
                            };
                        } else if (question.QuestionType === 'matching') {
                            // let count = iterCount + 1;
                            // console.log('matching count:', count, iterCount);
                            // setIterCount(count);
                            iCount++;
                            // console.log(iCount);
                            return {
                                ...baseQuestion,
                                matches: [
                                    question.details.A || null,
                                    question.details.B || null,
                                    question.details.C || null,
                                    question.details.D || null,
                                    question.details.E || null,
                                    question.details.MatchA || null,
                                    question.details.MatchB || null,
                                    question.details.MatchC || null,
                                    question.details.MatchD || null,
                                    question.details.MatchE || null,
                                    question.details.ComA || null,
                                    question.details.ComB || null,
                                    question.details.ComC || null,
                                    question.details.ComD || null,
                                    question.details.ComE || null,
                                    question.details.Defaultcom || null
                                ],
                                matchCon: [
                                    question.details.AnsA || null,
                                    question.details.AnsB || null,
                                    question.details.AnsC || null,
                                    question.details.AnsD || null,
                                    question.details.AnsE || null
                                ],
                            };
                        } else if (question.QuestionType === 'object_placement') {
                            // let count = iterCount + 1;
                            // console.log('op count:', count, iterCount);
                            // setIterCount(count);
                            iCount++;
                            // console.log(iCount);
                            return {
                                // ...baseQuestion,
                                ...baseQuestion,
                                tagmsgs: [
                                    question.details.TagID1 || null,
                                    question.details.TagID2 || null,
                                    question.details.TagID3 || null,
                                    question.details.TagID4 || null,
                                    question.details.TagID5 || null,
                                    question.details.TagID6 || null,
                                    question.details.TagID7 || null,
                                    question.details.TagID8 || null,
                                    question.details.TagID9 || null,
                                    question.details.TagID10 || null,
                                    question.details.Msg1 || null,
                                    question.details.Msg2 || null,
                                    question.details.Msg3 || null,
                                    question.details.Msg4 || null,
                                    question.details.Msg5 || null,
                                    question.details.Msg6 || null,
                                    question.details.Msg7 || null,
                                    question.details.Msg8 || null,
                                    question.details.Msg9 || null,
                                    question.details.Msg10 || null
                                ],
                            };
                        } else {
                            return baseQuestion;
                        }
                    })    
                }));
                // console.log('formattedpages:', formattedPages);
                setPages(formattedPages);
                // setPages([pages[0], ...formattedPages]);
                setIterCount(iCount);
                setLoading(false);
                setIsReady(true);
            })
            .catch(error => {
                console.error('Failed to fetch pages:', error);
                setLoading(false);  
            });
    };

    useEffect(() => {
        // Only set content if it exists and prevent setting undefined or null
        if (pages[currentPage] && pages[currentPage].content) {
            setContent(pages[currentPage].content);
        }
    }, [currentPage, pages]);

    const handlePageChange = (page) => {
        setCurrentPage(page - 1);
    };

    if (!isReady) { 
        return <Spin spinning={true} size="large" />;
    }

    const backToLessonBank = () => {
        // navigate('/lessonbank');
        navigate('/lessons');
    }

    // create new lesson
    const createNewLesson = () => {
        const userid = localStorage.getItem('userid');
        fetch(`${process.env.REACT_APP_API_URL}/importnewlesson/${userid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title: lessonInfo.title,
                description: lessonInfo.description
            })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to create lesson');
            }
            return response.json();
        })
        .then(data => {
            const LessonID = data.LessonID;
            // console.log('Lesson created with ID:', LessonID);
            console.log(pages);
            fetch(`${process.env.REACT_APP_API_URL}/lesson/addpages/${LessonID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(pages)
            })
            .then(response => {
                // console.log(response);
                if (!response.ok) {
                    throw new Error('Failed to save content');
                }
                navigate(`/mycreatelesson/${LessonID}/1/1`);
            })
            .catch(error => {
                console.error('Error:', error);
                throw error;
            });

            // navigate(`/mycreatelesson/${LessonID}/1/0`);
        })
        .catch(error => {
            console.error('Error creating lesson:', error);
            alert('Error creating lesson');
        });
    }

    const importLessonBank = () => {
        createNewLesson();
    }

    return (
        <div>
            <Header />
            <div className='lesson-bank-preview-container'>
                <div className='lessonbank-preview-header'>
                    <div className='lesson-bank-preview'>
                        Lesson Preview
                    </div>

                </div>

                <div className='lesson-preview-content'>
                    {/* <div className="first-step"> */}
                    <div className='lesson-preview-content-left'>
                        <List
                            className="lesson-preview-pages-list-container"
                            header={<div className='lesson-preview-content-left-title'>Pages</div>}
                            bordered
                            dataSource={pages.slice()}
                            renderItem={(item, index) => (
                                <List.Item 
                                    onClick={() => handlePageChange(index + 1)} 
                                    style={{ cursor: 'pointer', backgroundColor: currentPage === index ? '#cfefff' : '#fff' }}
                                >
                                    {item.title}
                                </List.Item>
                            )}
                            footer={
                                <div>
                                    <div style={{fontSize: '13px'}}><strong>This lesson has {pages.length} pages</strong></div>
                                </div>
                            }
                        />
                    </div>
                    {/* </div> */}
                    <div className='lesson-preview-content-right'>
                        <div className='prereview-body'>    
                            <Pagination
                                current={currentPage + 1}
                                onChange={handlePageChange}
                                total={totalPages * pageSize}
                                pageSize={pageSize}
                                showSizeChanger={false}
                                itemRender={itemRender}
                            />     
                            <Spin spinning={loading}>  
                                <div className='prereview-layout dashed-lines-horizontal dashed-lines-vertical'>
                                    <div id="divToPDF" className='prereview-pdf'>
                                        <div>
                                            <div className='page_identifier'>
                                                <img className='page_identifier_img' src={`/pageidentifier/page${ currentPage+1 }.svg`} alt="Cannot Load Img" />
                                            </div>
                                            <div className="prereview-body-container">
                                                <div className='section-content'>
                                                    {/* <h2>Content</h2> */}
                                                    <h2 style={{paddingLeft: '30px', margin: '0px', paddingBottom: '0px', paddingTop: '0px'}}>{pages[currentPage].title}</h2>
                                                    {/* <div dangerouslySetInnerHTML={{ __html: pages[currentPage]?.content }} /> */}
                                                    <ReactQuill
                                                        value={pages[currentPage]?.content}
                                                        readOnly={true}
                                                        theme="bubble" // Bubble theme for a clean read-only view
                                                        modules={{
                                                            toolbar: false // Disable toolbar
                                                        }}
                                                    />
                                                </div>
        
                                                <div className='section-2-preview'>
                                                    {/* <h2>Image</h2> */}
                                                    {pages[currentPage]?.imgpath && (
                                                        <div className='section-image'>
                                                            <img className='lesson-preview-img' src={`/images/${pages[currentPage].imgpath}`} alt="Cannot Load Img" />
                                                        </div>
                                                    )}
                                                    {!pages[currentPage]?.imgpath && pages[currentPage]?.content2 && (
                                                        <ReactQuill
                                                            value={pages[currentPage]?.content2}
                                                            readOnly={true}
                                                            theme="bubble" // Bubble theme for a clean read-only view
                                                            modules={{
                                                                toolbar: false // Disable toolbar
                                                            }}
                                                        />
                                                    )}
                                                    {pages[currentPage]?.questions[0] && pages[currentPage].questions[0].type === 'object_placement' && (
                                                        <div className='question-box-lesson-op'>
                                                            <div className={`ls-question-slot-title ${pages[currentPage].questions[0].type === 'matching' ? 'matching-style' : ''}`}>
                                                                <p>{pages[currentPage].questions[0].text}</p>
                                                            </div>
                                                            {/* Render question details here */}
                                                            <div className='ls-question-slot-options-op'>       
                                                                {/* <div className="object-placement-circle">
                                                                    Place Object Here
                                                                </div> */}
                                                                <img src={rfidpng} alt="Cannot Load Image" className="rfid-image"/>
                                                            </div>
                                                            <img src={trythis} alt="Cannot Load Image" className="top-layer-image-3"/>
                                                        </div>
                                                    )}
                                                    {pages[currentPage]?.questions[1] && pages[currentPage].questions[1].type === 'object_placement' && (
                                                        <div className='question-box-lesson-op'>
                                                            <div className={`ls-question-slot-title ${pages[currentPage].questions[1].type === 'matching' ? 'matching-style' : ''}`}>
                                                                <p>{pages[currentPage].questions[1].text}</p>
                                                            </div>
                                                            {/* Render question details here */}
                                                            <div className='ls-question-slot-options-op'>
                                                                {/* <div className="object-placement-circle">
                                                                    Place Object Here
                                                                </div>                                            */}
                                                                <img src={rfidpng} alt="Cannot Load Image" className="rfid-image"/>
                                                            </div>
                                                            <img src={trythis} alt="Cannot Load Image" className="top-layer-image-3"/>
                                                        </div>
                                                    )}
                                                    {pages[currentPage]?.questions[2] && pages[currentPage].questions[2].type === 'object_placement' && (
                                                        <div className='question-box-lesson-op'>
                                                            <div className={`ls-question-slot-title ${pages[currentPage].questions[2].type === 'matching' ? 'matching-style' : ''}`}>
                                                                <p>{pages[currentPage].questions[2].text}</p>
                                                            </div>
                                                            {/* Render question details here */}
                                                            <div className='ls-question-slot-options-op'>
                                                                {/* <div className="object-placement-circle">
                                                                    Place Object Here
                                                                </div>                              */}
                                                                <img src={rfidpng} alt="Cannot Load Image" className="rfid-image"/>
                                                            </div>
                                                            <img src={trythis} alt="Cannot Load Image" className="top-layer-image-3"/>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='section-question-1'>
                                                    {/* <h2>Question 1</h2> */}
                                                    {pages[currentPage]?.questions[0] && pages[currentPage].questions[0].type === 'multiple_choice' && (
                                                        <div className='question-box-lesson'>
                                                            <div className={`ls-question-slot-title ${pages[currentPage].questions[0].type === 'matching' ? 'matching-style' : ''}`}>
                                                                <p>{pages[currentPage].questions[0].text}</p>
                                                            </div>
                                                            {/* Render question details here */}
                                                            <div className='ls-question-slot-options'>
                                                                {<RenderOptionsLesson question={pages[currentPage].questions[0]} />}
                                                            </div>
                                                            <img src={trythis} alt="Cannot Load Image" className="top-layer-image"/>
                                                        </div>
                                                    )}
                                                    {pages[currentPage]?.questions[1] && pages[currentPage].questions[1].type === 'multiple_choice' && (
                                                        <div className='question-box-lesson'>
                                                            <div className={`ls-question-slot-title ${pages[currentPage].questions[1].type === 'matching' ? 'matching-style' : ''}`}>
                                                                <p>{pages[currentPage].questions[1].text}</p>
                                                            </div>
                                                            {/* Render question details here */}
                                                            <div className='ls-question-slot-options'>
                                                                {<RenderOptionsLesson question={pages[currentPage].questions[1]} />}
                                                            </div>
                                                            <img src={trythis} alt="Cannot Load Image" className="top-layer-image"/>
                                                        </div>
                                                    )}
                                                    {pages[currentPage]?.questions[2]  && pages[currentPage].questions[2].type === 'multiple_choice' && (
                                                        <div className='question-box-lesson'>
                                                            <div className={`ls-question-slot-title ${pages[currentPage].questions[2].type === 'matching' ? 'matching-style' : ''}`}>
                                                                <p>{pages[currentPage].questions[2].text}</p>
                                                            </div>
                                                            {/* Render question details here */}
                                                            <div className='ls-question-slot-options'>
                                                                {<RenderOptionsLesson question={pages[currentPage].questions[2]} />}
                                                            </div>
                                                            <img src={trythis} alt="Cannot Load Image" className="top-layer-image"/>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='section-question-2'>
                                                    {/* <h2>Question 2</h2> */}
                                                    {pages[currentPage]?.questions[0] && pages[currentPage].questions[0].type === 'matching' && (
                                                        <div className='question-box-lesson'>
                                                            <div className={`ls-question-slot-title ${pages[currentPage].questions[0].type === 'matching' ? 'matching-style' : ''}`}>
                                                                <p>{pages[currentPage].questions[0].text}</p>
                                                            </div>
                                                            {/* Render question details here */}
                                                            <div className='ls-question-slot-options'>
                                                                {/* {pages[currentPage].questions[1].type === 'multiple_choice' && <RenderOptionsLesson question={pages[currentPage].questions[1]} />} */}
                                                                {<RenderMatchesLesson question={pages[currentPage].questions[0]} />}
                                                                {/* {pages[currentPage].questions[1].type === 'object_placement' &&        
                                                                    <div className="object-placement-circle">
                                                                        Place Object Here
                                                                    </div>} */}
                                                            </div>
                                                            <img src={trythis} alt="Cannot Load Image" className="top-layer-image-2"/>
                                                        </div>
                                                    )}
                                                    {pages[currentPage]?.questions[1] && pages[currentPage].questions[1].type === 'matching' && (
                                                        <div className='question-box-lesson'>
                                                            <div className={`ls-question-slot-title ${pages[currentPage].questions[1].type === 'matching' ? 'matching-style' : ''}`}>
                                                                <p>{pages[currentPage].questions[1].text}</p>
                                                            </div>
                                                            {/* Render question details here */}
                                                            <div className='ls-question-slot-options'>
                                                                {<RenderMatchesLesson question={pages[currentPage].questions[1]} />}                                            
                                                            </div>
                                                            <img src={trythis} alt="Cannot Load Image" className="top-layer-image-2"/>
                                                        </div>
                                                    )}
                                                    {pages[currentPage]?.questions[2] && pages[currentPage].questions[2].type === 'matching' && (
                                                        <div className='question-box-lesson'>
                                                            <div className={`ls-question-slot-title ${pages[currentPage].questions[2].type === 'matching' ? 'matching-style' : ''}`}>
                                                                <p>{pages[currentPage].questions[2].text}</p>
                                                            </div>
                                                            {/* Render question details here */}
                                                            <div className='ls-question-slot-options'>
                                                                {<RenderMatchesLesson question={pages[currentPage].questions[2]} />}                              
                                                            </div>
                                                            <img src={trythis} alt="Cannot Load Image" className="top-layer-image-2"/>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='page-number-lesson'>
                                                Page: {currentPage+1}
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>  
                            </Spin>          
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', minWidth: '1400px'}}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', marginBottom: '40px' }}>
                        <Button type="primary" size="large" style={{ width: '6.5rem' }} onClick={backToLessonBank}>Back</Button>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent:  'center' }}>                    
                            <div style={{color: 'red', fontSize: '1.15rem'}}>
                                Please review the lesson and its page content. If you like it, please click on <strong>"Import"</strong> button.
                            </div>
                        </div>
                        <Button className='import-lesson' type="primary" size="large" style={{ width: '6.5rem' }} onClick={importLessonBank}>Import</Button>
                    </div>
                </div>
                <Joyride
                    hideCloseButton={true}
                    continuous={true}
                    run={run}
                    steps={steps}
                    showProgress={false}
                    styles={{
                        options: {
                            zIndex: 10000,
                            // overlayColor: 'transparent', // Makes the overlay invisible
                            // overlayColor: 'rgba(55, 55, 55, 1)', // Light semi-transparent overlay
                            spotlightPadding: 0,
                            spotlightBackground: 'transparent', // Removes the spotlight effect
                        },
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            width: '100vw',
                            height: '100vh',
                        },
                        tooltip: {
                            maxWidth: 'none', // Ensure the tooltip can expand to necessary width
                            width: 'auto', // Auto-adjust width
                            minWidth: '350px' // Set a minimum width for the tooltip
                        },
                        buttonSkip: {
                            border: '1px solid #000', // Adds a black border to the skip button
                            padding: '8px 10px', // Increases padding for better appearance
                            backgroundColor: '#FF0000', // Changes the background color to red
                            borderRadius: '5px',
                            color: 'white' ,
                            fontSize: '14px'
                        },
                        buttonNext: {
                            backgroundColor: 'green', // Set the Next button to green
                            width: '100px',
                            color: 'white' // Optional: set text color to white for better visibility
                        },
                    }}
                    spotlightPadding={0}
                    disableOverlayClose={true}
                    locale={{ last: 'OK'}}
                />

            {/* <Footer /> */}
            </div>

        </div>
    );
}

export default PreviewLessonBank;
