import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Pagination, Spin } from 'antd'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './index.css';
import Header from '../../components/header';
import RenderOptions from '../../components/renderOptions';
import RenderMatches from '../../components/renderMatches';
import RenderTrueFalse from '../../components/renderTrueFalse';


const Prereview = () => {
    const { quizsid } = useParams();
    const [questions, setQuestions] = useState([]);
    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 3; // Display two questions per page
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = "Pre-review Quiz";
        // console.log(quizsid); //test use only
        const fetchQuestions = () => {
            fetch(`${process.env.REACT_APP_API_URL}/api/questions/${quizsid}`)
                .then(response => response.json())
                .then(data => {
                    const qs = Array.isArray(data) ? data : [];
                    const mcQ = qs.filter(q => q.type === 'multiple_choice');
                    // setMcQuestions(mcQ);
                    const matchingQ = qs.filter(q => q.type === 'matching');
                    // setMatchingQuestions(matchingQ);
                    const opQ = qs.filter(q => q.type === 'object_placement');
                    let i = 0;
                    let j = 0;
                    let k = 0;
                    const qs_list = [];
                    while (i < mcQ.length || j < matchingQ.length || k < opQ.length) {
                        if (i < mcQ.length) {
                            qs_list.push(mcQ[i]);
                            // console.log(mcQ[i]);
                            i++;
                        } else {
                            qs_list.push({id: -1, text: null, type: null, imgpath: null});
                        }
                        if (j < matchingQ.length) {
                            qs_list.push(matchingQ[j]);
                            j++;
                        } else {
                            qs_list.push({id: -1, text: null, type: null, imgpath: null});
                        }
                        if (k < opQ.length) {
                            qs_list.push(opQ[k]);
                            k++;
                        } else {
                            qs_list.push({id: -1, text: null, type: null, imgpath: null});
                        }
                    }
                    setQuestions(qs_list);
                    // setQuestions(qs);
                    // console.log(matchingQuestions);
                    // setQuestions(Array.isArray(data) ? data : []);
                    // console.log(data);
                })                
                .catch(error => console.error('Failed to fetch questions:', error));
        };
        fetchQuestions();
    }, [quizsid]);

    useEffect(() => {
        questionsIntoPages(questions);
    }, [questions]);


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Calculate the number of pages
    const totalPages = Math.ceil(questions.length / pageSize);
    // Get current page questions
    const currentQuestions = questions.slice((currentPage - 1) * pageSize, currentPage * pageSize);   

    // put questions into pages
    const questionsIntoPages = (questions) => {
        let newPages = [];
        for (let i = 0; i < questions.length; i += pageSize) {
            newPages.push(questions.slice(i, i + pageSize));
        }
        // console.log(newPages);
        setPages(newPages);
    };

    // display question type
    const getQuestionType = (type) => {
        switch(type) {
            case 'multiple_choice':
                return 'Multiple Choice';
            case 'true_false':
                return 'True / False';
            case 'matching':
                return 'Matching';
            case 'ranking':
                return 'Ranking';
            case 'object_placement':
                return 'Object Placement';
            default:
                break;
        }
    }; 

    // generate csv
    const generateCSV = async () => {
        // console.log("generate csv");
        for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
            const csv_name = 'page' + (pageIndex+1);
            var list_data = [];
            for (let questionIndex = 0; questionIndex < pages[pageIndex].length; questionIndex++) {
                var question_data = [];
                question_data.push(pages[pageIndex][questionIndex].type);
                question_data.push(pages[pageIndex][questionIndex].id);
                question_data.push(questionIndex+1); // position of question on the page
                list_data.push(question_data);
            }
            const csv_data = {
                name: csv_name,
                data: list_data,
            };
            fetch(`${process.env.REACT_APP_API_URL}/api/generate-csv`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(csv_data)
            })
            .then(response => response.json())
            // .then(data => console.log('Success:', data))
            .catch((error) => console.error('Error:', error));
            await new Promise(resolve => setTimeout(resolve, 1500));
        }
        
    };

    // update tangibook
    const updateTangibook = async () => {
        // alert("Message Updated");
        // console.log("generate csv");
        for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
            const csv_name = 'page' + (pageIndex+1);
            var list_data = [];
            for (let questionIndex = 0; questionIndex < pages[pageIndex].length; questionIndex++) {
                var question_data = [];
                question_data.push(pages[pageIndex][questionIndex].type);
                question_data.push(pages[pageIndex][questionIndex].id);
                question_data.push(questionIndex+1); // position of question on the page
                list_data.push(question_data);
            }
            const csv_data = {
                name: csv_name,
                data: list_data,
            };
            // console.log(csv_data);
            fetch(`${process.env.REACT_APP_API_URL}/api/generate-csv`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(csv_data)
            })
            .then(response => response.json())
            // .then(data => console.log('Success:', data))
            .catch((error) => console.error('Error:', error));
            await new Promise(resolve => setTimeout(resolve, 1500));
        }
    };

    // save as pdf
    const saveAsPdf = async () => {
        setLoading(true);
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            // format: 'a4'
            format: 'letter'
            // format: [297, 210]
        });

    
        for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
            if (pageIndex > 0) {
                pdf.addPage();
            }
            // console.log(pages[pageIndex]);
            
            setCurrentPage(pageIndex + 1);  // Update the current page to render the next set of questions
            await new Promise(resolve => setTimeout(resolve, 700)); // Wait for the state to update and render
    
            const input = document.getElementById('divToPDF');
            const canvas = await html2canvas(input, {
                scale: 5,
                useCORS: true
            });
    
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgHeight = canvas.height * pdfWidth / canvas.width; 
            if (imgHeight > pdfHeight) {
                pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
            } else {
                pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, imgHeight);
            }
        }

        var date = new Date();
        let fileName = ( date.getFullYear() + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2) + ("0" + date.getHours() ).slice(-2) + ("0" + date.getMinutes()).slice(-2) + ("0" + date.getSeconds()).slice(-2) );
        
        pdf.save(`${fileName}.pdf`);
        setLoading(false);
        generateCSV();
    };

    let displayedQuestionNumber = 0;
    return (
        <div>
            <Header />
            <div className='prereview-container'>
                <div className='prereview-side'>
                    <Button type="primary" size="large" onClick={saveAsPdf}>Finalize</Button>
                    {/* <Button type="primary" onClick={updateTangibook} style={{marginLeft: '50px'}}>Update Tangibook</Button> */}
                    <h3>Questions</h3>
                    {/* <p>Drag the question to the area where you want to place it (on the right).</p> */}
                    
                    {questions.map((question, index) => (
                        question.id !== -1 && (
                        ++displayedQuestionNumber,
                        <div 
                            className='question-item-prereivew'
                            key={question.id}
                            // draggable
                            // onDragStart={(event) => onDragStart(event, question)}
                        >
                            <div className={`question-title-prereview unselectable`}>
                                {displayedQuestionNumber}. {question.text}
                                <div className='question-type'>
                                    {getQuestionType(question.type)}
                                </div>
                            </div>
                            {/* {question.id}   */}
                        </div>)
                    ))}
                </div>

                <div className='prereview-body'>    
                    <Pagination
                        current={currentPage}
                        onChange={handlePageChange}
                        total={totalPages * pageSize}
                        pageSize={pageSize}
                        showSizeChanger={false}
                    />     
                    <Spin spinning={loading}>  
                    <div className='prereview-layout'>
                        <div id="divToPDF" className='prereview-pdf' >
                            <div className='page_identifier'>
                                <img className='page_identifier_img' src={`/pageidentifier/page${currentPage}.svg`} alt="Cannot Load Img" />
                                {/* {currentPage} */}
                            </div>
                            <div className="prereview-body-container">
                                {/* {currentQuestions.map((question, index) => (
                                    <div key={question.id} className='page'>
                                        <div className='question-slot unselectable'>
                                            <div className={`question-slot-title ${question.type === 'matching' ? 'matching-style' : ''}`}>
                                                {question.text}
                                            </div>

                                            <div className='question-slot-options'>
                                                {question.type === 'multiple_choice' && <RenderOptions question={question} />}
                                                {question.type === 'matching' && <RenderMatches questionId={question.id} />}
                                                {question.type === 'true_false' && <RenderTrueFalse question={question} />}
                                            </div>
                                        </div>
                                    </div>
                                ))} */}
                                {currentQuestions.length > 0 && (
                                    <div className="block-large">
                                        <div key={currentQuestions[0].id} className="question-slot unselectable">
                                            <div className={`question-slot-title ${currentQuestions[0].type === 'matching' ? 'matching-style' : ''}`}>
                                                {currentQuestions[0].text}
                                            </div>
                                            <div className='question-slot-options'>
                                                {currentQuestions[0].type === 'multiple_choice' && <RenderOptions question={currentQuestions[0]} />}
                                                {currentQuestions[0].type === 'matching' && <RenderMatches questionId={currentQuestions[0].id} />}
                                                {currentQuestions[0].type === 'true_false' && <RenderTrueFalse question={currentQuestions[0]} />}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {currentQuestions.length > 1 && (
                                    <div className="block-small">
                                        <div key={currentQuestions[1].id} className="question-slot unselectable">
                                            <div className={`question-slot-title ${currentQuestions[1].type === 'matching' ? 'matching-style' : ''}`}>
                                                {currentQuestions[1].text}
                                            </div>
                                            <div className='question-slot-options'>
                                                {currentQuestions[1].type === 'multiple_choice' && <RenderOptions question={currentQuestions[1]} />}
                                                {currentQuestions[1].type === 'matching' && <RenderMatches questionId={currentQuestions[1].id} />}
                                                {currentQuestions[1].type === 'true_false' && <RenderTrueFalse question={currentQuestions[1]} />}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {currentQuestions.length > 2 && (
                                    <div className="block-small">
                                        <div key={currentQuestions[2].id} className="question-slot unselectable">
                                            <div className={`question-slot-title ${currentQuestions[2].type === 'matching' ? 'matching-style' : ''}`}>
                                                {currentQuestions[2].text}
                                            </div>
                                            <div className='question-slot-options'>
                                                {currentQuestions[2].type === 'multiple_choice' && <RenderOptions question={currentQuestions[2]} />}
                                                {currentQuestions[2].type === 'matching' && <RenderMatches questionId={currentQuestions[2].id} />}
                                                {currentQuestions[2].type === 'true_false' && <RenderTrueFalse question={currentQuestions[2]} />}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>  
                    </Spin>          
                </div>
            </div>
        </div>
    );
};

export default Prereview;
