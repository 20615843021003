import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import './index.css';
import { Button, Input, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import leesonmatching_img from '../../assets/matching-top.jpg';

const { TextArea } = Input;

const LessonMatching = forwardRef((props, ref) => {
    const [questionText, setQuestionText] = useState('');
    const [matches, setMatches] = useState(Array(16).fill(""));
    const [matchCon, setMatchCon] = useState(Array(5).fill(""));
    const [optionNum, setOptionNum] = useState(2);
    const inputRef = useRef(null);
    const svgRef = useRef(null);
    // const connections = useRef([]);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    useEffect(() => {
        if (!props.selectedQuestion || !props.selectedQuestion[0]) {
            return;
        }
        
        if (props.selectedQuestion) {
            // console.log("New question received:", props.selectedQuestion[0]);
            setQuestionText(props.selectedQuestion[0].questionTitle);
            setOptionNum(5);
            const question = props.selectedQuestion[0];
            const updatedMatches = [
                question.options.A, question.options.B, question.options.C,
                question.options.D, question.options.E,  
                question.matches.a, question.matches.b, question.matches.c,
                question.matches.d, question.matches.e, 
                question.explanations.explA, question.explanations.explB, question.explanations.explC,
                question.explanations.explD, question.explanations.explE, 'Wrong Match'   
            ];

            const updatedMatchCon = [
                question.answers.AnsA, question.answers.AnsB, question.answers.AnsC,
                question.answers.AnsD, question.answers.AnsE
            ];
            setMatches(updatedMatches);
            setMatchCon(updatedMatchCon);
        }
    }, [props.selectedQuestion]);


    useImperativeHandle(ref, () => ({
        submitQuestion
    }));

    const handleChange = (e) => {
        setQuestionText(e.target.value);
    };

    const handleMatchChange = (index, value) => {
        const updatedMatches = matches.map((match, i) => (i === index ? value : match));
        setMatches(updatedMatches);
    };

    const handleMatchConChange = (index, value) => {
        const allowedChars = 'abcde'.slice(0, optionNum);
        if (value.length <= 1 && allowedChars.includes(value.toLowerCase())) {
            const updatedMatchCon = matchCon.map((matchcon, i) => (i === index ? value : matchcon));
    
            // Check if the value already exists in matchCon
            if (matchCon.includes(value)) {
                const oldIndex = matchCon.indexOf(value);
                updatedMatchCon[oldIndex] = '';
            }
    
            setMatchCon(updatedMatchCon);
        }
    };

    const handleDeleteMatch = (index) => {
        if (optionNum <= 2) {
            alert("Options cannot be less than 2, please add a new one before deleting");
            return;
        }
        let updatedMatchCon = [...matchCon];
        updatedMatchCon[index] = '';
        for (let i = index; i < optionNum; i++) {
            if (i === 4) {
                matches[i] = "";
                matches[i + 5] = "";
                matches[i + 10] = "";
                updatedMatchCon[i] = "";
            } else {
                matches[i] = matches[i + 1];
                matches[i + 5] = matches[i + 1 + 5];
                matches[i + 10] = matches[i + 1 + 10];
                updatedMatchCon[i] = updatedMatchCon[i+1];
            }
            
        }
        const allowedChars = 'abcde'.slice(0, optionNum-1);
        setOptionNum(optionNum - 1);
        // console.log(allowedChars);
        const newMatchCon = updatedMatchCon.map((value) => (allowedChars.includes(value) ? value : ''));
        // console.log(newMatchCon);
        setMatchCon(newMatchCon);
    };

    const addMatch = () => {
        setOptionNum(optionNum + 1);
    };

    const renderMatches = () => {
        return ['A', 'B', 'C', 'D', 'E'].map((label, index) => {
            if (index < optionNum) {
                return (
                    <div className='lesson-matches-list' key={label}>
                        <DeleteOutlined className='delete-match' style={{ marginRight: '15px' }} onClick={() => handleDeleteMatch(index)} />
                        <div className='lesson-matches-label'><label>{label}: </label></div>
                        <div className='lesson-matches-content'>
                            <Input type="text" value={matches[index] || ''} onChange={(e) => handleMatchChange(index, e.target.value)} 
                                style={{ width: '100%', marginBottom: '10px', marginRight: '10px' }} maxLength={29}  // Limiting characters
                            />
                        </div>
                        <div className='lesson-match'>
                            {/* {label} -  */}
                            <Input type="text" value={matchCon[index] || ''} onChange={(e) => handleMatchConChange(index, e.target.value)} 
                                style={{ width: '45px', marginLeft: '-10px'}} maxLength={29}
                            />
                        </div>
                        <div className='lesson-matches-label'><label>{label.toLowerCase()}: </label></div>
                        <div className='lesson-matches-content'>
                            <Input type="text" value={matches[index + 5] || ''} onChange={(e) => handleMatchChange(index + 5, e.target.value)} 
                                style={{ width: '100%', marginBottom: '10px' }} maxLength={29}  // Limiting characters 
                            />
                        </div>
                        <div className='lesson-match-msg-content'>
                            <Input type="text" value={matches[index + 10] || ''} onChange={(e) => handleMatchChange(index + 10, e.target.value)} style={{ width: '100%', marginBottom: '0px', marginLeft: '10px' }} />
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    const submitQuestion = async () => {
        if (!questionText) {
            alert("Question Title cannot be empty!");
            return false;
        } 
        for (let i = 0; i < optionNum; i++) {
            if (!matches[i] || !matches[i+5] || !matches[i+10] || !matchCon[i]) {
                alert("Please fill out all necessary inputs!");
                return false;
            }
        }
        if (!matches[15]) {
            alert("Please fill out all necessary inputs!");
            return false;
        }
        props.setIsLoading(true);
        const questionData = {
            pageId: props.pageid,
            type: 'matching',
            text: questionText,
            matches: matches,
            matchCon: matchCon,
            optionNum: optionNum
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/lesson/matchingquestion/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(questionData)
            });
    
            if (!response.ok) {
                props.setIsLoading(false);
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.questionID) {
                // Question added successfully
                const questionDetails = {
                    questionid: data.questionID,
                    type: 'matching',
                    text: questionText,
                    matches: matches,
                    matchCon: matchCon,
                    optionNum: optionNum
                };
                props.onAddQuestion(questionDetails);
                notification.success({
                    message: 'Success',
                    description: 'Question added successfully!',
                    duration: 1.5
                });
                props.setIsLoading(false);
                props.setIsInterModalVisible(false);
                return true;
            } else {
                props.setIsLoading(false);
                throw new Error('Failed to add question');
            }
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            notification.error({
                message: 'Error',
                description: 'Error adding question: ' + error.message,
            });
            props.setIsLoading(false);
            return false;
        }
    };

    return (
        <div className='questionAndimage-lesson'>
            <div className='question-matching-lesson'>
                <div style={{marginTop: '25px', fontSize: '20px', fontWeight: 'bold'}}>
                    Question Title<span style={{ color: 'red' }}>*</span>
                </div>
                <TextArea ref={inputRef} type="text" value={questionText} placeholder="Question Title" onChange={handleChange} style={{ width: '100%', marginBottom: '10px', marginTop: '20px' }} 
                    autoSize={{ minRows: 2, maxRows: 2 }} maxLength={99}
                />
                <div className='lesson-jsplumb-container'>
                    <div className='lesson-option1-option2-msg-title'>
                        <div className='lesson-option1-option2-msg-option1'><strong>Options - Left</strong><span style={{ color: 'red' }}>*</span></div>
                        <div className='lesson-option1-option2-msg-match'><strong>Match</strong><span style={{ color: 'red' }}>*</span></div>
                        <div className='lesson-option1-option2-msg-option2'><strong>Options - Right</strong><span style={{ color: 'red' }}>*</span></div>
                        <div className='lesson-option1-option2-msg-msg'><strong>Messages</strong><span style={{ color: 'red' }}>*</span></div>
                    </div>
                    {renderMatches()}
                    <div className='default-msg'>
                        <div className='default-msg-title-'><strong>Default Message<span style={{ color: 'red' }}>*</span>:</strong></div>
                        <div className='lesson-default-msg-content'>
                            <Input type="text" value={matches[15] || ''} onChange={(e) => handleMatchChange(15, e.target.value)} style={{ width: '100%', marginBottom: '0px' }} />
                        </div>
                    </div>
                    {optionNum < 5 && <Button type="primary" onClick={addMatch} style={{ marginTop: '15px' }}>Add New Matches</Button>}
                    {/* <div className='matching-question-comment'>
                        <p>* Click and hold the left circle and drop it on the right circle to connect two options</p>
                        <p>* Left click the line to delete it</p>
                    </div> */}
                </div>
            </div>
            <div className='image-lesson-matching'>
                <div style={{marginTop: '-8px', marginBottom: '0px', width: '300px', padding: '6px', hyphens: 'auto', color: 'red'}}>
                    The Matching module allows you to match options by touching two options (one each side). Meaningful message will appear on the screen. (Max: <strong> 100 characters</strong>).
                </div>
                <img src={leesonmatching_img} alt="Cannot Load Image" />
            </div>
        </div>
    );
});

export default LessonMatching;