import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { Input } from 'antd';
import { useDrag, useDrop } from 'react-dnd';

const ItemType = 'option';
const { TextArea } = Input;

function DraggableOption({ question, index, moveOption, children }) {
    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { type: ItemType, index },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover(item, monitor) {
            if (item.index !== index) {
                moveOption(item.index, index);
                item.index = index;
            }
        },
    });

    return (
        <div ref={drop} style={{ opacity: isDragging ? 0.5 : 1, display: 'flex', alignItems: 'center', fontSize: '20px', cursor: 'pointer' }}>
            <div ref={drag} className="drag-handle" style={{marginRight: '5px'}}>⋮⋮</div>
            <div style={{ flexGrow: 1 }}>
                {children}
            </div>
        </div>
    );
}


function TrueFalseDetails({ question, onDataChange, onSave }) {
    const [questionText, setQuestionText] = useState(question.text || '');
    const [isDirty, setIsDirty] = useState(false);
    const [correctAnswer, setCorrectAnswer] = useState(null);
    const [tfOptions, setTfOptions] = useState([]);
    const [comments, setComments] = useState([]);
    const inputRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 20);
    }, [question]);

    useEffect(() => {
        setQuestionText(question.text || '');
        setIsDirty(false);

        const fetchAnswerOption = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/truefalse/${question.id}`);
            const results = await response.json();
            if (response.ok && results.data[0]) {
                const { T, F, CorrectAnswer, ComT, ComF } = results.data[0];
                setCorrectAnswer(CorrectAnswer);
                setTfOptions([T, F]);
                setComments([ComT, ComF]);
            } else {
                console.error('Failed to fetch answer:', results.message);
            }
        };

        fetchAnswerOption();
    }, [question]);

    useEffect(() => {
        const handleBlur = () => onSave();
        window.addEventListener('blur', handleBlur);
        return () => window.removeEventListener('blur', handleBlur);
    }, [questionText, isDirty, onSave]);

    const handleChange = (e) => {
        // console.log(e.target.value); // test use only
        const newText = e.target.value;
        setQuestionText(newText);
        setIsDirty(true);
        // console.log(questionText);
        onDataChange(newText); 
    };

    const handleSave = async () => {
        if (isDirty) {
            await onSave(question.id, questionText); // Save changes
            setIsDirty(false); // Reset dirty state
        }
    };

    // correct answer for the question
    const handleCorrectAnswerChange = (answer) => {
        setCorrectAnswer(answer);
        updateCorrectAnswerInDatabase(question.id, answer);
    };

    const handleCommentChange = async (index, newComment) => {
        const updatedComments = [...comments];
        updatedComments[index] = newComment;
        setComments(updatedComments);
        const updates = {
            newComments: [updatedComments[0], updatedComments[1]] 
        };
        // console.log(comments);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/truefalse/update-comments/${question.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updates)
            });
        
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || "Failed to update order");
            }
        
        } catch (error) {
            console.error('Error updating order and comments:', error);
        }
    };

    // Function to update the correct answer in the database
    const updateCorrectAnswerInDatabase = async (questionId, answer) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/truefalse/correct-answer/${questionId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ correctAnswer: answer })
        });
        const data = await response.json();
        if (!data.success) {
            console.error('Failed to update correct answer:', data.message);
        }
    };

    const moveOption = (from, to) => {
       
        setTfOptions((prevOptions) => {
            let newOptions = [...prevOptions];
            const movedOption = newOptions.splice(from, 1)[0];
            newOptions.splice(to, 0, movedOption);
            saveOrder(newOptions);
            return newOptions;
        });
    
        setComments((prevComments) => {
            let newComments = [...prevComments];
            const movedComment = newComments.splice(from, 1)[0];
            newComments.splice(to, 0, movedComment);
            return newComments;
        });
    }; 

    const saveOrder = async (newOptions) => {
        const updates = {
            newOrder: [newOptions[0], newOptions[1]], 
            // newComments: [comments[0], comments[1]] 
            newComments: newOptions.map(option => comments[tfOptions.indexOf(option)])
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/truefalse/update-order/${question.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updates)
            });
        
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || "Failed to update order");
            }
        
            // console.log('Order and comments updated successfully:', data.message);
        } catch (error) {
            console.error('Error updating order and comments:', error);
        }
    };
    


    return (
        <div>
            <TextArea
                ref={inputRef}
                value={questionText}
                placeholder="Type Your Question Here"
                // onChange={(e => { setQuestionText(e.target.value); setIsDirty(true); })}
                onChange={handleChange}
                // onBlur={() => onSave(question.id, questionText)}
                onBlur={handleSave}
                style={{ width: '98.5%', marginBottom: '10px', marginTop: '20px' }}
                autoSize={{ minRows: 1, maxRows: 5 }}
            />
            <div className='option-comment'>
                <div className='option-comment-option'><strong>Options</strong></div>
                <div className='option-comment-comment'><strong>Messages</strong></div>             
            </div>
            {tfOptions.map((option, index) => (
                <DraggableOption key={index} option={option} index={index} moveOption={moveOption}>
                    <div className='true_false_option'>
                        <input
                            type='radio'
                            name='correctAnswer'
                            checked={correctAnswer === option}
                            onChange={() => handleCorrectAnswerChange(option)}
                            style={{ marginRight: '5px' }}
                        />
                        {option} 
                        {/* {comments[index]} */}
                        <div className='true_false_option_container'>
                            <Input
                                className='true_false_option_box'
                                value={comments[index]}
                                onChange={e => handleCommentChange(index, e.target.value)}
                                placeholder=""
                                style={{ marginLeft: '10px' }}
                            />
                        </div>
                    </div>
                </DraggableOption>
            ))}
            <p>* Clcik the radio to set the correct answer</p>
        </div>
    );
}

export default TrueFalseDetails;
