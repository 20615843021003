import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Input, Button, List, Spin } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import './index.css';


const CreateNewLesson = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const [lessonTitle, setLessonTitle] = useState('');
    const [lessonDescription, setLessonDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // const [isImport, setIsImport] = useState(false);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [lessons, setLessons] = useState([]);
    const { isEdit, LessonID, importedLessonID, setImportedLessonID, nextStep } = props;  // Receive the LessonID from parent component

    // fetch lessonTitle and lessonDescription if LessonID is not null
    useEffect(() => {
        if (LessonID) {
            fetch(`${process.env.REACT_APP_API_URL}/lessonTD/${LessonID}`)
                .then(response => response.json())
                .then(data => {
                    setLessonTitle(data.lessonTitle);
                    setLessonDescription(data.lessonDescrp);
                })
                .catch(error => console.error('Failed to fetch lesson data:', error));
        }
    }, [LessonID]);

    useEffect(() => {
        // document.title = "My Lessons";
        fetchLessons();
    }, []);

    // fetch lessons from the database
    const fetchLessons = () => {
        setIsLoading(true);
        // const userid = localStorage.getItem('userid');
        const userid = 4; // update it to import from lesson bank
        // console.log(userid);
        fetch(`${process.env.REACT_APP_API_URL}/users/lessons/${userid}`)
            .then(response => response.json())
            .then(data => {
                // console.log("lessons fetched:", data);
                setLessons(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Failed to fetch Lessons:', error);
                setIsLoading(false); 
            });
    };

    useImperativeHandle(ref, () => ({
        handleCreateLesson
    }));

    const handleTitleChange = (e) => {
        setLessonTitle(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setLessonDescription(e.target.value);
    };

    const handleImportLesson = () => {
        if (!selectedLesson) {
            alert("Please select a lesson before importing!");
            return;
        }
        if (!lessonTitle) {
            alert("Lesson Title cannot be Empty, please input the lesson title!");
            return;
        }
        // console.log("lessonid...", LessonID, importedLessonID);
        if (selectedLesson) {
            setImportedLessonID(selectedLesson.LessonID);
            // console.log(selectedLesson.LessonID);
            if (LessonID) {
                // update title and description using LessonID
                const headers = new Headers();
                headers.append("Content-Type", "application/json");
                const body = JSON.stringify({
                    title: lessonTitle,
                    description: lessonDescription
                });
                fetch(`${process.env.REACT_APP_API_URL}/lessons/${LessonID}`, {
                    method: 'PUT',
                    headers: headers,
                    body: body
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to update lesson');
                    }
                    return response.text();
                    
                })
                .then(result => {
                    // console.log('Lesson updated successfully:', result);
                    // Handle further logic here after successful update
                })
                .catch(error => {
                    // console.error('Error updating lesson:', error);
                    // alert('Error updating lesson');
                    // return false;
                });
            } 
            nextStep();
            
        } 
        setSelectedLesson(null);
    };

    const handleLessonClick = (lesson) => {
        // Toggle the selection of a lesson
        if (selectedLesson && selectedLesson.LessonID === lesson.LessonID) {
            setSelectedLesson(null);  // Deselect if it's the currently selected lesson
        } else {
            setSelectedLesson(lesson); // Otherwise, select the new lesson
        }
        
    };


    const handleCreateLesson = () => {
        // console.log("Creating lesson with title: ", lessonTitle);
        // console.log("Lesson description: ", lessonDescription);
        if (!lessonTitle) {
            alert("Lesson Title cannot be Empty!");
            return false;
        }
        if (LessonID) {
            // update title and description using LessonID
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            const body = JSON.stringify({
                title: lessonTitle,
                description: lessonDescription
            });
            fetch(`${process.env.REACT_APP_API_URL}/lessons/${LessonID}`, {
                method: 'PUT',
                headers: headers,
                body: body
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to update lesson');
                }
                return response.text();
            })
            .then(result => {
                // console.log('Lesson updated successfully:', result);
                // Handle further logic here after successful update
            })
            .catch(error => {
                // console.error('Error updating lesson:', error);
                // alert('Error updating lesson');
                // return false;
            });
        
            const url = `${process.env.REACT_APP_API_URL}/lesson/getpages/${LessonID}`;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    console.log('data', data);
                    if (data && data.length > 0) {  // Check if data is not empty
                
                    } else {
                        const newPageNumber = 1;
                        const newPageTitle = `Page ${newPageNumber}`;
                        fetch(`${process.env.REACT_APP_API_URL}/lesson/addpage`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ title: newPageTitle, lessonId: LessonID })
                        })                    
                    }
                })




        } 
        // import the lesson
        // handleImportLesson();
        // setImportedLessonID(selectedLesson.LessonID);
        return true;
        
    };

    return (
        <div>
            <div className='container-createlesson'>
                <div className='createlesson-content'>
                    <div className='newlesson-content-left'>
                        <label className='createlesson-content-title'>
                            <div style={{marginBottom: '10px'}}>Lesson Title: <span style={{ color: 'red' }}>*</span></div>
                            <Input value={lessonTitle} onChange={handleTitleChange} placeholder="Enter Title" />
                        </label>
                        <label className='createlesson-content-description'>
                            <div style={{marginBottom: '10px'}}>Lesson Description:</div>
                            {/* <Input value={lessonDescription} onChange={handleDescriptionChange} placeholder="Enter Description" /> */}
                            <Input.TextArea
                                value={lessonDescription}
                                onChange={handleDescriptionChange}
                                placeholder="Enter Description"
                                autoSize={{ minRows: 2, maxRows: 2 }} 
                            />
                        </label>
                    </div>
                    {/* {isEdit? <div ></div>:<div className='newlesson-content-right'>
                        <div className='createlesson-content-right-title'>Import an Existing Lesson</div>
                        <div className='lesson-bank-list-import'>
                            <div className='lesson-bank-list'>
                            <Spin spinning={isLoading}> 
                                <List
                                    itemLayout="horizontal"
                                    dataSource={lessons}
                                    renderItem={(lesson, index) => (
                                        <List.Item
                                            className={`lesson-item lesson-title ${selectedLesson && selectedLesson.LessonID === lesson.LessonID ? 'active' : ''}`}
                                            onClick={() => handleLessonClick(lesson)}
                                            actions={[
                                            ]}
                                        >
                                            <List.Item.Meta
                                                title={<span style={{ fontSize: '20px', fontWeight: 'normal' }}>{lesson.LessonTitle}</span>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Spin>

                            </div>
                            <div className='lesson-bank-import'>
                                <Button type="primary" size="large" style={{ width: '10.5rem', marginBottom: '20px'}} onClick={handleImportLesson}> Import Lesson</Button>
                            </div>
                        </div>
                    </div>}                 */}
                </div>
                <div className='lesson-bank-import'>
                    {/* <Button type="default" size="large" style={{marginLeft: '20PX', backgroundColor: '#808080', color: 'white', width: '6.5rem'}} onClick={handleImportLesson}> Import </Button> */}
                </div>
            </div>
        </div>
    )
});

export default CreateNewLesson;