import React, {useEffect} from 'react';




const ClearCookies= () => {

    
    useEffect(() => {
        // console.log('Effect has been run');
        const userid = localStorage.getItem('userid');
        localStorage.setItem(`joyrideTaken_page${userid}`, 'false');
        // alert('Cookies for current user are cleared.');
    }, []);

    return (
        <div>
            Cookies for current user are cleared.
        </div>
    )
};

export default ClearCookies;