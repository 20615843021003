import React, { useState, useEffect } from 'react';
import './index.css';;

const RenderOptions = ({ question }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchOptions = async () => {
            // console.log(question);
            const optionsData = question.choices || [];
            setOptions(optionsData);
    
        };
        fetchOptions();
    }, [question]);

    return (
        <div>
            <div className='options-display'>
                {options.map((option, index) => (
                    <div key={index} className='render-options-list-'>            
                        <svg width="20" height="16" style={{ marginRight: '3px',  marginTop: '1px'}}>
                            <circle cx="8" cy="8" r="7"  fill="white" stroke="black" strokeWidth="1"/>
                        </svg>
                        <div className='option-list-content-'>
                            {option} 
                        </div>
                    </div> 
                ))}          
            </div>
        </div>
    );
};

export default RenderOptions;
