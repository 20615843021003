import React from 'react';
import './index.css'
import {Card, Form, Input, Button, notification} from 'antd'
import { Link, useNavigate  } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import logo from '../../assets/logo.png'


const Login = () => {
    const navigate = useNavigate();
    const handleLogin = (values) => {
        // console.log(values);
        fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: values.username,
                password: values.password
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                // console.log('Login Successful:', data.message);
                // console.log(data.userid);
                localStorage.setItem('userid', data.userid.toString());
                localStorage.setItem('username', values.username);
                notification.success({
                    message: 'Login Successfully',
                    description: 'You have successfully logged in.',
                    duration: 0.8, // duration in seconds
                    placement: 'top', 
                });
                navigate('/');
            } else {
                // console.log('Login Failed:', data.message);
                notification.error({
                    message: 'Login Failed',
                    description: 'Incorrect username or password.',
                    duration: 0.8, // duration in seconds
                    placement: 'top', 
                });
                // alert("Wrong Username or Password!");
            }
        })
        .catch(error => {
            console.error('Login error:', error);
        });
    };

    return (
        <div className='login'>
            <Card className='login-container'>
                <div style={{ position: 'relative' }}>
                    <Link to="/" style={{ position: 'absolute', right: -18, top: -24, fontSize: 24 }}>
                        <CloseCircleOutlined /> {/* This is the close icon */}
                    </Link>
                </div>
                <div style={{ textAlign: 'center', padding: '1px' }}>
                    <Link to="/" style={{ display: 'inline-block', padding: '2px' }}>
                        <img className='login-logo' src={logo} alt="CANNOT LOAD LOGO" />
                    </Link>
                </div>
                <Form onFinish={handleLogin} validateTrigger="onBlur" layout="vertical">
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}>
                        <Input size="large" placeholder='Username' />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}>
                        <Input.Password size="large" placeholder='Password' />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType='submit' size="large" block>
                            Sign in
                        </Button>
                    </Form.Item>
                </Form>
                <Link to="/signup" className="signup-form" style={{ float: 'left' }}>
                    NEW TO TANGIBOOKS? CREATE ACCOUNT
                </Link>
            </Card>
        </div>
    )
}

export default Login