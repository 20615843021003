import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import './index.css';
import { Button, Input, Spin } from 'antd';


const { TextArea } = Input;

const AIGenerateMC = forwardRef((props, ref)  => {
    const [inputValue, setInputValue] = useState('');
    const [outputValue, setOutputValue] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // console.log(props.isAIMCModalVisible);
        setInputValue('');
        setOutputValue('');
        // document.title = "AI Generate Question";
    }, [props]);

    useImperativeHandle(ref, () => ({
        getSelectedQuestionDetails
    }));

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();  // Prevent the default action to avoid a newline in textarea
            submitRequest();
        }
    };    

    const handleInputChange = (e) => {
        setInputValue(e.target.value); // Update state with input value
    };

    const submitRequest = () => {
        if (inputValue.trim().length === 0) {
            alert('Plase input your request before generating question.');
            return;
        }
        setLoading(true);
        let aitext = inputValue + '.';
        // aitext = aitext + 'Genearate 1 multiple choice question (question title must be within 148 characters) that does not use any programming language, with 5 choices (each choice is within 80 characters). The question is computer science related. And only one of them is correct. Explain each choice why they are right or wrong, with max 80 characters for each explanation, only include explanations. Use A, B, C, D, E for each choice and a, b, c, d, e for each explanation.';
        // aitext = aitext + 'Must have answer.';
        aitext = aitext + 'Generate 1 matching question related to computer science that does not involve any programming language. The question title must be within 95 characters. The question should include 5 different options (labeled A, B, C, D, E), each within 26 characters, that pair with 5 different corresponding options (labeled a, b, c, d, e), also within 26 characters. Every option must have different pairing options. The order of the options should be random. After creating the question, explain each pairing (labeled with *). Each explanation is within 100 characters. Two sets of options are in seperate area.';
        // console.log(aitext);
        fetch(`${process.env.REACT_APP_API_URL}/api/aigenerate-question`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text: aitext })
        })
        .then(response => {
            if (!response.ok) { // Check if response is ok (i.e., status is 200-299)
                throw new Error('Network response was not ok: ' + response.statusText);
            }
            // console.log(response);
            return response.json(); // Converts the response to JSON
        })
        .then(data => {
            // console.log('Success:', data.text);
            setOutputValue(data.text);
            setLoading(false);
        })
        .catch((error) => console.error('Error:', error));

    };
    
    const getSelectedQuestionDetails = () => {
        if (!outputValue) {
            // alert('Please regenerate the question.');
            return;
        }
        // console.log("Output Value: ", outputValue);

        // Extract the question
        // // const question_text = outputValue.match(/^.+?(?=\nA\.)/s)[0].trim();
        const question_text = outputValue.match(/^.+?(?=\nA\.)/s)[0].trim().replace(/\n/g, ' ');
        // Extract options
        const options = outputValue.match(/^[A-E]\. .+?(?=\n|$)/gm).map(option => option.replace(/^[A-E]\. /, ''));
        // Extract pairing options
        const pair_options = outputValue.match(/^[a-e]\. .+?(?=\n|$)/gm).map(option => option.replace(/^[a-e]\. /, ''));
        // const explanations = outputValue.match(/^[a-e]\. .+?(?=\n|$)/gm).map(option => option.replace(/^[a-e]\. /, ''));
        // Extract explanations and correct answers
        const regex_expla_ans = /\* (\w) - (\w):\s*(.+)/g;
        let match;
        const results = [];
        while ((match = regex_expla_ans.exec(outputValue)) !== null) {
            results.push({
                label: match[1],
                correctAnswer: match[2],
                explanation: match[3].trim()
            });
        }
        // console.log(results);


        const OptionA = options[0];
        const OptionB = options[1];
        const OptionC = options[2];
        const OptionD = options[3];
        const OptionE = options[4];
        const pair_optiona =pair_options[0];
        const pair_optionb =pair_options[1];
        const pair_optionc =pair_options[2];
        const pair_optiond =pair_options[3];
        const pair_optione =pair_options[4];
        // const ExplanationA = explanations[0];
        // const ExplanationB = explanations[1];
        // const ExplanationC = explanations[2];
        // const ExplanationD = explanations[3];
        // const ExplanationE = explanations[4];

        const regex = /\s*(?:options|Options|options:|Options:)\s*$/;
        const filerted_question_text = question_text.replace(regex, '').trim();
        // console.log("Question: ", filerted_question_text);
        // console.log("Option A: ", OptionA);
        // console.log("Option B: ", OptionB);
        // console.log("Option C: ", OptionC);
        // console.log("Option D: ", OptionD);
        // console.log("Option E: ", OptionE);
        // console.log("Option a: ", pair_optiona);
        // console.log("Option b: ", pair_optionb);
        // console.log("Option c: ", pair_optionc);
        // console.log("Option d: ", pair_optiond);
        // console.log("Option e: ", pair_optione);
        return ([{
            questionTitle: filerted_question_text.length > 99 ? filerted_question_text.substring(0, 99) : filerted_question_text,
            options: {
                A: OptionA.length > 29 ? OptionA.substring(0, 29) : OptionA,
                B: OptionB.length > 29 ? OptionB.substring(0, 29) : OptionB,
                C: OptionC.length > 29 ? OptionC.substring(0, 29) : OptionC,
                D: OptionD.length > 29 ? OptionD.substring(0, 29) : OptionD,
                E: OptionE.length > 29 ? OptionE.substring(0, 29) : OptionE,
            },
            matches: {
                a: pair_optiona.length > 29 ? pair_optiona.substring(0, 29) : pair_optiona,
                b: pair_optionb.length > 29 ? pair_optionb.substring(0, 29) : pair_optionb,
                c: pair_optionc.length > 29 ? pair_optionc.substring(0, 29) : pair_optionc,
                d: pair_optiond.length > 29 ? pair_optiond.substring(0, 29) : pair_optiond,
                e: pair_optione.length > 29 ? pair_optione.substring(0, 29) : pair_optione,

            },
            answers: {
                AnsA: results[0].correctAnswer.length > 1? results[0].correctAnswer.substring(0, 1) : results[0].correctAnswer,
                AnsB: results[1].correctAnswer.length > 1? results[1].correctAnswer.substring(0, 1) : results[1].correctAnswer,
                AnsC: results[2].correctAnswer.length > 1? results[2].correctAnswer.substring(0, 1) : results[2].correctAnswer,
                AnsD: results[3].correctAnswer.length > 1? results[3].correctAnswer.substring(0, 1) : results[3].correctAnswer,
                AnsE: results[4].correctAnswer.length > 1? results[4].correctAnswer.substring(0, 1) : results[4].correctAnswer,
            },
            explanations: {
                explA: results[0].explanation.length > 100? results[0].explanation.substring(0, 100) : results[0].explanation,
                explB: results[1].explanation.length > 100? results[1].explanation.substring(0, 100) : results[1].explanation,
                explC: results[2].explanation.length > 100? results[2].explanation.substring(0, 100) : results[2].explanation,
                explD: results[3].explanation.length > 100? results[3].explanation.substring(0, 100) : results[3].explanation,
                explE: results[4].explanation.length > 100? results[4].explanation.substring(0, 100) : results[4].explanation,
            }
            // correctAnswer: q.CorrectAnswer
        }]);
    }

    const outputDisplay = () => {

        return outputValue;
    }


    return (
        <Spin spinning={loading} tip="processing..." size="large" >
        <div className='aipart-body-lesson'>
            <div style={{marginTop: '-30px'}}>
                *Powered by <strong>Google Gemini</strong>
            </div>
            <div className='aiquestion-body-lesson'>            
                <div className='aiquestion-input-lesson'>
                    <div className='aiquestion-input-right-lesson'>
                        <div className='aiquestion-input-box'>
                            <TextArea 
                                placeholder="Input here! Example: Generate question about loop"
                                autoSize={{ minRows: 4, maxRows: 4 }}
                                autoFocus 
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                style={{ width: '100%', borderRadius: '10px', fontSize: '18px' }}
                            />
                            <Button type="primary" onClick={submitRequest} style={{fontSize: '20px', marginTop: '23px', height: '40px', width: '100%'}}> Generate / Regenerate </Button>
                        </div>
                    </div>
                </div>
                <div className='aiquestion-output-bottom'>
                    
                    <div className='aiquestion-output-lesson'>
                        {/* <Spin spinning={loading} tip="processing..." size="large" > */}
                            {/* output here*/}
                            {outputDisplay()}
                        {/* </Spin> */}
                    </div>
                    
                </div>           
            </div>
        </div>
        </Spin>
    );
});

export default AIGenerateMC;