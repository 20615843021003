import React, { useState, useEffect } from 'react';
import { Menu, Button } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './index.css'; 

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    useEffect(() => {
        // Check if user data is stored in localStorage
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
            setIsLoggedIn(true);
            setUsername(storedUsername);
        }
    }, []);

    const login = () => {
        navigate('/login');
        const username = localStorage.getItem('usaname');
        // localStorage.setItem('username', username);
        setIsLoggedIn(true);
        setUsername(username);
    };

    const logout = () => {
        localStorage.removeItem('username');
        localStorage.removeItem('userid');
        setIsLoggedIn(false);
        setUsername('');
        navigate('/');
        window.location.reload();
    };

    return (
        <div className='header'>
            <Menu theme="dark" mode="horizontal" selectedKeys={[location.pathname]} className="full-width-menu">
                <Menu.Item key="/">
                    <Link to="/" className='nav-title'><span>TEACH</span></Link>
                </Menu.Item>
                <Menu.Item key="/lessons">
                    <Link to="/lessons" className='nav-title'><span>My Lessons</span></Link>
                </Menu.Item>
                {/* <Menu.Item key="/">
                    <Link to="/" className='nav-title'><span>My Lessons</span></Link>
                </Menu.Item> */}
                {/* <Menu.Item key="/quizzes">
                    <Link to="/quizzes" className='nav-title'><span>My Quizzes</span></Link>
                </Menu.Item> */}
                {/* <Menu.Item key="/questionbank">
                    <Link to="/questionbank" className='nav-title'><span>Question Bank</span></Link>
                </Menu.Item> */}
                {/* <Menu.Item key="/lessonbank">
                    <Link to="/lessonbank" className='nav-title'><span>Lesson Bank</span></Link>
                </Menu.Item> */}
                {/* <Menu.Item key="/aiquestion">
                    <Link to="/aiquestion" className='nav-title'><span>AI Generate Question</span></Link>
                </Menu.Item> */}
                {/* <Menu.Item key="/instructions">
                    <Link to="/instructions" className='nav-title'><span>Instructions</span></Link>
                </Menu.Item>
                <Menu.Item key="/aboutus">
                    <Link to="/aboutus" className='nav-title'><span>About Us</span></Link>
                </Menu.Item> */}
            </Menu>
            <div className="login-status" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '50px' }}>
                {isLoggedIn ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '20px' }}>Welcome, {username}!</span>
                        <Button size="large" style={{ marginTop: '0px', fontSize: '1.2rem' }} onClick={logout}>Log Out</Button>
                    </div>
                ) : (
                    <Button size="large"  style={{ marginTop: '0px', fontSize: '1.2rem' }} onClick={login}>Log In</Button>
                )}
            </div>
        </div>
    );
};

export default Header;
