import React from 'react';
import './index.css';

const optionShape = ({ content }) => {
  return (
    <div>
      <div className='matching-match-box'>
        <div className='matching-match-content-lesson' lang="en">
          {content}
        </div>
        <svg width="20" height="16" style={{ marginLeft: '3px',  marginTop: '0px'}}>
            <circle cx="8" cy="8" r="7"  fill="white" stroke="black" strokeWidth="1"/>
        </svg>
      </div>
    </div>
  );
};

export default optionShape;
