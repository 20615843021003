import React, { useState, useEffect } from 'react';
import './index.css';
import { Card, Spin, Pagination, Checkbox } from 'antd';

const QBankMC = ({ mcCheckedState, onMcCheckedQuestion }) => {
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // const [mcCheckedState, setMcCheckedState] = useState({});
    const [questionsPerPage] = useState(5);  // Adjust number of questions per page as needed

    useEffect(() => {
        const fetchQuestions = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/qbankmc`);
            const results = await response.json();
            // console.log(results.data);
            if (response.ok) {
                if (results.data) {
                    setQuestions(results.data);
                }
            } else {
                console.error('Failed to fetch questions from question bank:', results.message);
            }
            setLoading(false);
        };
        fetchQuestions();
    }, []);

    // Get current questions to display
    const indexOfLastQuestion = currentPage * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const currentQuestions = questions.slice(indexOfFirstQuestion, indexOfLastQuestion);

    // Change page
    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
    };

    if (loading) {
        return <Spin />;
    }

    return (
        <div>
            <div className='question-display-header'>
                <p><strong>Select</strong></p>
                <div className='question-pagination'>
                <Pagination
                    current={currentPage}
                    total={questions.length}
                    pageSize={questionsPerPage}
                    onChange={paginate}
                    style={{ textAlign: 'right', margin: '15px 0' }}
                />
                </div>
            </div>
            {currentQuestions.map((q) => (
                <div key={q.QID} className='question-box'>
                    <Checkbox checked={mcCheckedState[q.QID]} onChange={() => onMcCheckedQuestion(q.QID)} />
                    <Card 
                        onClick={() => onMcCheckedQuestion(q.QID)}
                        style={{ marginLeft: '15px', marginBottom: '16px', width: '100%', cursor: 'pointer' }}
                    >
                        <div className='question-text'>
                            <b>Question:</b> {q.QuestionText}
                        </div>
                        <table className="table-option">
                            <tbody>
                                <tr>
                                    <td className={q.CorrectAnswer === 'A' ? 'correct-answer' : 'table-option-list'}>{`A: ${q.A}`}</td>
                                    <td className={q.CorrectAnswer === 'B' ? 'correct-answer' : 'table-option-list'}>{`B: ${q.B}`}</td>
                                    <td className={q.CorrectAnswer === 'C' ? 'correct-answer' : 'table-option-list'}>{`C: ${q.C}`}</td>
                                </tr>
                                <tr>
                                    <td className={q.CorrectAnswer === 'D' ? 'correct-answer' : 'table-option-list'}>{`D: ${q.D}`}</td>
                                    <td className={q.CorrectAnswer === 'E' ? 'correct-answer' : 'table-option-list'}>{`E: ${q.E}`}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                </div>
            ))}
            <Pagination
                current={currentPage}
                total={questions.length}
                pageSize={questionsPerPage}
                onChange={paginate}
                style={{ textAlign: 'right', margin: '20px 0' }}
            />
        </div>
    );
};

export default QBankMC;
