import React, { useState, useEffect, useRef, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Input, Button, List, Spin, Popover, Rate, Select } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import lessonItems from '../../data_lesson.json';  
import author_img1 from '../../assets/author1.jpg';
import author_img2 from '../../assets/author2.jpg';
import author_img3 from '../../assets/author3.jpg';
import author_img4 from '../../assets/author4.jpg';
import author_img5 from '../../assets/author5.jpg';
import author_img6 from '../../assets/author6.jpg';
import author_img7 from '../../assets/author7.jpg';
import author_img8 from '../../assets/author8.jpg';
import author_img9 from '../../assets/author9.jpg';
import author_img10 from '../../assets/author10.jpg';
import author_img_ from '../../assets/author1.svg';
import './index.css';

// import { Rate } from 'antd';
// import 'antd/dist/antd.css';

import Header from '../../components/header';

import BreadCrumbs from '../../components/breadCrumbs';

const { Option } = Select;

const StarDisplay = ({ value }) => {
    return (
      <Rate allowHalf disabled value={value} />
    );
};

const Lessons = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingbank, setIsLoadingbank] = useState(false);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [selectedLessonBank, setSelectedLessonBank] = useState(null);
    const [lessons, setLessons] = useState([]);
    const [lessonsbank, setLessonsbank] = useState([]);
    const [lessonIndex, setLessonIndex] = useState(0);
    const [refreshList, SetRefreshList] = useState(false);
    const [lessonInfoVisible, setLessonInfoVisible] = useState(false);
    const [institutions, setInstitutions] = useState([]);
    const [selectedInstitution, setSelectedInstitution] = useState('All');
    // const [lessonItems, setLessonItems] = useState([]);
    // useEffect(() => {
    //     document.title = "My Lessons";
    //     setLessonItems(jsonData); 
    //     fetchLessons();
    //     fetchLessonsBank();
    //     const universities = ['All', ...new Set(lessonItems.map(item => item.university))];
    //     setInstitutions(universities);
        
    // }, []);
    // let lessonItems;

    useEffect(() => {
        const setupData = async () => {
            document.title = "My Lessons";
            console.log(lessonItems[0].author_img);
            fetchLessons();
            fetchLessonsBank();
            const universities = ['All', ...new Set(lessonItems.map(item => item.university))];
            setInstitutions(universities);
        };
    
        setupData();
      }, []); 

    useEffect(() => {
        fetchLessons();
        SetRefreshList(false);
    }, [refreshList]);

    const authorimages = {
        author_img1,
        author_img2,
        author_img3,
        author_img4,
        author_img5,
        author_img6,
        author_img7,
        author_img8,
        author_img9,
        author_img10
      };

    // let lessonItems = [
    //     {
    //         author_img: author_img1,
    //         author: "Bob Rivers",
    //         university: "Monash University",
    //         country: "Australia",
    //         description: "This lesson introduces the concepts of for and while loops, essential constructs in programming for executing repetitive tasks. Through examples and practical exercises, students will learn to implement these loops to control program flow effectively.",
    //         rate: 4.5,
    //         comments: [
    //             { user: 'Peter', text: "The examples are well-chosen, illustrating the loops' real-world applications and helping to solidify the concepts taught." },
    //             { user: 'Jason', text: "This lesson effectively demystifies loop constructs, making them accessible and understandable for beginners." }
    //         ]
    //     },
    //     {
    //         author_img: author_img2,
    //         author: "Emily Caldwell",
    //         university: "Sorbonne University",
    //         country: "France",
    //         description: "This lesson introduces the fundamental concept of variables. Learn about different types of variables, such as integers, floats, strings, and booleans, and how they can be used to store information. Develop the ability to declare, initialize, and manipulate variables.",
    //         rate: 4.1,
    //         comments: [
    //             { user: 'John', text: "The explanations were clear, and the examples really helped make sense of how variables work. The questions are fair." },
    //             { user: 'Jason', text: "The course did a fantastic job breaking down the different types of variables and their uses. Great course." }
    //         ]
    //     },
    //     {
    //         author_img: author_img3,
    //         author: "Tom Lewis",
    //         university: "Kyoto University",
    //         country: "Japan",
    //         description: "This lesson is designed to introduce students to the critical concept of conditional logic, a fundamental building block in the programming. Learn about if-else statements, switch case statements, using logical opereators (AND, OR, NOT), and conditional logic in loops.",
    //         rate: 4.0,
    //         comments: [
    //             { user: 'Lucy', text: "Good lesson. The progression from basic to advanced concepts was well-paced, and the instructors provided clear, practical examples that made the lessons accessible and engaging." },
        
    //         ]
    //     },
    //     {
    //         author_img: author_img4,
    //         author: "Max Green",
    //         university: "Brown University",
    //         country: "United States",
    //         description: "A comprehensive lesson designed to teach the fundamental concepts and applications of loops in computer programming. Understand the purpose and functionality of loops in programming. Explore how to control loop execution with break and continue statements.",
    //         rate: 4.8,
    //         comments: [
    //             { user: 'Ana', text: "The lesson offers a thorough introduction to all types of loops and their uses in programming. The examples and questions used throughout were clear and relevant." },
    //             { user: 'Mia', text: "As a fellow educator, I appreciate the clarity and depth of this course. It's engaging, informative, and provides students with the necessary tools to understand and implement loops effectively. " }
    //         ]
    //     },
    //     {
    //         author_img: author_img5,
    //         author: "Ben Brown",
    //         university: "Brown University",
    //         country: "United States",
    //         // university: "University of Copenhagen",
    //         // country: "Denmark",
    //         description: "This is a detailed description of the fifth lesson.",
    //         rate: 3.6,
    //         comments: [
    //             { user: 'Laura', text: "Average lesson." },
    //         ]
    //     }
    // ];

    // fetch lessons from the database
   
    const fetchLessons = () => {
        setIsLoading(true);
        const userid = localStorage.getItem('userid');
        // console.log(userid);
        // if (userid !== null) {
            fetch(`${process.env.REACT_APP_API_URL}/users/lessons/${userid}`)
                .then(response => response.json())
                .then(data => {
                    // console.log("lessons fetched:", data);
                    setLessons(data);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Failed to fetch Lessons:', error);
                    setIsLoading(false); 
                });
                            
        // } else {
        //     setIsLoading(false);
        // }
    };

    // fetch lessons from the database lesson bank
    const fetchLessonsBank = () => {
        setIsLoadingbank(true);
        // const userid = localStorage.getItem('userid');
        const userid = 4; // update it to import from lesson bank
        // console.log(userid);
        fetch(`${process.env.REACT_APP_API_URL}/users/lessons/${userid}`)
            .then(response => response.json())
            .then(data => {
                // console.log("lessons fetched:", data);
                const newdata = data.map((lesson_, index) => {
                    // const university = lessonItems[index] ? lessonItems[index].university : "Unknown University";
                    const safeLessonItem = lessonItems[index] || {};
                    // console.log(safeLessonItem.author_img);
                    return {
                        ...lesson_,
                        // university: university,
                        // author: lessonItems[index] ? lessonItems[index].author : "Unknown Author", 
                        // author_img: lessonItems[index]? lessonItems[index].author_img : author_img_,
                        // country: lessonItems[index]? lessonItems[index].country : "Unknown Country",
                        // description: lessonItems[index]? lessonItems[index].description: "Coming",
                        // rate: lessonItems[index]? lessonItems[index].rate : 0,
                        // comments: lessonItems[index] ? lessonItems[index].comments : [],
                        university: safeLessonItem.university || "Unknown University",
                        author: safeLessonItem.author || "Unknown Author", 
                        author_img: safeLessonItem.author_img || author_img_,
                        country: safeLessonItem.country || "Unknown Country",
                        description: safeLessonItem.description || "Coming",
                        rate: safeLessonItem.rate || 0,
                        comments: safeLessonItem.comments || [],
                    };
                })
                setLessonsbank(newdata);
                setIsLoadingbank(false);
            })
            .catch(error => {
                console.error('Failed to fetch Lessons:', error);
                setIsLoadingbank(false); 
            });
    };

    // display selected lesson
    const handleLessonClick = (lesson) => {
        // console.log("Lesson clicked:", lesson); // Debug log
        setSelectedLesson(lesson);
        // console.log("Selected lesson state updated:", lesson); // Debug log
        navigate(`/mycreatelesson/${lesson.LessonID}/1/1`);
    };

    const handleLessonBankClick = (lesson, index) => {
        // Toggle the selection of a lesson
        if (selectedLessonBank && selectedLessonBank.LessonID === lesson.LessonID) {
            setSelectedLessonBank(null);  // Deselect if it's the currently selected lesson
        } else {
            setSelectedLessonBank(lesson); // Otherwise, select the new lesson
            setLessonIndex(index);
        }
        
    };

    // delete lesson
    const handleDeleteClick = (e, LessonID, index) => {
        e.stopPropagation();
        // call api to delte lesson
        Modal.confirm({
            title: 'Delete This Lesson?',
            content: 'Deleting this lesson will remove it permanently.',
            okText: 'Yes, delete it',
            okType: 'danger',
            cancelText: 'No, keep it',
            onOk() {
                const url = `${process.env.REACT_APP_API_URL}/lesson/delete/${LessonID}`;
                fetch(url, { method: 'DELETE' })
                    .then(response => {
                        if (response.ok) {
                            // console.log('Lesson deleted successfully');
                            // Update the UI 
                            SetRefreshList(true);
                        } else {
                            console.error('Failed to delete lesson');
                            // Handle errors, maybe display a message to the user
                        }
                    })
                    .catch(error => {
                        console.error('Error making DELETE request:', error);
                        // Handle network errors, display messages if needed
                    });
            },
            onCancel() {
                // console.log('Cancel delete');
            }
        });
    }

    // create new lesson
    const createNewLesson = () => {
        // alert("Please log in first!");
        // let LessonID = '';
        // navigate(`/mycreatelesson/${LessonID}`);
        // Send POST request to the backend to create a new lesson
        const userid = localStorage.getItem('userid');
        fetch(`${process.env.REACT_APP_API_URL}/createnewlesson/${userid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
            // No body required if the backend does not need any data
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to create lesson');
            }
            return response.json();
        })
        .then(data => {
            const LessonID = data.LessonID;
            // console.log('Lesson created with ID:', LessonID);
            // Redirect to the new lesson's page

            navigate(`/mycreatelesson/${LessonID}/0/0`);
        })
        .catch(error => {
            console.error('Error creating lesson:', error);
            alert('Error creating lesson');
        });
    }

    // preview lesson (lesson bank)
    const previewlessonbank = () => {
        if (!selectedLessonBank) {
            alert("Please select a lesson before importing!");
            return;
        }
        // navigate(`/lessonbankpreview/${selectedLessonBank.LessonID}`);
        setLessonInfoVisible(true);
        // console.log(selectedLessonBank);
    }

    const closeModal = () => {
        setLessonInfoVisible(false);
    }

    const importLesson = () => {
        setLessonInfoVisible(false);
        navigate(`/lessonbankpreview/${selectedLessonBank.LessonID}`);
    }

    // const handlePopoverVisibleChange = (lesson, visible) => {
    //     if (visible) {
    //         setSelectedLessonBank(lesson); 
    //     } else {
    //         // setSelectedLessonBank(null);  
    //     }
    // };

    const handleInstitutionChange = value => {
        setSelectedInstitution(value);
        setSelectedLessonBank(null);
    }
    

    return (
        <div className='body'>
            <div className='lesson-container'>
                <Header />
                {/* <BreadCrumbs class="breadcrumbs" quizName={selectedQuiz ? selectedQuiz.LTitle : null} /> */}
                <div className='lesson-body'>
                    <div className='personal-lesson-list'>
                        <h3 style={{fontSize: '1.75rem', marginBottom: '15px'}}>My Lessons</h3>
                        <Spin spinning={isLoading}> 
                            <List
                                itemLayout="horizontal"
                                dataSource={lessons}
                                renderItem={(lesson, index) => (
                                    <List.Item
                                        className={`quiz-item quiz-title ${selectedLesson && selectedLesson.LessonID === lesson.LessonID ? 'active' : ''}`}
                                        onClick={() => handleLessonClick(lesson)}
                                        actions={[
                                            // <EditOutlined className="edit-quiz-title" style={{fontSize: '20px'}} onClick={(e) => {
                                            //     handleEditClick(e, lesson)
                                            // }} />,
                                            <DeleteOutlined key="delete" style={{fontSize: '1.25rem'}} onClick={(e) => handleDeleteClick(e, lesson.LessonID, index)} />,
                                        ]}
                                    >
                                        <List.Item.Meta
                                            title={<span style={{ fontSize: '1.25rem', fontWeight: 'normal' }}>{lesson.LessonTitle}</span>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Spin>
                        
                        <Button type="primary" size="medium" style={{fontSize: '1.1rem', height: '2.25rem', }} onClick={createNewLesson}>+ Lesson from Scratch</Button>
                    </div>
                    <div style={{ width: '8%', height: '100px', backgroundColor: 'transparent' }}>
                    </div>
                    <div className='lesson-bank-title'>
                        <h3 style={{fontSize: '1.75rem', marginBottom: '8px', paddingLeft: '8px'}}>Lesson Bank</h3>
                        <div className='lesson-list'>
                            <div style={{display: 'flex'}}>
                                <p style={{fontSize: '1.30rem', marginBottom: '15px'}}>Institution:</p>
                                <Select defaultValue="All" style={{ width: '190px', marginLeft: '20px' }} onChange={handleInstitutionChange}>
                                    {institutions.map(institution => (
                                        <Option key={institution} value={institution}>{institution}</Option>
                                    ))}
                                </Select>
                            </div>
                            <Spin spinning={isLoadingbank}> 
                                <List
                                    itemLayout="horizontal"
                                    dataSource={lessonsbank.filter(lesson => selectedInstitution === 'All' || lesson.university === selectedInstitution)}
                                    renderItem={(lesson, index) => (
                                        <List.Item
                                            className={`lesson-item-bank lesson-title ${selectedLessonBank && selectedLessonBank.LessonID === lesson.LessonID ? 'active' : ''}`}
                                            onClick={() => handleLessonBankClick(lesson, index)}
                                            actions={[]}
                                        >
                                            <div className='lesson-bank-img-author'>
                                                <img src={authorimages[lesson.author_img]} alt="Cannot Load" style={{ width: '30px', marginRight: '10px', marginLeft: '10px', marginTop: '2px' }} /> 
                                                <div className='lesson-bank-author'>
                                                    <span style={{ width: '100%', fontSize: '20px', fontWeight: 'normal', verticalAlign: 'top', lineHeight: 'normal' }}>{index+1}. {lesson.LessonTitle}</span>
                                                    <span style={{ fontSize: '14px', verticalAlign: 'top', lineHeight: 'normal' }}>
                                                        {lesson.author} - {selectedInstitution === 'All' ? lessonItems[index].university : selectedInstitution}
                                                    </span>
                                                </div>
                                                
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </Spin>
                            <Modal
                                title={<span style={{ fontSize: '24px', fontWeight: 'bold' }}>{selectedLessonBank ? selectedLessonBank.LessonTitle : 'Loading...'}</span>}
                                centered
                                width={600}
                                open={lessonInfoVisible}
                                onOk={importLesson}
                                onCancel={closeModal}
                                maskClosable={false}
                                // footer={null}
                            >
                                {selectedLessonBank ? (
                                    <div className='lessoncard'>
                                        <div className='lessoncard-img-author'>
                                            <img src={authorimages[selectedLessonBank.author_img]} alt="Cannot Load" style={{ width: '130px', marginRight: '15px', marginLeft: '12px', marginTop: '2px', marginBottom: '10px' }} />
                                            <span style={{ fontSize: '15px', verticalAlign: 'top', lineHeight: 'normal', marginLeft: '10px', fontStyle: 'italic'  }}>{selectedLessonBank.author}</span>
                                            <span style={{ fontSize: '14px', verticalAlign: 'top', lineHeight: 'normal', marginLeft: '10px' }}>{selectedLessonBank.university}</span>
                                            <span style={{ fontSize: '14px', verticalAlign: 'top', lineHeight: 'normal', marginLeft: '10px' }}>{selectedLessonBank.country}</span>
                                        </div>
                                        <div className='lessoncard-content'>
                                            <div className='lessoncard-descrip'>
                                                {/* <p style={{ fontSize: '14px', fontWeight: 'bold', verticalAlign: 'top', lineHeight: 'normal', marginBottom: '5px'}}>Description:</p> */}
                                                {/* {selectedLessonBank.LessonDescrp}  */}
                                                {selectedLessonBank.description}
                                            </div>
                                            <div style={{marginTop: '18px', marginBottom: '15px', fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline'}}>
                                                Users' Opinions:
                                            </div>
                                            <div className='rating-container'>
                                                {/* <strong>Rate: &nbsp;&nbsp; </strong> */}
                                                <p style={{marginRight: '10px'}}>{selectedLessonBank.rate}</p>
                                                <StarDisplay value={selectedLessonBank.rate} />
                                            </div>
                                            <div className='lessoncard-comment' lang="en">
                                                {/* <p style={{ fontSize: '14px', fontWeight: 'bold', verticalAlign: 'top', lineHeight: 'normal', marginBottom: '5px'}}>Comments:</p> */}
                                                {/* <p><strong>Peter: </strong>This lesson is well prepared.</p>
                                                <p><strong>Jason: </strong>This lesson is awesome.</p> */}
                                                {/* <p>{lessonIndex}</p> */}
                                                {selectedLessonBank.comments.map((comment, index) => (
                                                    <p key={index} style={{marginBottom: '12px'}}>
                                                        {/* {comment.user}: {comment.text} */}
                                                        <span style={{ textDecoration: 'underline' }}>{comment.user}</span>: {comment.text}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ) : <p>Loading lesson details...</p>}
                            </Modal>
                            <Button type="primary" size="large" style={{fontSize: '1.25rem', height: '2.5rem'}} onClick={previewlessonbank}>Import Lesson</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    };

export default Lessons;

