import React, { useState }  from 'react';
import './index.css';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';


function ImgDisplay({src, alt, EditImg, DeleteImg}) {
    const [hover, setHover] = useState(false);
    return (
        <div 
            className='img-display'
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <img src={src} alt={alt} />
            {hover && (
                <div className="image-actions">
                    <EditOutlined className="image-icon edit-icon" onClick={EditImg} />
                    <DeleteOutlined className="image-icon delete-icon" onClick={DeleteImg} />
                </div>
            )}
        </div>
    );
}

export default ImgDisplay;
