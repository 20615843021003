import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { Button, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import AddImage from '../addImage'
import ImageDisplay from '../imgDisplay'; 
const { TextArea } = Input;

// multiple-choice question
function MultipleChoiceDetails({ question, onDataChange, onSave }) {
    const [questionText, setQuestionText] = useState(question.text || '');
    const [isDirty, setIsDirty] = useState(false);
    const [choicesDirty, setChoicesDirty] = useState(false);
    const [choices, setChoices] = useState([]);
    const [optionNum, setOptionNum] = useState(0);
    const [optionNumDirty, setOptionNumDirty] = useState(false);
    const [correctAnswer, setCorrectAnswer] = useState(null);
    const [showAddButton, setShowAddButton] = useState(false);
    const inputRef = useRef(null);
    const [imgSrc, setImgSrc] = useState('');
    const addImageRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 20);
    }, [question]);   

    useEffect(() => {
        setQuestionText(question.text || '');
        setIsDirty(false);

        const fetchChoices = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/choices/${question.id}`);
            const results = await response.json();
            // console.log(results.data);
            if (response.ok) {
                if (results.data[0]) {
                    const { A, B, C, D, E, OptionNum, CorrectAnswer, ComA, ComB, ComC, ComD, ComE } = results.data[0];
                    setOptionNum(OptionNum);
                    setCorrectAnswer(CorrectAnswer);
                    // console.log(OptionNum); // test use only
                    setChoices([A, B, C, D, E, ComA, ComB, ComC, ComD, ComE]); // Store choices as an array
                } else {
                    setChoices(["", "", "", "", "", "", "", "", "", ""]); // Default empty options if no choices are found
                }
            } else {
                console.error('Failed to fetch choices:', results.message);
            }
        };

        fetchChoices();
        setTimeout(() => {
            setShowAddButton(true);
        }, 10);
    }, [question]);

    useEffect(() => {
        // Save choices
        const saveChoices = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/choices/update/${question.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ choices })
            });
            const data = await response.json();
            if (data.success) {
                // console.log('Choices updated successfully'); // test use only
                setChoicesDirty(false); // Reset dirty state after successful save
            } else {
                console.error('Failed to update choices:', data.message);
            }
        };
        
        if (choicesDirty) {
            saveChoices();
            setChoicesDirty(false);
        }
    }, [question, choices, choicesDirty]);

    useEffect(() => {
        // update OptionNum value in the database
        const saveOptionNum = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/choices/updatenum/${question.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ optionNum })
            });
            const data = await response.json();
            if (!data.success) {
                console.error('Failed to update num:', data.message);
            }
        };
        if (optionNumDirty) {
            saveOptionNum();
            setOptionNumDirty(false);
        }
    }, [question, optionNum, optionNumDirty]);

    // fetch img path
    useEffect(() => { 
        const fetchImg = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/questions/img/${question.id}`);
            const results = await response.json();
            // console.log("API Response:", results);  // Check what the API returns
        
            if (response.ok) {
                // console.log(results);                
                if (results ) {
                    // console.log('test');
                    const { imgpath } = results;
                    // console.log(imgpath);
                    setImgSrc(imgpath || '');
                } 
            } else {
                console.error('Failed to fetch img:', results.message);
            }
        };
        const timer = setTimeout(() => {
            fetchImg();
        }, 10);
    
        return () => clearTimeout(timer);
        // console.log(imgSrc);  // Check the output in the console
    }, [question.id]) 

    // Callback function to update image source
    const updateImageSrc = (newSrc) => {
        setImgSrc(newSrc);
        // console.log(newSrc);
    };

    // edit img (replace img)
    const editImg = () => {
        // console.log("Edit action triggered");// test use only
        if (addImageRef.current) {
            addImageRef.current.showModal();
        }
    };

    // delete img
    const deleteImg = async () => {
        // console.log("Delete action triggered");// test use only
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/img/delete/${question.id}`, {
                method: 'DELETE'
            });
            const result = await response.json();
            if (response.ok) {
                // console.log('Image deleted successfully:', result.message);
                setImgSrc('');  // Clear the image source to update the UI
            } else {
                console.error('Failed to delete image:', result.message);
            }
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };
   
    const handleSave = async () => {
        if (isDirty) {
            await onSave(question.id, questionText); // Save changes
            setIsDirty(false); // Reset dirty state
        }
    };

    const handleChange = (e) => {
        // console.log(e.target.value); // test use only
        const newText = e.target.value;
        setQuestionText(newText);
        setIsDirty(true);
        // console.log(questionText);
        onDataChange(newText); 
    };

    const handleOptionChange = (index, value) => {
        const updatedChoices = choices.map((choice, i) => {
            if (i === index) {
                return value;
            }
            return choice;
        });
        setChoices(updatedChoices);
        setChoicesDirty(true);
    };
    // add options
    const addOption = () => {
        let num = optionNum + 1;
        setOptionNum(num);
        setOptionNumDirty(true);
    }

    // delete option
    const handleDeleteOption = (label, index) => {
        if (optionNum <= 2) {
            alert("Options cannot be less than 2, please add a new one before deleting");
            return;
        }
        // console.log(`Deleting option label ${label}`);
        if (label === correctAnswer) {
            // console.log('this is correct answer'); // test use only 
            setCorrectAnswer(null);
            handleCorrectAnswerChange(null);
        } else {
            let answers = ['A', 'B', 'C', 'D', 'E'];
            for (let i = index; i < optionNum; i++) {
                if (answers[i] === correctAnswer) {
                    setCorrectAnswer(answers[i-1]);
                    handleCorrectAnswerChange(answers[i-1]);
                    break;
                }
            }
        }
        
        for (let i = index; i < optionNum; i++) {
            // console.log(`option index ${i}`);
            // choices[i] = choices[i+1];
            // console.log(`option index ${choices[i+1]}`);
            if (i === 4) {
                choices[i] = "";
                choices[i+5] = "";
            }else {
                choices[i] = choices[i+1];
                choices[i+5] = choices[i+1+5];
            }
        }
        setChoicesDirty(true);
        let num = optionNum - 1;
        setOptionNum(num);
        setOptionNumDirty(true);
    };

    // correct answer for the question
    const handleCorrectAnswerChange = (label) => {
        setCorrectAnswer(label);
        updateCorrectAnswerInDatabase(question.id, label);
    };
    
    // Function to update the correct answer in the database
    const updateCorrectAnswerInDatabase = async (questionId, answer) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/choices/correct-answer/${questionId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ correctAnswer: answer })
        });
        const data = await response.json();
        if (!data.success) {
            console.error('Failed to update correct answer:', data.message);
        }
    };

    // display options
    const renderOptions = () => {
        return ['A', 'B', 'C', 'D', 'E'].map((label, index) => {
            if (index <= optionNum - 1) {
                // console.log(label);
                return (
                    <div className='options-list' key={label}>
                        <DeleteOutlined className='delete-option' style={{marginRight: '15px'}} onClick={() => handleDeleteOption(label, index)} />
                        <input
                            type='radio'
                            name='correctAnswer'
                            checked={correctAnswer === label}
                            onChange={() => handleCorrectAnswerChange(label)}
                            style={{ marginRight: '5px' }}
                        />
                        <div className='options-label'>
                            <label>{label}: </label>
                        </div>
                        <div className='options-content'>
                            <Input
                                type="text"
                                // placeholder="Option"
                                value={choices[index] || ''}
                                onChange={(e) => handleOptionChange(index, e.target.value)}
                                style={{ width: '97%', marginBottom: '10px' }}
                            />
                        </div>
                        {/* comment */}
                        <div className='options-label'>
                            {/* Comment: */}
                            {/* MSG: */}
                        </div>
                        <div className='comment-content'>
                            <Input
                                type="text"
                                // placeholder="Option"
                                value={choices[index+5] || ''}
                                onChange={(e) => handleOptionChange(index+5, e.target.value)}
                                style={{ width: '100%', marginBottom: '0px' }}
                            />
                        </div>
                        
                    </div>
                );
            } else {
                // Return null or an alternative component for empty or undefined choices
                return null;
            }
        });
    }

    // useEffect(() => {
    //     console.log("Available env variables:", process.env);
    //   }, []);
    
    return (
        <div>
            <div className='question-content'>
                <TextArea
                    ref={inputRef}
                    type="text"
                    value={questionText}
                    placeholder="Question Title"
                    onChange={handleChange}
                    onBlur={handleSave}
                    style={{ maxWidth: '95%', width: '100%', marginBottom: '10px', marginTop: '20px' }}
                    autoSize={{ minRows: 1, maxRows: 5 }} 
                />
                <AddImage ref={addImageRef} questionId={question.id} onUpdateImageSrc={updateImageSrc} />
            </div>
            {/* image display */}
            {/* {imgSrc && <ImageDisplay key={imgSrc} src={`./images/${imgSrc}`} alt="Cannot Load IMG" EditImg={editImg} DeleteImg={deleteImg} />} */}
            {imgSrc && <ImageDisplay key={imgSrc} src={`/images/${imgSrc}`} alt="Cannot Load IMG" EditImg={editImg} DeleteImg={deleteImg} />}
            <div>
                <div className='option-msg-title'>
                    <div className='option-msg-option'><strong>Options</strong></div>
                    <div className='option-msg-msg-quiz'><strong>Messages</strong></div>
                </div>
                {renderOptions()}
                {/* { optionNum < 5 && <button onClick={() => addOption()}>Add New Option</button> } */}
                { optionNum < 5 && showAddButton && <Button type="primary" onClick={() => addOption()} style={{marginTop:'15px'}}>Add New Option</Button> }
                <p>* Clcik the radio to set the correct answer</p>
            </div>

        </div>
    );
}

export default MultipleChoiceDetails;