import React, { forwardRef, useState, useEffect, useImperativeHandle  } from 'react';
import { Input, notification, Radio, Button, InputNumber, Spin, Space } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import './index.css';
// import l2r from '../../assets/l2r.png';
// import r2l from '../../assets/r2l.png';
import sweepingLR from '../../assets/SweepingLR.png';
import sweepingRL from '../../assets/SweepingRL.png';
import circleLeft from '../../assets/circleLeft.png';
import circleRight from '../../assets/circleRight.png';
import handmodule_img from '../../assets/handmodule.png';

const HandModule = forwardRef((props, ref) => {
    const [tempInput, setTempInput] = useState('');
    const [times, setTimes] = useState(1);
    const [gesture, setGesture] = useState(1);

    // gesture module: 1, 2, 3, 4
    const [inputsGesture, setInputsGesture] = useState([]);
    

    useEffect(() => {
        // console.log(props.pageid);
        const fetchGestureData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/lesson/handmodule/${props.pageid}`);
                const data = await response.json();
                if (!response.ok) {
                    throw new Error("Failed to fetch gesture data");
                }
                setInputsGesture(data); 
                props.setFetchingGesture(false);
            } catch (error) {
                console.error('Error fetching gesture data:', error);
                props.setFetchingGesture(false);
                notification.error({
                    message: 'Error',
                    description: 'Error fetching gesture data: ' + error.message,
                });
            }
        };
        setInputsGesture([]);
        setGesture(1);
        setTempInput('');
        // props.setFetchingGesture(true);
        fetchGestureData();
    }, [props]);  

    useImperativeHandle(ref, () => ({
        getInputsGesture,
        handleSubmit
    }));

    const getInputsGesture = () => {
        return tempInput;
    }

    const handleSubmit = async () => {
        const data = inputsGesture;
        const pageId = props.pageid;
        props.setIsLoading(true); 
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/lesson/handmodule/update/${pageId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
    
            const result = await response.json();
    
            if (!response.ok) {
                throw new Error("Failed to save module data");
            }
    
            notification.success({
                message: 'Success',
                description: 'Hand module data updated successfully!',
                duration: 2
            });
            // props.onUpdate(pageId, data); 
            props.setIsLoading(false); 
            return true;
        } catch (error) {
            console.error('Error saving data:', error);
            notification.error({
                message: 'Error',
                description: 'Error saving module data: ' + error.message,
            });
            props.setIsLoading(false); 
            return false;
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();  // Prevents the default action of the enter key
        }
    };

    const onGestureChange = (e) => {
        // console.log('radio checked', e.target.value);
        setGesture(e.target.value);
    };

    const handleAddGesture = () => {
        if (!tempInput) {
            alert('The message cannot be empty.');
            return;
        }
    
        const newGesture = {
            gestureModule: gesture.toString().trim(),
            times: times.toString().trim(),
            msg: tempInput
        };
    
        // Update the state with the new gesture or update existing one
        setInputsGesture(prev => {
            // Check if there is an existing gesture with the same module and times
            const existingIndex = prev.findIndex(g =>
                g.gestureModule.toString().trim() === newGesture.gestureModule &&
                g.times.toString().trim() === newGesture.times
            );
    
            if (existingIndex !== -1) {
                // If found, create a new array with the updated item
                const overwrite = window.confirm('A gesture with the same module and time already exists. Do you want to overwrite it?');
                if (overwrite) {
                    setTempInput('');
                    setTimes(1);
                    return prev.map((item, index) => index === existingIndex ? newGesture : item);
                } else {
                    return prev;
                }
            } else {
                // If not found, find the correct position to insert
                const insertIndex = prev.findIndex(g => 
                    g.gestureModule.toString().trim() > newGesture.gestureModule || 
                    (g.gestureModule.toString().trim() === newGesture.gestureModule && parseInt(g.times, 10) > parseInt(newGesture.times, 10))
                );
    
                if (insertIndex === -1) {
                    setTempInput('');
                    setTimes(1);
                    // If no suitable position is found, append at the end
                    return [...prev, newGesture];
                } else {
                    setTempInput('');
                    setTimes(1);
                    // Insert at the found position
                    return [
                        ...prev.slice(0, insertIndex),
                        newGesture,
                        ...prev.slice(insertIndex)
                    ];
                }
            }
        });
    };
    

    const handleEditGesture = (gIndex) => {
        // console.log(inputsGesture[gIndex]);
        setInputsGesture(prev => prev.filter((_, index) => index !== gIndex));
        const gestureToEdit = inputsGesture[gIndex];
        const gestureModule = Number(gestureToEdit.gestureModule); 
        setGesture(gestureModule);
        setTimes(gestureToEdit.times);
        setTempInput(gestureToEdit.msg);
    }

    const handleDeleteGesture = (gIndex) => {
        // console.log(gIndex);
        setInputsGesture(prev => prev.filter((_, index) => index !== gIndex));
    }
    
    const testButton = () => {
        console.log('diii', inputsGesture);
    }

    const getGestureDescription = (gestureModule) => {
        const descriptions = {
            1: 'Swiping LR',   // Left to Right
            2: 'Swiping RL',   // Right to Left
            3: 'Circle Left',
            4: 'Circle Right'
        };
    
        return descriptions[gestureModule] || 'Unknown Gesture';  // Default to 'Unknown Gesture' if not found
    };
    
    return (     
        <div className='hand-module-body'>
            <div className='hand-module-input'>
                <div style={{color: 'red'}}>
                    <p style={{marginBottom: '8px'}}>Swiping: Move your hand from one sensor to the other sensor.</p>
                    <p style={{marginBottom: '8px'}}>LR: Left to Right.</p>
                    <p style={{marginBottom: '8px'}}>RL: Right to Left.</p>
                    <p style={{marginBottom: '8px'}}>Circle: Move your hand in circle over the sensor as shown in the picture.</p>
                </div>
                <Radio.Group onChange={onGestureChange} value={gesture}>
                    <div style={{display: 'flex'}}>
                        <div className="radio-row">
                            <Space direction="vertical">
                                <Radio value={1} style= {{display: 'flex', alignItems: 'center'}}>
                                    <span style={{ marginRight: '8px' }}>Swiping LR</span>
                                    <img src={sweepingLR} alt="" style={{ width: '44px' }}/>
                                </Radio>
                                <Radio value={3} style= {{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                                    <span style={{ marginRight: '8px', position: 'relative', top: '-15px' }}>Circle Left</span>
                                    <img src={circleLeft} alt="" style={{ marginLeft: '18px', width: '44px' }}/>
                                </Radio>
                            </Space>
                        </div>
                        <div className="radio-row">
                            <Space direction="vertical">
                                <Radio value={2} style= {{display: 'flex', alignItems: 'center'}}>
                                    <span style={{ marginRight: '8px' }}>Swiping RL</span>
                                    <img src={sweepingRL} alt="" style={{ width: '44px' }}/>
                                </Radio>
                                <Radio value={4} style= {{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                                    <span style={{ marginLeft: '0px', position: 'relative', top: '-13px' }}>Circle Right</span>
                                    <img src={circleRight} alt="" style={{ marginLeft: '18px', width: '44px' }}/>
                                </Radio>
                            </Space>
                        </div>
                    </div>
                </Radio.Group>
                {/* <Button size="large" style={{backgroundColor: '#118f50', color: 'white', marginBottom: '18px'}} onClick={testButton}>Test</Button> */}
                <div className='input-button'>
                    <p>Message: </p> 
                    <Input.TextArea
                        className="hand-input"
                        // key={`${gesture}`}
                        // value={inputsGesture[gesture - 1]}
                        // onChange={e => setInputs[gesture - 1](e.target.value)}
                        value={tempInput}
                        onChange={e => setTempInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Input the message for gesture here."
                        rows={2}
                        style={{ resize: 'none', marginRight: '20px' }}
                    />
                    <p style={{marginRight: '5px'}}>Times:</p>
                    <InputNumber
                        min={0} // Set minimum value as needed
                        max={99} // Set maximum value as needed
                        value={times}
                        onChange={value => setTimes(value)} 
                        className="hide-spinner"
                        style={{ width: '100px', marginBottom: '0px', marginRight: '15px' }} // Adjust styling as needed
                        onKeyPress={(event) => {
                            const input = event.target;
                            if (!/[0-9]/.test(event.key) || input.value.length >= 2) {
                                event.preventDefault();
                            }
                        }}
                    />
                    <Button size="large" style={{backgroundColor: '#118f50', color: 'white', marginBottom: '18px'}} onClick={handleAddGesture}>Add Message</Button>
                </div>
                <div>
                    {inputsGesture.some(gest => gest.msg) && ( // Check if any gestures have a non-empty message
                        <div style={{display: 'flex', marginBottom: '5px'}}>
                            <p style={{width: '85px', fontWeight: 'bold'}}>Gesture</p>
                            <p style={{width: '145px', fontWeight: 'bold'}}>Times</p>
                            <p style={{fontWeight: 'bold'}}>Message</p>
                        </div>
                    )}
                    {inputsGesture.map((gest, gIndex) => ( gest.msg && 
                        <div key={`${gest.gestureModule}-${gIndex}`}  className='display-gesture'>
                            <p style={{width: '87px', marginRight: '5px', userSelect: 'none'}}> {`${getGestureDescription(gest.gestureModule)}:`}</p>
                            {/* <p style={{marginRight: '10px', marginLeft: '0px', userSelect: 'none'}}>{'Times:'}</p> */}
                            <p style={{ width: '20px', border: '1px solid', padding: '0px', display: 'flex', justifyContent: 'center', userSelect: 'none', marginRight: '10px' }}>{`${gest.times}`}</p>
                            <p className='display-list-gestsure'>{`${gest.msg}`}</p>
                            
                            <EditOutlined style={{fontSize: '20px', marginRight: '10px', marginLeft: '10px', cursor: 'pointer'}} onClick={() => handleEditGesture(gIndex)} />
                            <DeleteOutlined style={{fontSize: '20px', cursor: 'pointer'}}   onClick={() => handleDeleteGesture(gIndex)} />
                        </div>
                    ))}
                </div>
            </div>
            <div className='image-lesson-handmodule'>
                <div style={{textAlign: 'left', marginTop: '0px', marginBottom: '18px', padding: '6px', hyphens: 'auto', color: 'red'}}>
                    This module allows you to input some gestures. Meaningful message will appear on the screen accordingly. (Max: <strong> 100 characters</strong>) . 
                </div>
                <img src={handmodule_img} alt="Cannot Load Image" />
            </div>
        </div>
    );
});

export default HandModule;
